import { message } from "antd";
import { api } from "../../helpers/ApiHelper";
import * as actionTypes from "./actionTypes";

export const fetchBankaHesap = (bankaHesapId) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_BANKA_HESAP_REQUEST });
    const response1 = await api.post("bankahesap/get", {
      Includes: ["Doviz", "Banka"],
      Data: {
        Id: bankaHesapId,
      },
    });
    if (response1.status === 200) {
      dispatch({
        type: actionTypes.FETCH_BANKA_HESAP_SUCCESS,
        payload: response1.data.data[0],
      });
    }
    const response2 = await api.post(
      `/bankahesap/getsonbakiye/${bankaHesapId}`
    );
    if (response2.status === 200) {
      dispatch({
        type: actionTypes.FETCH_BANKA_HESAP_SUCCESS,
        payload: response2.data.data,
      });
    }
  } catch (error) {
    message.error(error.message);
    dispatch({
      type: actionTypes.FETCH_BANKA_HESAP_FAILURE,
      payload: error.message,
    });
  }
};
