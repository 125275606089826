import React, { useState } from "react";
import {
  Space,
  Button,
  Tag,
  message,
  notification,
  Popconfirm,
  Card,
  Form,
  Row,
  Col,
  Radio,
  InputNumber,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import FetchListContent from "../toolbox/FetchListContent";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import strings from "../../utils/Localization";
import StokIslemDuzenleModal from "../stokIslem/StokIslemDuzenleModal";
import { api } from "../../helpers/ApiHelper";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import DatepickerRanger from "../toolbox/DatepickerRanger";
import DepoSelectOption from "../toolbox/DepoSelectOption";

function StokIslemListe() {
  const { stokId } = useParams();
  const history = useHistory();

  const initialFilterRequestData = {
    Data: {
      StokId: stokId,
      Stokislem: {
        GirisMi: null,
        StokDepoId: null,
      },
    },
    BetweenOptions: [
      {
        PropertyName: "Miktar",
        First: null,
        Last: null,
      },
    ],
    DateOptions: [
      {
        DatePropertyName: "IslemTarihi",
        FirstDate: null,
        LastDate: null,
      },
    ],
  };
  const [filterRequestData, setFilterRequestData] = useState({
    ...initialFilterRequestData,
  });
  const [dataSource, setDataSource] = useState([]);
  const [stokIslemDuzenleModalVisible, setStokIslemDuzenleModalVisible] =
    useState(false);
  const [duzenleData, setDuzenleData] = useState(null);
  const [detailSearchForm] = Form.useForm();
  const { islemTarihi, aciklama, miktar, islemler, faturaIslemi, depo } =
    strings.stokDetay.stokIslemListe;
  const { detayliArama } = strings.stokDetay;
  const dispatch = useDispatch();

  const columns = [
    {
      title: islemTarihi,
      dataIndex: "islemTarihi",
      key: "islemTarihi",
      sorter: (a, b) => a.islemTarihi.length - b.islemTarihi.length,
      render: (text) => moment(text).format("DD.MM.YYYY"),
      defaultSortOrder: "descend",
    },
    {
      title: aciklama,
      dataIndex: "aciklama",
      key: "aciklama",
      render: (text, row, index) => (
        <>
          {row.faturaSatirId !== null && (
            <Tag color="geekblue">{faturaIslemi}</Tag>
          )}
          {` ${text !== null ? text : ""}`}
        </>
      ),
    },
    {
      title: depo,
      dataIndex: "stokDepoId",
      key: "stokDepoId",
      render: (text, row, index) => row.StokDepo.ad,
    },
    {
      title: miktar,
      dataIndex: "miktar",
      key: "miktar",
      sorter: (a, b) => a.miktar.length - b.miktar.length,
      render: (text, row, index) => {
        return (
          <Tag
            style={{
              fontSize: 14,
              padding: 4,
              paddingRight: 8,
              paddingLeft: 8,
            }}
            color={row.girisMi ? "green" : "red"}
          >
            {`${row.girisMi ? "+" : "-"}${text} ${
              row.StokVaryantGrup.Stok.StokBirim.ad
            }`}
          </Tag>
        );
      },
    },
    {
      title: islemler,
      dataIndex: "islemler",
      key: "islemler",
      render: (text, row, index) => (
        <Space size="middle">
          <Button
            type="default"
            shape="circle"
            icon={<SearchOutlined />}
            size={"large"}
            onClick={() => history.push(`/StokIslemDetay/${row.id}`)}
          />
          <Button
            type="default"
            shape="circle"
            icon={<EditOutlined />}
            size={"large"}
            onClick={() => {
              if (row.faturaSatirId !== null) {
                return notification["warning"]({
                  message: strings.mesaj.stokIslemFaturaDuzenleme,
                });
              }
              setDuzenleData(row);
              setStokIslemDuzenleModalVisible(true);
            }}
          />
          <Popconfirm
            title={strings.mesaj.silmeIslemUyari}
            onConfirm={() => {
              if (row.faturaSatirId !== null) {
                message.error({
                  content: strings.mesaj.stokIslemSilme,
                  style: { marginTop: "20vh" },
                });
              } else remove(row.id);
            }}
          >
            <Button
              type="default"
              shape="circle"
              icon={<DeleteOutlined />}
              size={"large"}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const remove = async (stokIslemId) => {
    try {
      const response = await api.delete(`/stokislem/${stokIslemId}`, {});
      if (response.status === 200) {
        dispatch(setRerender());
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const detayliAramaOnFinish = async (props) => {
    const { islemTarihi, depoId, islemTipi, minMiktar, maxMiktar } = props;

    let newData = { ...initialFilterRequestData };

    newData = {
      Data: {
        StokId: stokId,
        Stokislem: {
          StokDepoId: depoId !== undefined ? depoId : null,
          GirisMi: islemTipi !== undefined ? islemTipi : null,
        },
      },
      BetweenOptions: [
        {
          PropertyName: "Miktar",
          First: minMiktar !== undefined ? minMiktar : null,
          Last: maxMiktar !== undefined ? maxMiktar : null,
        },
      ],
      DateOptions: [
        {
          DatePropertyName: "IslemTarihi",
          FirstDate:
            islemTarihi !== undefined
              ? `${moment(islemTarihi[0]).format("YYYY-MM-DD")} 00:00:00`
              : null,
          LastDate:
            islemTarihi !== undefined
              ? `${moment(islemTarihi[1]).format("YYYY-MM-DD")} 23:59:59`
              : null,
        },
      ],
    };

    await setFilterRequestData(newData);
    dispatch(setRerender());
  };
  const detayliAramaReset = async () => {
    await setFilterRequestData({ ...initialFilterRequestData });
    detailSearchForm.resetFields();

    dispatch(setRerender());
  };
  return (
    <>
      <FetchListContent
        columns={columns}
        dataSource={dataSource}
        onDataSource={(e) => setDataSource(e)}
        requestUrl="/stokislem/getstokislemlist"
        requestBody={{
          SortOptions: {
            ColumnName: "IslemTarihi",
            SortType: "Desc",
          },
          SelectColumns: [
            {
              ColumnNames: [
                "id",
                "miktar",
                "aciklama",
                "girisMi",
                "islemTarihi",
                "evrakNo",
                "stokDepoId",
                "faturaSatirId",
                "birimFiyat",
              ],
              TableName: "",
            },
            {
              ColumnNames: ["id", "ad"],
              TableName: "StokVaryantGrup.Stok.StokBirim",
            },
            {
              TableName: "StokDepo",
              ColumnNames: ["id", "ad"],
            },
            {
              TableName: "Doviz",
              ColumnNames: ["id", "ad", "simge", "kod"],
            },
          ],
          ...filterRequestData,
        }}
        detailSearch={
          <Card title={detayliArama.baslik}>
            <Form
              form={detailSearchForm}
              name="detailSearchForm"
              layout="vertical"
              onFinish={detayliAramaOnFinish}
              initialValues={{
                islemTipi: null,
              }}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <DatepickerRanger
                    formName="islemTarihi"
                    formLabel={detayliArama.islemTarihi}
                  />
                  <DepoSelectOption
                    formItemLabel={detayliArama.depo}
                    formItemName="depoId"
                    required={false}
                  />
                </Col>
                <Col span={8}>
                  <Space
                    direction="vertical"
                    align="center"
                    style={{ width: "100%" }}
                  >
                    <Form.Item name="islemTipi" label={detayliArama.islemTipi}>
                      <Radio.Group>
                        <Space direction="vertical">
                          <Radio value={null}>
                            {detayliArama.tumIslemTipleriniGoster}
                          </Radio>
                          <Radio value={true}>
                            {detayliArama.girisIslemleriniGoster}
                          </Radio>
                          <Radio value={false}>
                            {detayliArama.cikisIslemleriniGoster}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space direction="vertical">
                    <Form.Item label={detayliArama.minMiktar} name="minMiktar">
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>

                    <Form.Item label={detayliArama.maxMiktar} name="maxMiktar">
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
              <Space align="baseline" style={{ float: "right" }}>
                <Button
                  type="default"
                  size="middle"
                  icon={<ClearOutlined />}
                  style={{ float: "right" }}
                  onClick={detayliAramaReset}
                >
                  {detayliArama.temizle}
                </Button>
                <Button
                  type="default"
                  size="middle"
                  icon={<SearchOutlined />}
                  style={{ float: "right" }}
                  form="detailSearchForm"
                  htmlType="submit"
                >
                  {detayliArama.aramayiBaslat}
                </Button>
              </Space>
            </Form>
          </Card>
        }
      />
      <StokIslemDuzenleModal
        visible={stokIslemDuzenleModalVisible}
        handleCancel={() => setStokIslemDuzenleModalVisible(false)}
        data={duzenleData}
      />
    </>
  );
}

export default StokIslemListe;
