import React from 'react'
import { PageHeader as MyPageHeader } from 'antd'
import { useHistory } from 'react-router-dom'
import strings from '../../utils/Localization'

export default function PageHeader() {
    const history = useHistory()
    const { pageHeader } = strings.cariIslemRapor.components

    return (
        <MyPageHeader
            ghost={false}
            onBack={() => history.goBack()}
            title={pageHeader.baslik}
            subTitle={pageHeader.altBaslik}
        />
    )
}
