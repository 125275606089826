import React, { useState, useEffect } from "react";
import { Modal, Form, Input, message, notification } from "antd";
import { api } from "../../helpers/ApiHelper";
import strings from "../../utils/Localization";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";

const formSize = "middle";

function CariGrupDuzenleModal({ visible = false, handleCancel, data = {} }) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { cariGrupDuzenleModal: localization } = strings.cariGrup;

  useEffect(() => {
    if (data !== {}) {
      form.setFieldsValue({
        id: data.id,
        ad: data.ad,
      });
    } else form.resetFields();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      setFetchLoading(true);
      const { ad, id } = values;
      const response = await api.put("/carigrup", {
        Id: id,
        Ad: ad,
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "cariGrupDuzenleModal",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
    >
      <Form
        name="cariGrupDuzenleModal"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size={formSize}
      >
        <Form.Item
          name="ad"
          label={localization.cariGrupAdi}
          rules={[{ required: true }]}
        >
          <Input placeholder={localization.cariGrupAdiPlaceHolder} />
        </Form.Item>
        <Form.Item name="id" hidden noStyle />
      </Form>
    </Modal>
  );
}

export default CariGrupDuzenleModal;
