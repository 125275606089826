import { PageHeader, Form, Card, Button, Space, Col, message } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import StokKategoriSelectOption from "../components/toolbox/StokKategoriSelectOption";
import { api } from "../helpers/ApiHelper";
import strings from "../utils/Localization";

function StokRapor() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const history = useHistory();
  const { stokRapor: localization } = strings;

  const onFinish = async (values) => {
    try {
      const { stokKategoriId } = values;
      setFetchLoading(true);
      const response = await api.post(
        "/rapordosya/stokliste",
        {
          StokKategoriId: stokKategoriId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return window.open(response.data, "_blank");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <PageHeader
        onBack={() => history.goBack()}
        title={localization.baslik}
        ghost={false}
      />
      <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Card>
          <Form layout="vertical" onFinish={onFinish}>
            <StokKategoriSelectOption formItemName="stokKategoriId" />
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: "right" }}
                size="large"
                loading={fetchLoading}
              >
                {localization.stokRaporuAl}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Space>
  );
}

export default StokRapor;
