import React from "react";
import { PageHeader, Space } from "antd";
import { useHistory } from "react-router-dom";
import {
  TeklifSiparisListeTable,
  IslemButton,
} from "../components/teklifSiparisListe";
import strings from "../utils/Localization";

function TeklifSiparisListe() {
  const history = useHistory();
  const { teklifSipariListe: localization } = strings;

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <PageHeader
        onBack={() => history.goBack()}
        title={localization.baslik}
        ghost={false}
        extra={<IslemButton />}
      />
      <TeklifSiparisListeTable />
    </Space>
  );
}

export default TeklifSiparisListe;
