import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import strings from '../../utils/Localization'

export default function KayitOlButton() {
    const history = useHistory()

    return (
        <Button
            onClick={() => history.push('/KayitOl')}
            type="default"
            block size="middle"
        >{strings.girisYap.components.kayitOlButton.butonYazi}
        </Button>
    )
}
