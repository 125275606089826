import React, { useState, useEffect } from "react";
import { Col, Input, Modal, Row, Form, message, notification } from "antd";
import strings from "../../utils/Localization";
import { api } from "../../helpers/ApiHelper";
import BankaSelectOption from "../toolbox/BankaSelectOption";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";

const formSize = "middle";

function BankaHesapDuzenleModal({
  visible = false,
  handleCancel,
  data = null,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { bankaHesapDuzenleModal: localization } = strings.bankaHesap;

  useEffect(() => {
    if (data !== null) {
      form.setFieldsValue({
        id: data.id,
        hesapAdi: data.ad,
        bankaId: String(data.banka.id),
        dovizId: String(data.doviz.id),
        subeAd: data.subeAd,
        subeNo: data.subeNo,
        hesapNo: data.hesapNo,
        ibanNo: data.ibanNo,
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        hesapAdi,
        bankaId,
        dovizId,
        ibanNo,
        subeAd,
        subeNo,
        hesapNo,
        id,
      } = values;

      setFetchLoading(true);
      const response = await api.put("/bankahesap", {
        Id: id,
        BankaId: bankaId,
        DovizId: dovizId,
        Ad: hesapAdi,
        subeAd: subeAd,
        SubeNo: subeNo,
        HesapNo: hesapNo,
        IbanNo: ibanNo,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "bankaHesapModalDuzenle",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      width="50%"
      centered
    >
      <Form
        name="bankaHesapModalDuzenle"
        onFinish={onFinish}
        form={form}
        layout="vertical"
        size={formSize}
      >
        <Form.Item hidden noStyle name="id" />
        <Row gutter={24}>
          <Col xl={12}>
            <Form.Item
              name="hesapAdi"
              label={localization.hesapAdi}
              rules={[{ required: true }]}
            >
              <Input placeholder={localization.hesapAdiPlaceHolder} />
            </Form.Item>
            <BankaSelectOption formItemName="bankaId" disabled />
            <DovizSelectOption formItemName="dovizId" required disabled />
            <Form.Item name="ibanNo" label={localization.ibanNo}>
              <Input placeholder={localization.ibanNoPlaceHolder} />
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item name="subeAd" label={localization.sube}>
              <Input placeholder={localization.subePlaceHolder} />
            </Form.Item>
            <Form.Item name="subeNo" label={localization.subeNo}>
              <Input placeholder={localization.subeNoPlaceHolder} />
            </Form.Item>
            <Form.Item name="hesapNo" label={localization.hesapNo}>
              <Input placeholder={localization.hesapNoPlaceHolder} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default BankaHesapDuzenleModal;
