import {
  Card,
  Col,
  PageHeader,
  Space,
  Form,
  Checkbox,
  Button,
  message,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DatepickerRanger from "../components/toolbox/DatepickerRanger";
import StokSelectOption from "../components/toolbox/StokSelectOption";
import { api } from "../helpers/ApiHelper";
import strings from "../utils/Localization";

function StokEkstreRapor() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const history = useHistory();
  const { stokEkstreRapor: localization } = strings;

  const onFinish = async (values) => {
    try {
      const { tarih, gecmisBakiyeDahilMi, stokId } = values;

      const baslangicTarihi = moment(tarih[0]).format("YYYY-MM-DD");
      const bitisTarihi = moment(tarih[1]).format("YYYY-MM-DD");

      setFetchLoading(true);
      const response = await api.post(
        "/rapordosya/stokekstre",
        {
          BaslangicTarih: `${baslangicTarihi} 00:00:00`,
          BitisTarih: `${bitisTarihi} 23:59:59`,
          StokId: stokId,
          GecmisBakiyeDahilMi: gecmisBakiyeDahilMi,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return window.open(response.data, "_blank");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="middle">
      <PageHeader
        title={localization.baslik}
        onBack={() => history.goBack()}
        ghost={false}
      />
      <Col xxl={8} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Card>
          <Form
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              gecmisBakiyeDahilMi: true,
              tarih: [moment().add("months", -12), moment()],
            }}
          >
            <DatepickerRanger
              formName="tarih"
              formLabel={localization.tarihSeciniz}
              required
            />
            <StokSelectOption formItemName="stokId" required allowClear />
            <Form.Item name="gecmisBakiyeDahilMi" valuePropName="checked">
              <Checkbox>{localization.gecmisBakiyeDahilEt}</Checkbox>
            </Form.Item>
            <Form.Item noStyle>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ float: "right" }}
                loading={fetchLoading}
              >
                {localization.stokEkstreRaporuAl}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Space>
  );
}

export default StokEkstreRapor;
