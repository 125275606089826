import React from "react";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import Layout from "./layout";

//Kimlik Doğrulama
import GirisYap from "./screens/GirisYap";
import KayitOl from "./screens/KayitOl";
import SifremiUnuttumMailDogrulama from "./screens/SifremiUnuttumMailDogrulama";
import SifremiUnuttumKodDogrulama from "./screens/SifremiUnuttumKodDogrulama";
import SifremiUnuttumYeniSifre from "./screens/SifremiUnuttumYeniSifre";

import Panel from "./screens/Panel";

//CariKart
import Cari from "./screens/Cari";
import CariKartDetay from "./screens/CariKartDetay";
import CariIslemDetay from "./screens/CariIslemDetay";

//Fatura
import FaturaListe from "./screens/FaturaListe";
import FaturaEkle from "./screens/FaturaEkle";
import FaturaDuzenle from "./screens/FaturaDuzenle";
import FaturaRapor from "./screens/FaturaRapor";

//Teklif Siparis
import TeklifSiparisListe from "./screens/TeklifSiparisListe";
import TeklifSiparisEkle from "./screens/TeklifSiparisEkle";
import TeklifSiparisDuzenle from "./screens/TeklifSiparisDuzenle";

//Stok
import Stok from "./screens/Stok";
import StokEkle from "./screens/StokEkle";
import StokDuzenle from "./screens/StokDuzenle";
import StokDetay from "./screens/StokDetay";
import VaryantliStokEkle from "./screens/VaryantliStokEkle";
import VaryantliStokDuzenle from "./screens/VaryantliStokDuzenle";
import VaryantliStokDetay from "./screens/VaryantliStokDetay";
import StokVaryantGrupEkle from "./screens/StokVaryantGrupEkle";
import StokRapor from "./screens/StokRapor";
import StokHareketRapor from "./screens/StokHareketRapor";
import StokEkstreRapor from "./screens/StokEkstreRapor";
import StokVaryantGrupDuzenle from "./screens/StokVaryantGrupDuzenle";
import StokIslemDetay from "./screens/StokIslemDetay";

//Kasa
import KasaListe from "./screens/KasaListe";
import KasaDetay from "./screens/KasaDetay";

//Banka Hesap
import BankaHesapListe from "./screens/BankaHesapListe";
import BankaHesapDetay from "./screens/BankaHesapDetay";

//Genel
import FirmaBilgileri from "./screens/FirmaBilgileri";
import SifremiDegistir from "./screens/SifremiDegistir";
import VarsayilanAyarlar from "./screens/VarsayilanAyarlar";

//Raporlar
import CariRapor from "./screens/CariRapor";
import CariEkstreRapor from "./screens/CariEkstreRapor";
import CariIslemRapor from "./screens/CariIslemRapor";
import KasaRapor from "./screens/KasaRapor";
import BankaRapor from "./screens/BankaRapor";

import Page404 from "./screens/Page404";

import VadeliTahsilatDetay from "./screens/VadeliTahsilatDetay";
import VadeliOdemeDetay from "./screens/VadeliOdemeDetay";

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (localStorage.getItem("token") != null)
          return <Layout>{children}</Layout>;
        else
          return (
            <Redirect
              to={{ pathname: "/GirisYap", state: { from: location } }}
            />
          );
      }}
    />
  );
};

export default function Routes() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/GirisYap">
          <GirisYap />
        </Route>
        <Route path="/KayitOl">
          <KayitOl />
        </Route>
        <Route path="/SifremiUnuttumMailDogrulama">
          <SifremiUnuttumMailDogrulama />
        </Route>
        <Route path="/SifremiUnuttumKodDogrulama">
          <SifremiUnuttumKodDogrulama />
        </Route>
        <Route path="/SifremiUnuttumYeniSifre">
          <SifremiUnuttumYeniSifre />
        </Route>
        <PrivateRoute exact path="/">
          <Panel />
        </PrivateRoute>
        <PrivateRoute exact path="/Panel">
          <Panel />
        </PrivateRoute>
        <PrivateRoute path="/FaturaListe">
          <FaturaListe />
        </PrivateRoute>
        <PrivateRoute path="/FaturaRapor">
          <FaturaRapor />
        </PrivateRoute>
        <PrivateRoute path="/Stok">
          <Stok />
        </PrivateRoute>
        <PrivateRoute path="/StokEkle">
          <StokEkle />
        </PrivateRoute>
        <PrivateRoute path="/VaryantliStokEkle">
          <VaryantliStokEkle />
        </PrivateRoute>
        <PrivateRoute path="/VaryantliStokDuzenle/:stokId">
          <VaryantliStokDuzenle />
        </PrivateRoute>
        <PrivateRoute path="/VaryantliStokDetay/:stokId">
          <VaryantliStokDetay />
        </PrivateRoute>
        <PrivateRoute path="/StokDuzenle/:stokId">
          <StokDuzenle />
        </PrivateRoute>
        <PrivateRoute path="/StokVaryantGrupDuzenle/:stokVaryantGrupId">
          <StokVaryantGrupDuzenle />
        </PrivateRoute>
        <PrivateRoute path="/StokIslemDetay/:stokIslemId">
          <StokIslemDetay />
        </PrivateRoute>
        <PrivateRoute path="/StokDetay/:stokId/:varyantGrupId">
          <StokDetay />
        </PrivateRoute>
        <PrivateRoute path="/StokVaryantGrupEkle/:stokId">
          <StokVaryantGrupEkle />
        </PrivateRoute>
        <PrivateRoute path="/StokRapor">
          <StokRapor />
        </PrivateRoute>
        <PrivateRoute path="/StokHareketRapor">
          <StokHareketRapor />
        </PrivateRoute>
        <PrivateRoute path="/StokEkstreRapor">
          <StokEkstreRapor />
        </PrivateRoute>
        <PrivateRoute path="/Cari">
          <Cari />
        </PrivateRoute>
        <PrivateRoute path="/CariDetay/:cariKartId">
          <CariKartDetay />
        </PrivateRoute>
        <PrivateRoute path="/CariIslemDetay/:cariIslemTur/:cariIslemId">
          <CariIslemDetay />
        </PrivateRoute>
        <PrivateRoute path="/KasaListe">
          <KasaListe />
        </PrivateRoute>
        <PrivateRoute path="/KasaListe">
          <KasaListe />
        </PrivateRoute>
        <PrivateRoute path="/KasaDetay/:kasaId">
          <KasaDetay />
        </PrivateRoute>
        <PrivateRoute path="/BankaHesapListe">
          <BankaHesapListe />
        </PrivateRoute>
        <PrivateRoute path="/BankaHesapDetay/:bankaHesapId">
          <BankaHesapDetay />
        </PrivateRoute>
        <PrivateRoute path="/FirmaBilgileri">
          <FirmaBilgileri />
        </PrivateRoute>
        <PrivateRoute path="/SifremiDegistir">
          <SifremiDegistir />
        </PrivateRoute>
        <PrivateRoute path="/CariRapor">
          <CariRapor />
        </PrivateRoute>
        <PrivateRoute path="/CariEkstreRapor">
          <CariEkstreRapor />
        </PrivateRoute>
        <PrivateRoute path="/CariIslemRapor">
          <CariIslemRapor />
        </PrivateRoute>
        <PrivateRoute path="/KasaRapor">
          <KasaRapor />
        </PrivateRoute>
        <PrivateRoute path="/BankaRapor">
          <BankaRapor />
        </PrivateRoute>
        <PrivateRoute path="/FaturaEkle">
          <FaturaEkle />
        </PrivateRoute>
        <PrivateRoute path="/FaturaDuzenle/:faturaId">
          <FaturaDuzenle />
        </PrivateRoute>
        <PrivateRoute path="/TeklifSiparisListe">
          <TeklifSiparisListe />
        </PrivateRoute>
        <PrivateRoute path="/TeklifSiparisEkle">
          <TeklifSiparisEkle />
        </PrivateRoute>
        <PrivateRoute path="/TeklifSiparisDuzenle/:teklifSiparisId">
          <TeklifSiparisDuzenle />
        </PrivateRoute>
        <PrivateRoute path="/VadeliTahsilatDetay/:islemListTip/:dovizId">
          <VadeliTahsilatDetay />
        </PrivateRoute>
        <PrivateRoute path="/VadeliOdemeDetay/:islemListTip/:dovizId">
          <VadeliOdemeDetay />
        </PrivateRoute>
        <PrivateRoute path="/VarsayilanAyarlar">
          <VarsayilanAyarlar />
        </PrivateRoute>
        <PrivateRoute path="/Page404">
          <Page404 />
        </PrivateRoute>
        <Redirect to="/Page404" />
      </Switch>
    </HashRouter>
  );
}
