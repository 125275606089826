import React from "react";
import { PageHeader as MyPageHeader } from "antd";
import { useHistory } from "react-router-dom";
import strings from "../../utils/Localization";

export default function PageHeader() {
  const history = useHistory();

  return (
    <>
      <MyPageHeader
        ghost={false}
        onBack={() => history.goBack()}
        title={strings.cariDetay.components.pageHeader.baslik}
        subTitle={strings.cariDetay.components.pageHeader.altBaslik}
      />
    </>
  );
}
