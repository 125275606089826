import React, { useState } from "react";
import { Menu, Image, Layout } from "antd";
import styled from "styled-components";
import logo from "../../assets/images/logo32.png";
import {
  DesktopOutlined,
  UserOutlined,
  ProfileOutlined,
  FileSyncOutlined,
  DropboxOutlined,
  DatabaseOutlined,
  BankOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import strings from "../../utils/Localization";

const { Sider } = Layout;
const { SubMenu } = Menu;

const sideBarKeyStrings = {
  panel: "panel",
  cariKart: "cariKart",
  fatura: "fatura",
  teklifSiparis: "teklifSiparis",
  stok: "stok",
  kasa: "kasa",
  banka: "banka",
  cariRapor: "cariRapor",
  cariIslemRapor: "cariIslemRapor",
  cariEkstreRapor: "cariEkstreRapor",
  kasaRapor: "kasaRapor",
  bankaRapor: "bankaRapor",
  stokRapor: "stokRapor",
  stokHareketRapor: "stokHareketRapor",
  stokEkstreRapor: "stokEkstreRapor",
  faturaRapor: "faturaRapor",
};

const LogoContainer = styled.div`
  height: 38px;
  margin: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const LogoTitle = styled.span`
  color: #ececec;
  font-size: 20px;
  margin-left: 6px;
  margin-bottom: 3px;
  display: ${(props) => (props.collapsed ? "none" : "block")};
`;

const SideBar = (props) => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const { sideBar } = strings;

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const onClickMenuItem = async (routeName, pushName) => {
    history.push(pushName);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="lg"
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0
      }}
    >
      <LogoContainer>
        <Image preview={false} src={logo} width={38} />
        <LogoTitle collapsed={collapsed}>EminHesap</LogoTitle>
      </LogoContainer>
      <Menu
        defaultSelectedKeys={[sideBarKeyStrings.panel]}
        defaultOpenKeys={["raporlar"]}
        mode="inline"
        theme="dark"
      >
        <Menu.Item
          key={sideBarKeyStrings.panel}
          icon={<DesktopOutlined />}
          onClick={() => onClickMenuItem(sideBarKeyStrings.panel, "/Panel")}
        >
          {sideBar.panel}
        </Menu.Item>
        <Menu.Item
          key={sideBarKeyStrings.cariKart}
          icon={<UserOutlined />}
          onClick={() => onClickMenuItem(sideBarKeyStrings.cariKart, "/Cari")}
        >
          {sideBar.cariKart}
        </Menu.Item>
        <Menu.Item
          key={sideBarKeyStrings.stok}
          icon={<DropboxOutlined />}
          onClick={() => onClickMenuItem(sideBarKeyStrings.stok, "/Stok")}
        >
          {sideBar.stoklar}
        </Menu.Item>
        <Menu.Item
          key={sideBarKeyStrings.fatura}
          icon={<ProfileOutlined />}
          onClick={() =>
            onClickMenuItem(sideBarKeyStrings.fatura, "/FaturaListe")
          }
        >
          {sideBar.faturalar}
        </Menu.Item>
        <Menu.Item
          key={sideBarKeyStrings.teklifSiparis}
          icon={<FileSyncOutlined />}
          onClick={() =>
            onClickMenuItem(sideBarKeyStrings.teklifSiparis, "/TeklifSiparisListe")
          }
        >
          {sideBar.teklifSiparis}
        </Menu.Item>
        <Menu.Item
          key={sideBarKeyStrings.kasa}
          icon={<DatabaseOutlined />}
          onClick={() => onClickMenuItem(sideBarKeyStrings.kasa, "/KasaListe")}
        >
          {sideBar.kasalar}
        </Menu.Item>
        <Menu.Item
          key={sideBarKeyStrings.banka}
          icon={<BankOutlined />}
          onClick={() =>
            onClickMenuItem(sideBarKeyStrings.banka, "/BankaHesapListe")
          }
        >
          {sideBar.bankaHesaplar}
        </Menu.Item>
        <SubMenu
          key={"raporlar"}
          icon={<ProjectOutlined />}
          title={sideBar.raporlar}
        >
          <Menu.Item
            key={sideBarKeyStrings.cariRapor}
            onClick={() =>
              onClickMenuItem(sideBarKeyStrings.cariRapor, "/CariRapor")
            }
          >
            {sideBar.raporlarAltMenu.cariRapor}
          </Menu.Item>
          <Menu.Item
            key={sideBarKeyStrings.cariIslemRapor}
            onClick={() =>
              onClickMenuItem(
                sideBarKeyStrings.cariIslemRapor,
                "/CariIslemRapor"
              )
            }
          >
            {sideBar.raporlarAltMenu.cariIslemRapor}
          </Menu.Item>
          <Menu.Item
            key={sideBarKeyStrings.cariEkstreRapor}
            onClick={() =>
              onClickMenuItem(
                sideBarKeyStrings.cariEkstreRapor,
                "/CariEkstreRapor"
              )
            }
          >
            {sideBar.raporlarAltMenu.cariEksteRapor}
          </Menu.Item>
          <Menu.Item
            key={sideBarKeyStrings.kasaRapor}
            onClick={() =>
              onClickMenuItem(sideBarKeyStrings.kasaRapor, "/KasaRapor")
            }
          >
            {sideBar.raporlarAltMenu.kasaDetayRapor}
          </Menu.Item>
          <Menu.Item
            key={sideBarKeyStrings.bankaRapor}
            onClick={() =>
              onClickMenuItem(sideBarKeyStrings.bankaRapor, "/BankaRapor")
            }
          >
            {sideBar.raporlarAltMenu.bankaDetayRapor}
          </Menu.Item>
          <Menu.Item
            key={sideBarKeyStrings.stokRapor}
            onClick={() =>
              onClickMenuItem(sideBarKeyStrings.stokRapor, "/StokRapor")
            }
          >
            {sideBar.raporlarAltMenu.stokRapor}
          </Menu.Item>
          <Menu.Item
            key={sideBarKeyStrings.stokHareketRapor}
            onClick={() =>
              onClickMenuItem(
                sideBarKeyStrings.stokHareketRapor,
                "/StokHareketRapor"
              )
            }
          >
            {sideBar.raporlarAltMenu.stokHareketRapor}
          </Menu.Item>
          <Menu.Item
            key={sideBarKeyStrings.stokEkstreRapor}
            onClick={() =>
              onClickMenuItem(
                sideBarKeyStrings.stokEkstreRapor,
                "/StokEkstreRapor"
              )
            }
          >
            {sideBar.raporlarAltMenu.stokEkstreRapor}
          </Menu.Item>
          <Menu.Item
            key={sideBarKeyStrings.faturaRapor}
            onClick={() =>
              onClickMenuItem(sideBarKeyStrings.faturaRapor, "/FaturaRapor")
            }
          >
            {sideBar.raporlarAltMenu.faturaRapor}
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default SideBar;
