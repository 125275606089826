import React, { useEffect, useState } from "react";
import { Form, message, Card, notification } from "antd";
import FaturaLayout from "../components/faturaDuzenle/FaturaLayout";
import strings from "../utils/Localization";
import { useParams } from "react-router-dom";
import { api } from "../helpers/ApiHelper";
import moment from "moment";
import { faturaTypes } from "../types/faturaTypes";
import { cariIslemTurTypes } from "../types/cariIslemTurTypes";
import { odemeSekliTypes } from "../types/odemeSekliTypes";

function FaturaDuzenle() {
  const [form] = Form.useForm();
  const { faturaId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { faturaDuzenle: localization } = strings;

  useEffect(() => {
    fetchFatura();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchFatura = async () => {
    try {
      setLoading(true);
      const response = await api.post("/fatura/get", {
        Data: {
          Id: faturaId,
        },
        Includes: [
          "Faturasatir",
          "Faturasatir.Faturasatiriskonto",
          "Faturasatir.StokVaryantGrup",
          "Faturasatir.StokVaryantGrup.StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger",
          "Faturasatir.StokBirim",
          "Faturasatir.Stokislem.StokDepo",
          "Cariislembaglanti.CariIslem.AltCariIslem.Cariislembaglanti",
          "Doviz",
        ],
      });
      if (response.status === 200) {
        const result = response.data.data[0];
        form.setFieldsValue({
          faturaId: result.id,
          faturaTip: result.faturaTip,
          cariKartId: result.cariKartId,
          yetkili: result.yetkili,
          vergiDairesi: result.vergiDairesi,
          vergiNumarasi: result.vergiNumarasi,
          telefon: result.telefon,
          cepTelefonu: result.cepTelefonu,
          eMail: result.eMail,
          il: result.il,
          ilce: result.ilce,
          adres: result.adres,
          sevkAdresi: result.sevkAdresi,
          aciklama: result.aciklama,
          firmaAdi: result.firmaAdi,
          dovizId: String(result.doviz.id),
          odemeSekli: result.odemeSekli,
          kasaId:
            result.odemeSekli === odemeSekliTypes.ACIK_HESAP
              ? null
              : String(
                  result.cariislembaglanti.cariIslem.altCariIslem[0]
                    .cariislembaglanti.kasaId
                ),
          bankaHesapId:
            result.odemeSekli === odemeSekliTypes.ACIK_HESAP
              ? null
              : String(
                  result.cariislembaglanti.cariIslem.altCariIslem[0]
                    .cariislembaglanti.bankaHesapId
                ),
          faturaTarihi: moment(result.faturaTarihi),
          faturaSaati: moment(result.faturaSaati, "HH:mm:ss"),
          vadeTarihi: moment(result.cariislembaglanti.cariIslem.vadeTarih),
          sevkTarihi: moment(result.sevkTarihi),
          faturaNo: result.faturaNo,
          sevkNo: result.sevkNumarasi,
          faturaSatir: result.faturasatir.map((faturaSatirItem) => {
            return {
              id: faturaSatirItem.id,
              stokBirimId: faturaSatirItem.stokBirimId,
              urunAdi: faturaSatirItem.urunAdi,
              barkod: faturaSatirItem.barkod,
              stokId: faturaSatirItem.stokVaryantGrupId,
              stokVaryantGrupId: faturaSatirItem.stokVaryantGrupId,
              varyantGrupAdi:
                faturaSatirItem.stokVaryantGrup.stokvaryantgrupHasStokvaryantdeger
                  .map((varyantGrupItem) => {
                    return varyantGrupItem.stokVaryantDeger.ad + " - ";
                  })
                  .join(""),
              aciklama: faturaSatirItem.aciklama,
              stokDepoId: String(faturaSatirItem.stokislem.stokDepo.id),
              miktar: faturaSatirItem.miktar,
              birimFiyat: faturaSatirItem.birimFiyat,
              kdvOran: faturaSatirItem.kdvOran,
              kdvTutar: faturaSatirItem.kdvTutar,
              otvOran: faturaSatirItem.otvOran,
              otvTutar: faturaSatirItem.otvTutar,
              toplam: faturaSatirItem.genelToplam,
              iskontoId: faturaSatirItem.faturasatiriskonto[0].id,
              iskontoOran: faturaSatirItem.faturasatiriskonto[0].oran,
              iskontoTip:
                faturaSatirItem.faturasatiriskonto[0].iskontoTip === "Oran"
                  ? "yuzde"
                  : "tutar",
              iskontoTutar: faturaSatirItem.faturasatiriskonto[0].tutar,
              yeniSatirMi: false,
            };
          }),
          genelIskontoOran: result.araToplamIskontoOran,
          genelIskontoTip:
            result.araToplamIskontoTip === "Oran" ? "yuzde" : "tutar",
          genelIskontoTutar: result.araToplamIskontoTutar,
          stopajOran: result.stopajOran,
          stopajTutar: result.stopajTutar,
          tevkifatOran: result.tevkifatOran,
          tevkifatTutar: result.tevkifatTutar,
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      const {
        faturaId,
        faturaNo,
        firmaAdi,
        yetkili,
        vergiDairesi,
        vergiNumarasi,
        telefon,
        cepTelefonu,
        eMail,
        il,
        ilce,
        adres,
        sevkAdresi,
        faturaTarihi,
        vadeTarihi,
        faturaSaati,
        sevkTarihi,
        sevkNo,
        aciklama,
        stopajOran,
        stopajTutar,
        tevkifatOran,
        tevkifatTutar,
        araToplamTutar,
        genelIskontoOran,
        genelIskontoTutar,
        genelIskontoTip,
        toplamSatirIskontoTutar,
        genelToplamTutar,
        faturaSatir,
      } = values;

      let requestData = {
        Id: faturaId,
        CariIslemAcikHesapAciklama: `${faturaNo} numaralı faturanın ${cariIslemTur()} işlemidir`,
        CariIslemOdemeAciklama: `${faturaNo} numaralı faturanın ${
          cariIslemTur() === "Borc" ? "Tahsilat" : "Odeme"
        } işlemidir`,
        FirmaAdi: firmaAdi,
        Yetkili: yetkili,
        VergiDairesi: vergiDairesi,
        VergiNumarasi: vergiNumarasi,
        Telefon: telefon,
        CepTelefonu: cepTelefonu,
        EMail: eMail,
        // CariKod: faturaFetchState.cariKod,
        Il: il,
        Ilce: ilce,
        Adres: adres,
        SevkAdresi: sevkAdresi,
        FaturaNo: faturaNo,
        FaturaTarihi: moment(faturaTarihi),
        SevkTarihi: moment(sevkTarihi),
        CariIslemVadeTarih :moment(vadeTarihi),
        SevkNumarasi: sevkNo,
        FaturaSaati: moment(faturaSaati).format("HH:mm:ss"),
        Aciklama: aciklama,
        StopajOran: stopajOran,
        StopajTutar: stopajTutar,
        TevkifatOran: tevkifatOran,
        TevkifatTutar: tevkifatTutar,
        Toplam: araToplamTutar,
        AraToplamIskontoOran: genelIskontoOran,
        AraToplamIskontoTutar: genelIskontoTutar,
        AraToplamIskontoTip: genelIskontoTip === "yuzde" ? "Oran" : "Tutar",
        FaturaSatirIskontoToplamTutar: toplamSatirIskontoTutar,
        GenelToplam: genelToplamTutar,
        UpdatedAt: moment(),
        Faturasatir: faturaSatir.map((faturaSatirItem) => {
          if (faturaSatirItem.id != null) {
            return {
              //eğer güncellenen satir ise
              Id: faturaSatirItem.id,
              Miktar: faturaSatirItem.miktar,
              BirimFiyat: faturaSatirItem.birimFiyat,
              Aciklama: faturaSatirItem.aciklama,
              OtvTutar: faturaSatirItem.otvTutar,
              OtvOran: faturaSatirItem.otvOran,
              // OivTutar: faturaSatirItem.oivTutar,
              // OivOran: faturaSatirItem.oivOran,
              KdvTutar: faturaSatirItem.kdvTutar,
              KdvOran: faturaSatirItem.kdvOran,
              UpdatedAt: moment(),
              Faturasatiriskonto: [
                {
                  Id: faturaSatirItem.iskontoId,
                  Oran: faturaSatirItem.iskontoOran,
                  Tutar: faturaSatirItem.iskontoTutar,
                  IskontoTip:
                    faturaSatirItem.iskontoTip === "yuzde" ? "Oran" : "Tutar",
                },
              ],
              GenelToplam: faturaSatirItem.toplam,
            };
          } else {
            return {
              //eğer yeni eklenen satir ise
              StokVaryantGrupId: faturaSatirItem.stokVaryantGrupId,
              StokBirimId: faturaSatirItem.stokBirimId,
              UrunAdi: faturaSatirItem.urunAdi,
              Miktar: faturaSatirItem.miktar,
              BirimFiyat: faturaSatirItem.birimFiyat,
              Barkod: faturaSatirItem.barkod,
              Aciklama: faturaSatirItem.aciklama,
              OtvTutar: faturaSatirItem.otvTutar,
              OtvOran: faturaSatirItem.otvOran,
              // OivTutar: faturaSatirItem.oivTutar,
              // OivOran: faturaSatirItem.oivOran,
              KdvTutar: faturaSatirItem.kdvTutar,
              KdvOran: faturaSatirItem.kdvOran,
              Stokislem: {
                StokDepoId: faturaSatirItem.stokDepoId,
                GirisMi: faturaGirisMi(),
              },
              Faturasatiriskonto: [
                {
                  Oran: faturaSatirItem.iskontoOran,
                  Tutar: faturaSatirItem.iskontoTutar,
                  IskontoTip:
                    faturaSatirItem.iskontoTip === "yuzde" ? "Oran" : "Tutar",
                },
              ],
              GenelToplam: faturaSatirItem.toplam,
            };
          }
        }),
      };
      setFetchLoading(true);
      const response = await api.put("/fatura/update", requestData);

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const faturaGirisMi = () => {
    const faturaTip = form.getFieldValue("faturaTip");
    switch (faturaTip) {
      case faturaTypes.SATIS_FATURASI:
        return false;
      case faturaTypes.ALIS_FATURASI:
        return true;
      case faturaTypes.SATIS_IADE_FATURASI:
        return true;
      case faturaTypes.ALIS_IADE_FATURASI:
        return false;
      default:
        return null;
    }
  };
  const cariIslemTur = () => {
    const faturaTip = form.getFieldValue("faturaTip");
    switch (faturaTip) {
      case faturaTypes.SATIS_FATURASI:
        return cariIslemTurTypes.BORC;
      case faturaTypes.ALIS_FATURASI:
        return cariIslemTurTypes.ALACAK;
      case faturaTypes.SATIS_IADE_FATURASI:
        return cariIslemTurTypes.ALACAK;
      case faturaTypes.ALIS_IADE_FATURASI:
        return cariIslemTurTypes.BORC;
      default:
        return null;
    }
  };

  if (loading) {
    return <Card loading={loading} />;
  }

  return (
    <>
      <FaturaLayout
        onFinish={onFinish}
        form={form}
        saveButtonTitle={localization.guncelleButon}
        saveButtonLoading={fetchLoading}
        title={localization.baslik}
      />
    </>
  );
}

export default FaturaDuzenle;
