import * as actionTypes from './actionTypes'

export const setLightTheme = () => ({
    type: actionTypes.SET_LIGHT_THEME,
    payload: 'light'
})

export const setDarkTheme = () => ({
    type: actionTypes.SET_DARK_THEME,
    payload: 'dark'
})