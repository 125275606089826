import React from "react";
import { Modal, Row, Col, Divider, Button } from "antd";
import strings from "../../utils/Localization";

const ModelItem = ({ title, text }) => (
  <div style={{ marginBottom: 16 }}>
    <strong style={{ fontSize: 16 }}>{title}</strong>
    <br />
    <span style={{ fontSize: 14 }}>{text}</span>
    <Divider />
  </div>
);

export default function ProfilDetay({
  visible = false,
  kapatOnClick,
  cariKart = {},
}) {
  return (
    <Modal
      width={"70%"}
      title={strings.cariDetay.components.profilDetay.baslik}
      visible={visible}
      onCancel={kapatOnClick}
      footer={[
        <Button key="submit" type="primary" size="large" onClick={kapatOnClick}>
          {strings.cariDetay.components.profilDetay.kapatButon}
        </Button>,
      ]}
    >
      <Row gutter={24}>
        <Col xl={8} lg={8} md={12} xs={12}>
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.firmaAdi}:`}
            text={cariKart.firmaAdi}
          />
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.unvan}:`}
            text={cariKart.yetkili}
          />
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.cariGrup}:`}
            text={cariKart.cariGrup !== undefined ? cariKart.cariGrup.ad : ""}
          />
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.cepTelefonu}:`}
            text={cariKart.cepTelefonu}
          />
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.telefon}:`}
            text={cariKart.telefon}
          />
        </Col>
        <Col xl={8} lg={8} md={12} xs={12}>
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.eMail}:`}
            text={cariKart.eMail}
          />
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.vergiDairesi}:`}
            text={cariKart.vergIdairesi}
          />
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.vergiNumarasi}:`}
            text={cariKart.vergiNumarasi}
          />
        </Col>
        <Col xl={8} lg={8} md={12} xs={12}>
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.il}:`}
            text={cariKart.il}
          />
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.ilce}:`}
            text={cariKart.ilce}
          />
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.adres}:`}
            text={cariKart.adres}
          />
          <ModelItem
            title={`${strings.cariDetay.components.profilDetay.sevkAdresi}:`}
            text={cariKart.sevkAdresi}
          />
        </Col>
      </Row>
    </Modal>
  );
}
