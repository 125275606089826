import React, { useState } from "react";
import { Button, Divider, Form, Input, message } from "antd";
import DilSecim from "../navi/navBar/DilSecim";
import strings from "../../utils/Localization";
import { useHistory } from "react-router-dom";
import { api } from "../../helpers/ApiHelper";
const md5 = require("md5");

export default function GirisYapForm() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const history = useHistory();
  const { girisYapForm } = strings.girisYap.components;

  const onFinish = async (values) => {
    try {
      setFetchLoading(true);
      const { eMail, sifre } = values;
      const response = await api.post("/auth/login", {
        Email: eMail,
        Password: md5(sifre),
      });

      if (response.status === 200) {
        localStorage.setItem("token", response.data.singleData.token);
        localStorage.setItem(
          "defaultSettings",
          JSON.stringify(response.data.singleData.defaultSettings)
        );
        localStorage.setItem("eMail", eMail);
        history.push("/");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      size="large"
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <DilSecim alignRight />
      <Divider />
      <Form.Item
        name="eMail"
        rules={[
          { required: true, message: girisYapForm.kurallar.emailZorunlu },
          { type: "email", message: girisYapForm.kurallar.emailFormat },
        ]}
      >
        <Input placeholder={girisYapForm.eMailPlaceHolder} />
      </Form.Item>

      <Form.Item
        name="sifre"
        rules={[
          { required: true, message: girisYapForm.kurallar.sifreZorunlu },
          { min: 6, message: girisYapForm.kurallar.sifreMinUzunluk },
        ]}
      >
        <Input.Password placeholder={girisYapForm.sifrePlaceHolder} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          size="large"
          loading={fetchLoading}
        >
          {girisYapForm.girisYapButon}
        </Button>
      </Form.Item>
    </Form>
  );
}
