import React from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import strings from '../../utils/Localization'

export default function Datepicker(props) {

    moment.updateLocale('en', {
        weekdaysMin: strings.tarih.hafta,
        monthsShort: strings.tarih.ay
    })

    return (
        <DatePicker
            placeholder={strings.tarih.tarihSeciniz}
            format={strings.tarih.format}
            style={{ width: '100%' }}
            {...props}
        />
    )
}
