import React, { useEffect, useState } from "react";
import { Form, message, Select } from "antd";
import strings from "../../utils/Localization";
import { api } from "../../helpers/ApiHelper";

const { Option } = Select;

function KasaSelectOption({
  formItemName,
  formItemLabel,
  required,
  placeholder,
  hidden,
  showDovizKod = false,
  requestBody = {},
  rules,
  onSelectedKasa = () => {},
  disabled,
  allowClear,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [kasaData, setKasaData] = useState([]);
  useEffect(() => {
    fetchKasa();
  }, [requestBody]); // eslint-disable-line react-hooks/exhaustive-deps
  const fetchKasa = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/kasa/get", {
        Includes: ["Doviz"],
        SortOptions: {
          ColumnName: "Id",
          SortType: "Asc",
        },
        ...requestBody,
      });
      if (response.status === 200) {
        setKasaData(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Form.Item
      name={formItemName}
      label={formItemLabel}
      rules={
        rules
          ? rules
          : [{ required: required, message: strings.mesaj.gerekliBirAlan }]
      }
      hidden={hidden}
    >
      <Select
        placeholder={placeholder || strings.kasaSelectOption.placeHolder}
        loading={fetchLoading}
        onChange={(e) =>
          onSelectedKasa(kasaData.find((x) => x.id === parseInt(e)))
        }
        disabled={disabled}
        allowClear={allowClear}
      >
        {kasaData.map((item, index) => (
          <Option key={index} value={String(item.id)}>
            {`${item.ad} ${showDovizKod ? "(" + item.doviz.kod + ")" : ""}`}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default KasaSelectOption;
