import axios from "axios";

export const api = axios.create({
  baseURL: "https://api.aymyazilim.com/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token") || "";
    config.headers["Authorization"] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
    }
    // setTimeout(() => {
    //   return (window.location.href = "https://youtu.be/2f4015PEFTc?t=57");
    // }, 2000);
    let responseMessage = undefined;
    if (error.response) {
      if (typeof error.response.data == "string") {
        responseMessage = error.response.data;
      } else {
        responseMessage = error.response.data.message;
      }
    }
    return Promise.reject(new Error(responseMessage || "Uyarı"));
  }
);
