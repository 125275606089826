import React from 'react'
import { Space, Row, Col, Drawer, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import TemaSecim from './TemaSecim';
import DilSecim from './DilSecim';
// import DonemSecim from './DonemSecim';
import KullaniciAyarlari from './KullaniciAyarlari';

const Container = styled.div`
    display:flex;
    flex-direction:row-reverse;
    align-items:center;
`

export default function Index() {
    const [drawerVisible, setDrawerVisible] = React.useState(false)

    return (
        <Container>
            <Row>
                <Col xs={0} sm={0} md={24} lg={24} xl={24}>
                    <Space size={"large"} align="center">
                        <DilSecim />
                        <TemaSecim />
                        {/* <DonemSecim /> */}
                        <KullaniciAyarlari />
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={0} lg={0} xl={0}>
                    <MenuOutlined style={{ fontSize: 22 }} onClick={() => { setDrawerVisible(true) }} />
                </Col>
            </Row>
            <Drawer
                title="Menü"
                placement="right"
                closable={false}
                onClose={() => setDrawerVisible(false)}
                visible={drawerVisible}
                bodyStyle={{ padding: 0 }}
            >
                <Menu>
                    <Menu.Item>
                        <DilSecim />
                    </Menu.Item>
                    <Menu.Item>
                        <TemaSecim />
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        {/* <DonemSecim /> */}
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        <KullaniciAyarlari />
                    </Menu.Item>
                    <Menu.Divider />
                </Menu>
            </Drawer>
        </Container >
    )
}
