import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  message,
  notification,
} from "antd";
import { api } from "../../helpers/ApiHelper";
import moment from "moment";
import strings from "../../utils/Localization";
import { setRerender } from "../../redux/actions/rerenderAction";
import { useDispatch } from "react-redux";
import Datepicker from "../toolbox/DatePicker";

const formSize = "large";

function KasaVirmanDuzenleModal({
  visible = false,
  handleCancel,
  kasaIslemId = null,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { virmanDuzenleModal: localization } = strings.kasaIslem;

  useEffect(() => {
    if (kasaIslemId !== null) {
      kasaIslemGetVirman();
    }
  }, [kasaIslemId]); // eslint-disable-line react-hooks/exhaustive-deps

  const kasaIslemGetVirman = async () => {
    try {
      const response = await api.post("/kasaislem/get", {
        Data: { Id: kasaIslemId },
        Includes: [
          "KasaislemHasBankahesapislem.BankaHesapIslem.BankaHesap.Banka",
          "KasaislemHasBankahesapislem.BankaHesapIslem.BankaHesap.Doviz",
          "KasaislemHasKasaislemKasaIslemCikis.KasaIslemGiris.Kasa.Doviz",
          "KasaislemHasKasaislemKasaIslemGiris.KasaIslemCikis.Kasa.Doviz",
          "Kasa.Doviz",
        ],
      });

      if (response.status === 200) {
        const {
          girisMi,
          id,
          kasa,
          tutar,
          carpan,
          evrakNo,
          aciklama,
          islemTarihi,
          virmanMi,
        } = response.data.data[0];

        if (girisMi === true) {
          form.setFieldsValue({
            aliciId: id,
            aliciAdi: kasa.ad,
            aliciDoviz: kasa.doviz,
            girenTutar: tutar,
            carpan: carpan,
            islemTarihi: moment(islemTarihi),
            evrakNo: evrakNo,
            aciklama: aciklama,
            virmanMi,
            girisMi,
          });

          if (virmanMi === 1) {
            const { id, kasa, tutar } =
              response.data.data[0].kasaislemHasKasaislemKasaIslemGiris[0]
                .kasaIslemCikis;
            form.setFieldsValue({
              gondericiId: id,
              gondericiAdi: kasa.ad,
              cikanTutar: tutar,
              gondericiDoviz: kasa.doviz,
            });
          } else if (virmanMi === 2) {
            const { id, bankaHesap, tutar } =
              response.data.data[0].kasaislemHasBankahesapislem[0]
                .bankaHesapIslem;
            form.setFieldsValue({
              gondericiId: id,
              gondericiAdi: bankaHesap.ad,
              cikanTutar: tutar,
              gondericiDoviz: bankaHesap.doviz,
            });
          }
        } else {
          form.setFieldsValue({
            gondericiId: id,
            gondericiAdi: kasa.ad,
            gondericiDoviz: kasa.doviz,
            cikanTutar: tutar,
            carpan: carpan,
            islemTarihi: moment(islemTarihi),
            evrakNo: evrakNo,
            aciklama: aciklama,
            virmanMi,
            girisMi,
          });
          if (virmanMi === 1) {
            const { id, kasa, tutar } =
              response.data.data[0].kasaislemHasKasaislemKasaIslemCikis[0]
                .kasaIslemGiris;
            form.setFieldsValue({
              aliciId: id,
              aliciAdi: kasa.ad,
              girenTutar: tutar,
              aliciDoviz: kasa.doviz,
            });
          } else if (virmanMi === 2) {
            const { id, bankaHesap, tutar } =
              response.data.data[0].kasaislemHasBankahesapislem[0]
                .bankaHesapIslem;
            form.setFieldsValue({
              aliciId: id,
              aliciAdi: bankaHesap.ad,
              girenTutar: tutar,
              aliciDoviz: bankaHesap.doviz,
            });
          }
        }
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const onFinish = (values) => {
    const { virmanMi, girisMi } = values;

    if (virmanMi === 1) {
      kasaToKasaUpdate(values);
    } else if (virmanMi === 2) {
      if (girisMi === true) {
        bankaToKasaUpdate(values);
      } else {
        kasaToBankaUpdate(values);
      }
    }
  };

  const kasaToKasaUpdate = async (values) => {
    try {
      const {
        gondericiId,
        evrakNo,
        aciklama,
        cikanTutar,
        gondericiDoviz,
        aliciDoviz,
        carpan,
        islemTarihi,
      } = values;
      setFetchLoading(true);
      const response = await api.put("/kasaislem/virman/updatekasatokasa", {
        Id: gondericiId,
        EvrakNo: evrakNo,
        Aciklama: aciklama,
        CikanKasaTutar: cikanTutar,
        GirenKasaTutar:
          gondericiDoviz.id !== aliciDoviz.id
            ? cikanTutar * carpan
            : cikanTutar,
        IslemTarihi: islemTarihi,
        Carpan: carpan,
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const kasaToBankaUpdate = async (values) => {
    try {
      const {
        gondericiId,
        evrakNo,
        aciklama,
        cikanTutar,
        gondericiDoviz,
        aliciDoviz,
        carpan,
        islemTarihi,
      } = values;
      setFetchLoading(true);
      const response = await api.put("/kasaislem/virman/updatekasatobanka", {
        KasaIslemId: gondericiId,
        EvrakNo: evrakNo,
        Aciklama: aciklama,
        CikanKasaTutar: cikanTutar,
        GirenBankaHesapTutar:
          gondericiDoviz.id !== aliciDoviz.id
            ? cikanTutar * carpan
            : cikanTutar,
        IslemTarihi: islemTarihi,
        Carpan: carpan,
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const bankaToKasaUpdate = async (values) => {
    try {
      const {
        aliciId,
        evrakNo,
        aciklama,
        cikanTutar,
        gondericiDoviz,
        aliciDoviz,
        carpan,
        islemTarihi,
      } = values;
      setFetchLoading(true);
      const response = await api.put("/kasaislem/virman/updatebankatokasa", {
        KasaIslemId: aliciId,
        EvrakNo: evrakNo,
        Aciklama: aciklama,
        CikanBankaHesapTutar: cikanTutar,
        GirenKasaTutar:
          gondericiDoviz.id !== aliciDoviz.id
            ? cikanTutar * carpan
            : cikanTutar,
        IslemTarihi: islemTarihi,
        Carpan: carpan,
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(true);
    }
  };

  const kurHesapla = (fieldName) => {
    const { cikanTutar, carpan, girenTutar } = form.getFieldValue();

    if (fieldName === "cikanTutar") {
      const currentGirenTutar = parseFloat(cikanTutar * carpan).toFixed(2);
      form.setFieldsValue({
        girenTutar: currentGirenTutar,
      });
    } else if (fieldName === "carpan") {
      const currentGirenTutar = parseFloat(cikanTutar * carpan).toFixed(2);
      form.setFieldsValue({
        girenTutar: currentGirenTutar,
      });
    } else if (fieldName === "girenTutar") {
      const currentCikanTutar = parseFloat(girenTutar / carpan).toFixed(2);
      form.setFieldsValue({
        cikanTutar: currentCikanTutar,
      });
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "kasaVirmanDuzenleForm",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      width="60%"
    >
      <Form
        form={form}
        id="kasaVirmanDuzenleForm"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={12}>
          <Col xxl={12} xl={12} md={12} sm={12} xs={24}>
            <Form.Item name="islemTarihi" label={localization.islemTarihi}>
              <Datepicker />
            </Form.Item>
            <Form.Item name="gondericiAdi" label={localization.gonderici}>
              <Input readOnly />
            </Form.Item>
            <Form.Item name="aliciAdi" label={localization.alici}>
              <Input readOnly />
            </Form.Item>
            <Form.Item name="evrakNo" label={localization.evrakNo}>
              <Input />
            </Form.Item>
            <Form.Item name="aciklama" label={localization.aciklama}>
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} md={12} sm={12} xs={24}>
            <Row gutter={12}>
              <Col flex={1}>
                <Form.Item
                  name="cikanTutar"
                  label={localization.cikanTutar}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "100%" }}
                    onChange={() => kurHesapla("cikanTutar")}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item shouldUpdate label={localization.doviz}>
                  {({ getFieldValue }) => {
                    let kod = "";
                    let simge = "";
                    const gondericiDoviz = getFieldValue("gondericiDoviz");
                    if (gondericiDoviz !== undefined) {
                      kod = gondericiDoviz.kod;
                      simge = gondericiDoviz.simge;
                    }

                    return <Input value={`${kod} (${simge})`} />;
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const aliciDoviz = getFieldValue("aliciDoviz");
                const gondericiDoviz = getFieldValue("gondericiDoviz");
                if (gondericiDoviz === undefined || aliciDoviz === undefined)
                  return null;
                if (aliciDoviz.id !== gondericiDoviz.id) {
                  return (
                    <>
                      <Form.Item
                        name="carpan"
                        label={localization.kur}
                        rules={[{ required: true }]}
                      >
                        <Input
                          type="number"
                          onChange={() => kurHesapla("carpan")}
                        />
                      </Form.Item>
                      <Row gutter={12}>
                        <Col flex={1}>
                          <Form.Item
                            name="girenTutar"
                            label={localization.girenTutar}
                            rules={[{ required: true }]}
                          >
                            <InputNumber
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              style={{ width: "100%" }}
                              onChange={() => kurHesapla("girenTutar")}
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item shouldUpdate label={localization.doviz}>
                            {({ getFieldValue }) => {
                              let kod = "";
                              let simge = "";
                              const gondericiDoviz =
                                getFieldValue("aliciDoviz");
                              if (gondericiDoviz !== undefined) {
                                kod = gondericiDoviz.kod;
                                simge = gondericiDoviz.simge;
                              }

                              return <Input value={`${kod} (${simge})`} />;
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  );
                }
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="gondericiDoviz" hidden noStyle />
        <Form.Item name="aliciDoviz" hidden noStyle />
        <Form.Item name="aliciId" hidden noStyle />
        <Form.Item name="gondericiId" hidden noStyle />
        <Form.Item name="virmanMi" hidden noStyle />
        <Form.Item name="girisMi" hidden noStyle />
      </Form>
    </Modal>
  );
}

export default KasaVirmanDuzenleModal;
