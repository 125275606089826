import React, { useState } from "react";
import { Button, message } from "antd";
import { useHistory } from "react-router-dom";
import strings from "../../utils/Localization";
import md5 from "md5";
import { api } from "../../helpers/ApiHelper";

const eMail = "aymyazilim@gmail.com";
const sifre = "123456";

const buttonStyle = { marginTop: 16 };

export default function DemoButton() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const history = useHistory();
  const girisYap = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/auth/login", {
        Email: eMail,
        Password: md5(sifre),
      });

      if (response.status === 200) {
        localStorage.setItem("token", response.data.singleData.token);
        localStorage.setItem(
          "defaultSettings",
          JSON.stringify(response.data.singleData.defaultSettings)
        );
        localStorage.setItem("eMail", eMail);
        history.push("/");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  return (
    <Button
      type="text"
      size="middle"
      block
      style={buttonStyle}
      onClick={girisYap}
      loading={fetchLoading}
    >
      {strings.girisYap.components.demoButton.butonYazi}
    </Button>
  );
}
