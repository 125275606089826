import React, { useState, useEffect } from "react";
import Avatar from "../toolbox/Avatar";
import { Button, Card, Divider, message, Space, Typography } from "antd";
import strings from "../../utils/Localization";
import { InfoCircleOutlined } from "@ant-design/icons";
import ProfilDetay from "./ProfilDetay";
import CariBakiye from "./CariBakiye";
import { api } from "../../helpers/ApiHelper";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const MyDivider = () => <Divider style={{ marginTop: 12, marginBottom: 12 }} />;

export default function Profil() {
  const [cariKart, setCariKart] = useState({});
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const { cariKartId } = useParams();

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = async () => {
    try {
      const response = await api.post("carikart/get", {
        Includes: ["CariGrup"],
        Data: {
          Id: cariKartId,
        },
      });
      if (response.status === 200) {
        setCariKart(response.data.data[0]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        bodyStyle={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        loading={loading}
      >
        <Space direction="vertical" align="center">
          <Avatar text={cariKart.firmaAdi} />
          <Title level={4} style={{ marginTop: 8 }}>
            {cariKart.firmaAdi}
          </Title>
        </Space>
        <MyDivider />
        <Button
          onClick={() => setVisible(true)}
          style={{ marginBottom: 16, marginTop: 6 }}
          icon={<InfoCircleOutlined />}
          type="default"
          size="middle"
          block
        >
          {strings.cariDetay.components.profil.tumBilgileriGoster}
        </Button>
        <CariBakiye />
      </Card>
      <ProfilDetay
        visible={visible}
        kapatOnClick={() => setVisible(!visible)}
        cariKart={cariKart}
      />
    </>
  );
}
