import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const selectedStokReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_STOK:
      return action.payload;
    default:
      return state;
  }
};

export default selectedStokReducer;
