import {
  Card,
  Button,
  Space,
  Popconfirm,
  message,
  notification,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import strings from "../../utils/Localization";
import CursorTableItem from "../toolbox/CursorTableItem";
import FetchListContent from "../toolbox/FetchListContent";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { api } from "../../helpers/ApiHelper";
import { PlusOutlined } from "@ant-design/icons";
import CariKartEkleModal from "../cariKart/CariKartEkleModal";
import CariKartDuzenleModal from "../cariKart/CariKartDuzenleModal";
import useCurrency from "../../hooks/useCurrency";

const CariKartListe = () => {
  const [dataSource, setDataSource] = useState({});
  const [cariKartEkleModalVisible, setCariKartEkleModalVisible] =
    useState(false);
  const [cariKartDuzenleModalVisible, setCariKartDuzenleModalVisible] =
    useState(false);
  const [duzenleData, setDuzenleData] = useState({});
  const history = useHistory();
  const { paraFormat } = useCurrency();
  const { cariKartListe: localization } = strings;

  const columns = [
    {
      title: localization.firmaAdi,
      dataIndex: "firmaAdi",
      key: "firmaAdi",
      sorter: (a, b) => a.firmaAdi.length - b.firmaAdi.length,
      render: (text, row, index) => (
        <CursorTableItem
          text={`${row.firmaAdi} (${row.islemSayisi})`}
          onClick={() => history.push(`/CariDetay/${row.id}`)}
        />
      ),
    },
    {
      title: localization.yetkili,
      dataIndex: "yetkili",
      key: "yetkili",
      sorter: (a, b) => {
        a.yetkili = a.yetkili || "";
        b.yetkili = b.yetkili || "";
        return a.yetkili.length - b.yetkili.length;
      },
    },
    {
      title: localization.telefon,
      dataIndex: "telefon",
      key: "telefon",
    },
    {
      title: localization.vergiNumarasi,
      dataIndex: "vergiNumarasi",
      key: "vergiNumarasi",
    },
    {
      title: localization.il,
      dataIndex: "il",
      key: "il",
    },
    {
      title: localization.bakiye,
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => (
        <Space direction="vertical">
          {row.bakiye.map((item, index) => (
            <Typography.Title
              level={5}
              key={index}
              style={{
                color: item.Tutar >= 0 ? "green" : "red",
                fontWeight: "400",
              }}
            >{`${paraFormat(item.Tutar)}  ${item.Doviz}`}</Typography.Title>
          ))}
          {row.bakiye.length === 0 && "-"}
        </Space>
      ),
    },
    {
      title: "",
      key: "edit",
      dataIndex: "edit",
      render: (text, row, index) => (
        <Space size="middle">
          <Button
            onClick={() => history.push(`/CariDetay/${row.id}`)}
            type="default"
            shape="circle"
            icon={<SearchOutlined />}
            size={"large"}
          />
          <Button
            onClick={() => {
              setDuzenleData(row);
              setCariKartDuzenleModalVisible(true);
            }}
            type="default"
            shape="circle"
            icon={<EditOutlined />}
            size={"large"}
          />
          <Popconfirm
            title={strings.mesaj.silmeIslemUyari}
            onConfirm={() => {
              if (row.islemSayisi > 0) {
                message.error({
                  content: strings.mesaj.cariKartSilme,
                  style: { marginTop: "20vh" },
                });
              } else remove(row.id);
            }}
          >
            <Button
              type="default"
              shape="circle"
              icon={<DeleteOutlined />}
              size={"large"}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const remove = async (cariKartId) => {
    try {
      const response = await api.delete(`/carikart/${cariKartId}`, {});
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== cariKartId),
        });
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  return (
    <>
      <Card
        title={localization.baslik}
        extra={[
          <Button
            key="1"
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => setCariKartEkleModalVisible(true)}
          >
            {localization.cariEkleButon}
          </Button>,
        ]}
      >
        <FetchListContent
          columns={columns}
          requestUrl="carikartliste/get"
          deleteUrl="carikart/"
          dataSource={dataSource}
          onDataSource={(e) => setDataSource(e)}
        />
      </Card>
      <CariKartEkleModal
        visible={cariKartEkleModalVisible}
        handleCancel={() => setCariKartEkleModalVisible(false)}
      />
      <CariKartDuzenleModal
        visible={cariKartDuzenleModalVisible}
        handleCancel={() => setCariKartDuzenleModalVisible(false)}
        data={duzenleData}
      />
    </>
  );
};

export default CariKartListe;
