import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message, notification } from "antd";
import { api } from "../../helpers/ApiHelper";
import strings from "../../utils/Localization";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";

const formSize = "middle";

function StokVaryantDegerEkleModal({
  visible = false,
  handleCancel,
  stokVaryantId = null,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [varyantId, setVaryantId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (stokVaryantId !== null) setVaryantId(stokVaryantId);
  }, [stokVaryantId]);

  const dispatch = useDispatch();
  const { stokVaryantDegerEkle: localization } =
    strings.stokVaryantDeger.components;

  const onFinish = async (values) => {
    try {
      setFetchLoading(true);
      const { varyantDegerAdi } = values;

      const response = await api.post("/stokvaryantdeger", {
        StokVaryantId: varyantId,
        ad: varyantDegerAdi,
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "varyantDegerEkleModal",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
    >
      <Form
        name="varyantDegerEkleModal"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size={formSize}
        initialValues={{
          varyantDegerAdi: "",
        }}
      >
        <Form.Item
          name="varyantDegerAdi"
          label={localization.varyantDegerAdi}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default StokVaryantDegerEkleModal;
