import { Input, Form } from "antd";
import React from "react";

function CurrencyInput({
  addonAfter,
  onChange,
  formItemName,
  formItemLabel,
  required,
  hidden,
  initialValue,
}) {
  return (
    <Form.Item
      hidden={hidden}
      label={formItemLabel}
      name={formItemName}
      rules={[{ required: required }]}
      initialValue={0.0}
    >
      <Input
        type="number"
        addonAfter={addonAfter}
        onChange={onChange}
        autoComplete={"off"}
        initialValue
      />
    </Form.Item>
  );
}

export default CurrencyInput;
