import React, { useState } from "react";
import { Button, Card, Form, message } from "antd";
import DatepickerRanger from "../toolbox/DatepickerRanger";
import strings from "../../utils/Localization";
import CariKartSelectOption from "../toolbox/CariKartSelectOption";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import { api } from "../../helpers/ApiHelper";
import moment from "moment";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";

export default function CariRaporForm() {
  const { cariRaporForm } = strings.cariRapor.components;
  const [fetchLoading, setFetchLoading] = useState(false);
  const { getDefaultSetting } = useVarsayilanDeger();

  const onFinish = async (values) => {
    try {
      const { tarih, cariKartId, dovizId } = values;
      const baslangicTarihi = moment(tarih[0]).format("YYYY-MM-DD");
      const bitisTarihi = moment(tarih[1]).format("YYYY-MM-DD");

      setFetchLoading(true);
      const response = await api.post(
        "/rapordosya/cari",
        {
          BaslangicTarih: `${baslangicTarihi} 00:00:00`,
          BitisTarih: `${bitisTarihi} 23:59:59`,
          CariKartId: cariKartId === undefined ? 0 : cariKartId,
          DovizId: dovizId === undefined ? 0 : dovizId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return window.open(response.data, "_blank");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Card>
      <Form
        layout="vertical"
        onFinish={onFinish}
        size="middle"
        initialValues={{
          tarih: [moment().add("month", -12), moment()],
          dovizId: getDefaultSetting("doviz"),
        }}
      >
        <DatepickerRanger
          formLabel={cariRaporForm.tarihSeciniz}
          formName="tarih"
          required
        />
        <CariKartSelectOption formItemName="cariKartId" allowClear />
        <DovizSelectOption formItemName="dovizId" allowClear />
        <Button
          type="primary"
          htmlType="submit"
          style={{ float: "right" }}
          size="large"
          loading={fetchLoading}
        >
          {cariRaporForm.raporAlButon}
        </Button>
      </Form>
    </Card>
  );
}
