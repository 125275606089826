import React, { useEffect, useState } from "react";
import { api } from "../../helpers/ApiHelper";
import { message, Select, Form } from "antd";
import strings from "../../utils/Localization";

const { Option } = Select;

function BankaSelectOption({ formItemName, disabled, required = true,allowClear }) {
  const [bankaData, setBankaData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { bankaPlaceHolder, bankaBaslik } = strings.bankaSelectOption;

  useEffect(() => {
    fetchBanka();
  }, []);

  const fetchBanka = async () => {
    try {
      setFetchLoading(true);

      const response = await api.post("/banka/get", {});
      if (response.status === 200) {
        setBankaData(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Form.Item
      name={formItemName}
      label={bankaBaslik}
      rules={[{ required: required }]}
    >
      <Select
        placeholder={bankaPlaceHolder}
        allowClear={allowClear}
        loading={fetchLoading}
        disabled={disabled}
      >
        {bankaData.map((item, index) => (
          <Option key={index} value={String(item.id)}>
            {item.ad}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default BankaSelectOption;
