import React from "react";
import { Button, Space } from "antd";
import { useHistory } from "react-router-dom";
import strings from "../../utils/Localization";
import { teklifSiparisTypes } from "../../types/teklifSiparisTypes";

export default function IslemButton() {
  const history = useHistory();
  const { islemButton: localization } = strings.teklifSipariListe.components;

  return (
    <>
      <Space direction="horizontal">
        <Button
          type="primary"
          size="large"
          onClick={() =>
            history.push({
              pathname: "/TeklifSiparisEkle",
              state: {
                teklifSiparisTip: teklifSiparisTypes.TEKLIF,
              },
            })
          }
        >
          {localization.yeniTeklif}
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={() =>
            history.push({
              pathname: "/TeklifSiparisEkle",
              state: {
                teklifSiparisTip: teklifSiparisTypes.SIPARIS,
              },
            })
          }
        >
          {localization.yeniSiparis}
        </Button>
      </Space>
    </>
  );
}
