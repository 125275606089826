import React, { useState } from "react";
import {
  Card,
  Button,
  Space,
  Tag,
  notification,
  message,
  Popconfirm,
  Typography,
  Form,
  Col,
  Row,
  InputNumber,
  Radio,
} from "antd";
import strings from "../../utils/Localization";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import IslemButton from "./IslemButton";
import FetchListContent from "../toolbox/FetchListContent";
import { useParams } from "react-router";
import useCurrency from "../../hooks/useCurrency";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import KasaVirmanDuzenleModal from "../kasaislem/KasaVirmanDuzenleModal";
import KasaIslemDuzenleModal from "../kasaislem/KasaIslemDuzenleModal";
import { api } from "../../helpers/ApiHelper";
import CariIslemDuzenleModal from "../cariIslem/CariIslemDuzenleModal";
import { cariIslemTurTypes } from "../../types/cariIslemTurTypes";
import { setRerender } from "../../redux/actions/rerenderAction";
import DatepickerRanger from "../toolbox/DatepickerRanger";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";

export default function KasaIslemListe() {
  const { kasaId } = useParams();

  const initialFilterRequestData = {
    Data: {
      KasaId: kasaId,
      GirisMi: null,
      VirmanMi: null,
    },
    DateOptions: [
      {
        DatePropertyName: "IslemTarihi",
        FirstDate: null,
        LastDate: null,
      },
    ],
    BetweenOptions: [
      {
        PropertyName: "Tutar",
        First: null,
        Last: null,
      },
    ],
  };
  const [filterRequestData, setFilterRequestData] = useState({
    ...initialFilterRequestData,
  });
  const [dataSource, setDataSource] = useState([]);
  const [virmaDuzenleVisible, setVirmaDuzenleVisible] = useState(false);
  const [virmanKasaIslemId, setVirmanKasaIslemId] = useState(null);
  const [duzenleModalVisible, setDuzenleModalVisible] = useState(false);
  const [cariIslemDuzenleModalVisible, setCariIslemDuzenleModalVisible] =
    useState(false);
  const [duzenleData, setDuzenleData] = useState({});
  const { paraFormat } = useCurrency();
  const [detailSearchForm] = Form.useForm();
  const { kasaIslemListe, detayliArama } = strings.kasaDetay.components;
  const { data: selectedKasa } = useSelector(
    (state) => state.selectedKasaReducer
  );
  const dispatch = useDispatch();

  const columns = [
    {
      title: kasaIslemListe.tarih,
      dataIndex: "islemTarihi",
      key: "islemTarihi",
      sorter: (a, b) => a.islemTarihi.length - b.islemTarihi.length,
      render: (text) => moment(text).format("DD/MM/YYYY"),
      defaultSortOrder: "descend",
    },
    {
      title: kasaIslemListe.evrakNo,
      dataIndex: "evrakNo",
      key: "evrakNo",
    },
    {
      title: kasaIslemListe.aciklama,
      dataIndex: "aciklama",
      key: "aciklama",
      render: (text, row, index) => {
        let virmanText = "";

        //Kasalar arası virman
        if (row.virmanMi === 1) {
          if (row.girisMi === true) {
            const { ad: kasaAd } =
              row.KasaislemHasKasaislemKasaIslemGiris[0].KasaIslemCikis.Kasa;

            virmanText = `${kasaAd} => ${selectedKasa.ad}`;
          } else {
            const { ad: kasaAd } =
              row.KasaislemHasKasaislemKasaIslemCikis[0].KasaIslemGiris.Kasa;

            virmanText = `${selectedKasa.ad} => ${kasaAd}`;
          }
        }
        // kasa banka arası virman
        if (row.virmanMi === 2) {
          const { ad: bankaHesapAd } =
            row.KasaislemHasBankahesapislem[0].BankaHesapIslem.BankaHesap;
          if (row.girisMi === true) {
            virmanText = `${bankaHesapAd} => ${selectedKasa.ad}`;
          } else {
            virmanText = `${selectedKasa.ad} => ${bankaHesapAd}`;
          }
        }
        return (
          <>
            {row.virmanMi > 0 && (
              <Tag color={"processing"} style={{ whiteSpace: "normal" }}>
                {kasaIslemListe.virmanIslemi} ( {virmanText})
              </Tag>
            )}
            {text}
          </>
        );
      },
    },
    {
      title: kasaIslemListe.unvan,
      dataIndex: "unvan",
      key: "unvan",
      render: (text, row, index) => {
        const { CariKart } = row.Cariislembaglanti;
        if (CariKart.id === 0) return "-";
        return (
          <Tag
            style={{ fontSize: 14, padding: 8, whiteSpace: "normal" }}
            color="default"
          >
            {CariKart.firmaAdi}
          </Tag>
        );
      },
    },
    {
      title: kasaIslemListe.tutar,
      dataIndex: "tutar",
      key: "tutar",
      render: (text, row, index) => (
        <Typography.Title
          level={5}
          style={{ padding: 8, color: row.girisMi ? "green" : "red" }}
        >{`${row.Kasa.Doviz.simge}${paraFormat(row.tutar)}`}</Typography.Title>
      ),
    },
    {
      title: kasaIslemListe.islemler,
      dataIndex: "islemler",
      key: "islemler",
      render: (text, row, index) => (
        <Space size="middle">
          <Button
            onClick={() => {
              if (row.virmanMi >= 1) {
                setVirmanKasaIslemId(row.id);
                setVirmaDuzenleVisible(true);
              } else {
                setDuzenleData(row);
                if (row.Cariislembaglanti.CariKart.id !== 0) {
                  setCariIslemDuzenleModalVisible(true);
                } else {
                  setDuzenleModalVisible(true);
                }
              }
            }}
            type="default"
            shape="circle"
            icon={<EditOutlined />}
            size={"large"}
          />
          <Popconfirm
            title={strings.mesaj.silmeIslemUyari}
            onConfirm={() => {
              if (row.Cariislembaglanti.cariIslemId !== 0) {
                removeCariIslem(row.Cariislembaglanti.cariIslemId);
              } else remove(row.id);
            }}
          >
            <Button
              type="default"
              shape="circle"
              icon={<DeleteOutlined />}
              size={"large"}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const remove = async (kasaIslemId) => {
    try {
      const response = await api.delete(`/kasaislem/${kasaIslemId}`, {});
      if (response.status === 200) {
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const removeCariIslem = async (cariIslemId) => {
    try {
      const response = await api.delete(`/cariislem/${cariIslemId}`);
      if (response.status === 200) {
        dispatch(setRerender());
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const detayliAramaOnFinish = async (props) => {
    const { islemTarihi, islemTipi, virmanTipi, minTutar, maxTutar } = props;

    let newData = { ...initialFilterRequestData };

    newData = {
      Data: {
        KasaId: kasaId,
        GirisMi: islemTipi !== undefined ? islemTipi : null,
        VirmanMi: virmanTipi !== undefined ? virmanTipi : null,
      },
      BetweenOptions: [
        {
          First: minTutar !== undefined ? minTutar : null,
          Last: maxTutar !== undefined ? maxTutar : null,
          PropertyName: "Tutar",
        },
      ],
      DateOptions: [
        {
          FirstDate:
            islemTarihi !== undefined
              ? `${moment(islemTarihi[0]).format("YYYY-MM-DD")} 00:00:00`
              : null,
          LastDate:
            islemTarihi !== undefined
              ? `${moment(islemTarihi[1]).format("YYYY-MM-DD")} 23:59:59`
              : null,
          DatePropertyName: "IslemTarihi",
        },
      ],
    };

    await setFilterRequestData(newData);
    dispatch(setRerender());
  };
  const detayliAramaReset = async () => {
    await setFilterRequestData({ ...initialFilterRequestData });
    detailSearchForm.resetFields();

    dispatch(setRerender());
  };
  return (
    <>
      <Card title={kasaIslemListe.baslik} extra={<IslemButton />}>
        <FetchListContent
          columns={columns}
          requestUrl="/kasaislem/get"
          requestBody={{
            SortOptions: {
              ColumnName: "IslemTarihi",
              SortType: "Desc",
            },
            SelectColumns: [
              {
                TableName: "",
                ColumnNames: [
                  "id",
                  "evrakNo",
                  "aciklama",
                  "virmanMi",
                  "girisMi",
                  "tutar",
                  "islemTarihi",
                ],
              },
              {
                TableName: "Kasa.Doviz",
                ColumnNames: ["id", "simge"],
              },
              {
                TableName: "Cariislembaglanti",
                ColumnNames: ["id", "cariIslemId"],
              },
              {
                TableName: "Cariislembaglanti.CariKart",
                ColumnNames: ["id", "firmaAdi"],
              },
              {
                TableName:
                  "KasaislemHasKasaislemKasaIslemCikis.KasaIslemGiris.Kasa",
                ColumnNames: ["id", "ad"],
              },
              {
                TableName:
                  "KasaislemHasKasaislemKasaIslemGiris.KasaIslemCikis.Kasa",
                ColumnNames: ["id", "ad"],
              },
              {
                TableName:
                  "KasaislemHasBankahesapislem.BankaHesapIslem.BankaHesap",
                ColumnNames: ["id", "ad"],
              },
            ],
            ...filterRequestData,
          }}
          dataSource={dataSource}
          onDataSource={(e) => setDataSource(e)}
          detailSearch={
            <Card title={detayliArama.baslik}>
              <Form
                form={detailSearchForm}
                name="detailSearchForm"
                layout="vertical"
                onFinish={detayliAramaOnFinish}
                initialValues={{
                  islemTipi: null,
                  virmanTipi: null,
                }}
              >
                <Row gutter={24}>
                  <Col span={10}>
                    <DatepickerRanger
                      formName="islemTarihi"
                      formLabel={detayliArama.islemTarihi}
                    />
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Form.Item
                        name="islemTipi"
                        label={detayliArama.islemTipi}
                      >
                        <Radio.Group>
                          <Space direction="vertical">
                            <Radio value={null}>
                              {detayliArama.tumIslemTipleriniGoster}
                            </Radio>
                            <Radio value={true}>
                              {detayliArama.girisIslemleriniGoster}
                            </Radio>
                            <Radio value={false}>
                              {detayliArama.cikisIslemleriniGoster}
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        name="virmanTipi"
                        label={detayliArama.virmanTipi}
                      >
                        <Radio.Group>
                          <Space direction="vertical">
                            <Radio value={null}>
                              {detayliArama.tumVirmanTipleriniGoster}
                            </Radio>
                            <Radio value={1}>
                              {detayliArama.kasayaVirmanIslemleriniGoster}
                            </Radio>
                            <Radio value={2}>
                              {detayliArama.bankayaVirmanIslemleriniGoster}
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Space direction="vertical" align="center">
                      <Form.Item label={detayliArama.minTutar} name="minTutar">
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                      <Form.Item label={detayliArama.maxTutar} name="maxTutar">
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
                <Space align="baseline" style={{ float: "right" }}>
                  <Button
                    type="default"
                    size="middle"
                    icon={<ClearOutlined />}
                    style={{ float: "right" }}
                    onClick={detayliAramaReset}
                  >
                    {detayliArama.temizle}
                  </Button>
                  <Button
                    type="default"
                    size="middle"
                    icon={<SearchOutlined />}
                    style={{ float: "right" }}
                    form="detailSearchForm"
                    htmlType="submit"
                  >
                    {detayliArama.aramayiBaslat}
                  </Button>
                </Space>
              </Form>
            </Card>
          }
        />
      </Card>
      <KasaVirmanDuzenleModal
        visible={virmaDuzenleVisible}
        handleCancel={() => setVirmaDuzenleVisible(false)}
        kasaIslemId={virmanKasaIslemId}
      />
      <KasaIslemDuzenleModal
        visible={duzenleModalVisible}
        handleCancel={() => setDuzenleModalVisible(false)}
        data={duzenleData}
      />
      <CariIslemDuzenleModal
        visible={cariIslemDuzenleModalVisible}
        handleCancel={() => setCariIslemDuzenleModalVisible(false)}
        data={
          duzenleData.id === undefined
            ? {}
            : {
                id: duzenleData.Cariislembaglanti.CariKart.id,
                vadeTarih: duzenleData.islemTarihi,
                islemTarih: duzenleData.islemTarihi,
                tutar: duzenleData.tutar,
                doviz: { id: duzenleData.Kasa.Doviz.id },
                evrakNo: duzenleData.evrakNo,
                aciklama: duzenleData.aciklama,
                cariIslemTur:
                  duzenleData.girisMi === true
                    ? cariIslemTurTypes.TAHSILAT
                    : cariIslemTurTypes.ODEME,
              }
        }
      />
    </>
  );
}
