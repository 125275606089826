import React, { useEffect, useState } from "react";
import Avatar from "../toolbox/Avatar";
import { Card, Divider, message, Space, Typography } from "antd";
import { api } from "../../helpers/ApiHelper";
import { useParams } from "react-router-dom";
import strings from "../../utils/Localization";
import { useSelector } from "react-redux";
const { Title } = Typography;

const gridStyle = {
  width: "50%",
  textAlign: "center",
  padding: 12,
};

const MyDivider = () => <Divider style={{ marginTop: 12, marginBottom: 12 }} />;

export default function Profil() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [stokData, setStokData] = useState({});
  const [stokBakiye, setStokBakiye] = useState({
    giren: 0,
    cikan: 0,
    bakiye: 0,
  });
  const { stokId } = useParams();
  const { cikis, giris, kalan } = strings.stokDetay.profil;
  const rerenderReducer = useSelector(state => state.rerenderReducer)

  useEffect(() => {
    fetchStok();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchStokBakiye();
  }, [rerenderReducer]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStok = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/stok/get", {
        Includes: ["Stokvaryantgrup", "Stokvaryantgrup.Stokvaryantgrupfiyat"],
        Data: {
          Id: stokId,
        },
      });
      if (response.status === 200) {
        setStokData(response.data.data[0]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const fetchStokBakiye = async () => {
    try {
      const response = await api.post(`/stok/getsonbakiye`, {
        stokId: stokId,
      });
      if (response.status === 200) {
        setStokBakiye(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  return (
    <>
      <Card
        bodyStyle={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        loading={fetchLoading}
      >
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <Avatar text={stokData.ad} />
          <Title level={4} style={{ marginTop: 8, marginBottom: 4 }}>
            {stokData.ad}
          </Title>
        </Space>
        <MyDivider />
        <Card>
          <Card.Grid style={gridStyle}>{giris}</Card.Grid>
          <Card.Grid style={gridStyle}>{stokBakiye.giren}</Card.Grid>
          <Card.Grid style={gridStyle}>{cikis}</Card.Grid>
          <Card.Grid style={gridStyle}>{stokBakiye.cikan}</Card.Grid>
          <Card.Grid style={gridStyle}>{kalan}</Card.Grid>
          <Card.Grid style={gridStyle}>{stokBakiye.bakiye}</Card.Grid>
        </Card>
      </Card>
    </>
  );
}
