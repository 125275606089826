import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import strings from '../../utils/Localization'

const buttonStyle = { marginTop: 16 }

export default function SifremiUnuttumButon() {
    const history = useHistory()

    return (
        <Button
            type="text"
            size="middle"
            block
            style={buttonStyle}
            onClick={() => history.push('/SifremiUnuttumMailDogrulama')}
        >{strings.girisYap.components.sifremiUnuttumButon.butonYazi}
        </Button>
    )
}
