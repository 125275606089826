import React, { useState } from "react";
import { Button, Card, Form, message } from "antd";
import DatepickerRanger from "../toolbox/DatepickerRanger";
import strings from "../../utils/Localization";
import BankaHesapSelectOption from "../toolbox/BankaHesapSelectOption";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import moment from "moment";
import { api } from "../../helpers/ApiHelper";
import { useForm } from "antd/lib/form/Form";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";

export default function BankaRaporForm() {
  const [form] = useForm();
  const [fetchLoading, setFetchLoading] = useState(false);
  const { bankaRaporForm } = strings.bankaRapor.components;
  const { getDefaultSetting } = useVarsayilanDeger();

  const onFinish = async (values) => {
    try {
      const { tarih, bankaId, bankaHesapId, dovizId } = values;

      const baslangicTarihi = moment(tarih[0]).format("YYYY-MM-DD");
      const bitisTarihi = moment(tarih[1]).format("YYYY-MM-DD");

      setFetchLoading(true);
      const response = await api.post(
        "/rapordosya/banka",
        {
          BaslangicTarih: `${baslangicTarihi} 00:00:00`,
          BitisTarih: `${bitisTarihi} 23:59:59`,
          BankaId: bankaId === undefined ? 0 : bankaId,
          BankaHesapId: bankaHesapId === undefined ? 0 : bankaHesapId,
          DovizId: dovizId === undefined ? 0 : dovizId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return window.open(response.data, "_blank");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Card>
      <Form
        layout="vertical"
        size="middle"
        onFinish={onFinish}
        form={form}
        initialValues={{
          tarih: [moment().add("months", -12), moment()],
          dovizId: getDefaultSetting("doviz"),
        }}
      >
        <DatepickerRanger
          required
          formName="tarih"
          formLabel={bankaRaporForm.tarihSeciniz}
        />
        <DovizSelectOption formItemName="dovizId" allowClear />
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.dovizId !== currentValues.dovizId
          }
        >
          {({ getFieldValue }) => {
            form.setFieldsValue({ bankaHesapId: undefined });
            const currentDovizId = getFieldValue("dovizId");

            return (
              <BankaHesapSelectOption
                formItemName="bankaHesapId"
                formItemLabel={bankaRaporForm.bankaHesapSeciniz}
                requestBody={{
                  Data: {
                    dovizId: currentDovizId,
                  },
                }}
                disabled={currentDovizId === undefined ? true : false}
                allowClear
              />
            );
          }}
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ float: "right" }}
          size="large"
          loading={fetchLoading}
        >
          {bankaRaporForm.raporAlButon}
        </Button>
      </Form>
    </Card>
  );
}
