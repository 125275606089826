import React from "react";
import { PageHeader as MyPageHeader } from "antd";
import { useHistory } from "react-router-dom";
import strings from "../../utils/Localization";
import IslemButon from "./IslemButon";

export default function PageHeader() {
  const history = useHistory();

  return (
    <MyPageHeader
      ghost={false}
      onBack={() => history.goBack()}
      title={strings.faturaListe.components.pageHeader.baslik}
      subTitle={strings.faturaListe.components.pageHeader.altBaslik}
      extra={<IslemButon />}
    />
  );
}
