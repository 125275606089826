import React from "react";
import { Result, Button } from "antd";
import strings from "../utils/Localization";
import { useHistory } from "react-router-dom";

function Page404() {
  const history = useHistory();
  const { geriDonButton, mesajBaslik } = strings.page404;
  return (
    <Result
      status="404"
      title="404"
      subTitle={mesajBaslik}
      extra={
        <Button type="primary" onClick={() => history.goBack()}>
          {geriDonButton}
        </Button>
      }
    />
  );
}

export default Page404;
