import React, { useEffect, useState } from "react";
import { api } from "../../helpers/ApiHelper";
import { message, Select, Form } from "antd";
import strings from "../../utils/Localization";

const { Option } = Select;

function StokKategoriSelectOption({ formItemName, disabled, allowClear }) {
  const [stokKategoriData, setStokKategoriData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { stokKategoriPlaceHolder, stokKategoriBaslik } =
    strings.stokKategoriSelectOption;

  useEffect(() => {
    fetchStokKategori();
  }, []);

  const fetchStokKategori = async () => {
    try {
      setFetchLoading(true);

      const response = await api.post("/stokkategori/get", {
        SortOptions: {
          ColumnName: "Id",
          SortType: "Asc",
        },
      });
      if (response.status === 200) {
        setStokKategoriData(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Form.Item
      name={formItemName}
      label={stokKategoriBaslik}
      rules={[{ required: true }]}
    >
      <Select
        placeholder={stokKategoriPlaceHolder}
        allowClear={allowClear}
        loading={fetchLoading}
        disabled={disabled}
      >
        {stokKategoriData.map((item, index) => (
          <Option key={index} value={String(item.id)}>
            {item.ad}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default StokKategoriSelectOption;
