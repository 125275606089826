import React from "react";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";
import strings from "../../../utils/Localization";
import { useHistory } from "react-router-dom";

const MyButton = styled.span`
  cursor: pointer;
`;

export default function KullaniciAyarlari() {
  const history = useHistory();

  const cikisYap = () => {
    localStorage.removeItem("eMail");
    localStorage.removeItem("token");
    history.push("/GirisYap");
  };

  const kullaniciMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => history.push("/FirmaBilgileri")}>
        {strings.navBar.kullaniciAyarSecim.firmaBilgileri}
      </Menu.Item>
      <Menu.Item key="1" onClick={() => history.push("/SifremiDegistir")}>
        {strings.navBar.kullaniciAyarSecim.sifremiDegistir}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => history.push("/VarsayilanAyarlar")}>
        {strings.navBar.kullaniciAyarSecim.varsayilanAyarlar}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={() => cikisYap()}>
        {strings.navBar.kullaniciAyarSecim.cikisYap}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={kullaniciMenu} trigger={["click"]}>
      <MyButton type="text" onClick={(e) => e.preventDefault()}>
        {localStorage.getItem("eMail")} <DownOutlined />
      </MyButton>
    </Dropdown>
  );
}
