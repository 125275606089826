import React from "react";
import LinesEllipsis from "react-lines-ellipsis";

export default function CursorTableItem({ text, onClick, hideHand }) {
  return (
    <div
      style={{
        fontSize: 17,
        height: 40,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        cursor: hideHand ? "none" : "pointer",
      }}
      onClick={onClick}
    >
      <strong style={{ fontWeight: "600" }}>
        {
          <LinesEllipsis
            text={text}
            maxLine="3"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        }
      </strong>
    </div>
  );
}
