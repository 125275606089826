import React, { useState } from "react";
import { Button, Space, Card, Popconfirm, notification, message } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import CursorTableItem from "../toolbox/CursorTableItem";
import strings from "../../utils/Localization";
import FetchListContent from "../toolbox/FetchListContent";
import { api } from "../../helpers/ApiHelper";
import DepoEkleModal from "../depo/DepoEkleModal";
import DepoDuzenleModal from "../depo/DepoDuzenleModal";

export default function StokDepoListe() {
  const [dataSource, setDataSource] = useState({});
  const [depoEkleModalVisible, setDepoEkleModalVisible] = useState(false);
  const [depoDuzenleModalVisible, setDepoDuzenleModalVisible] = useState(false);
  const [duzenleData, setDuzenleData] = useState({});
  const { stokDepoListe } = strings.stokDepoListe.components;

  const columns = [
    {
      title: stokDepoListe.depoAd,
      dataIndex: "ad",
      key: "ad",
      render: (text, row, index) => (
        <CursorTableItem hideHand text={`${text} (${row.stokIslemSayisi})`} />
      ),
    },
    {
      title: stokDepoListe.islemler,
      dataIndex: "islemler",
      key: "islemler",
      render: (text, row, index) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setDuzenleData(row);
              setDepoDuzenleModalVisible(true);
            }}
            type="default"
            shape="circle"
            icon={<EditOutlined />}
            size={"large"}
          />
          <Popconfirm
            title={strings.mesaj.silmeIslemUyari}
            onConfirm={() => {
              if (row.stokIslemSayisi > 0) {
                message.error({
                  content: strings.mesaj.stokDepoSilme,
                  style: { marginTop: "20vh" },
                });
              } else remove(row.id);
            }}
          >
            <Button
              type="default"
              shape="circle"
              icon={<DeleteOutlined />}
              size={"large"}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const remove = async (stokDepoId) => {
    try {
      const response = await api.delete(`/stokdepo/${stokDepoId}`, {});
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== stokDepoId),
        });
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      <Card
        title={stokDepoListe.baslik}
        extra={[
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => setDepoEkleModalVisible(true)}
          >
            {stokDepoListe.depoEkleButon}
          </Button>,
        ]}
      >
        <FetchListContent
          columns={columns}
          requestUrl="/stokdepo/get"
          onDataSource={(e) => setDataSource(e)}
          dataSource={dataSource}
          requestBody={{
            SelectColumns: [
              {
                TableName: "",
                ColumnNames: ["ad", "id"],
              },
              {
                TableName: "Stokislem:stokIslemSayisi",
                Operator: "Count",
              },
            ],
          }}
        />
      </Card>
      <DepoEkleModal
        visible={depoEkleModalVisible}
        handleCancel={() => setDepoEkleModalVisible(false)}
      />
      <DepoDuzenleModal
        visible={depoDuzenleModalVisible}
        handleCancel={() => setDepoDuzenleModalVisible(false)}
        data={duzenleData}
      />
    </>
  );
}
