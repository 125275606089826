import React, { useEffect, useState } from "react";
import { Form, message, Select, Tag } from "antd";
import strings from "../../utils/Localization";
import { api } from "../../helpers/ApiHelper";

const { Option } = Select;

function StokVaryantSelectOption({
  formItemName,
  formItemLabel,
  required,
  placeholder,
  hidden,
  rules,
  onSelectedVaryant = () => {},
  disabled,
  requestBody = {},
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [varyantData, setVaryantData] = useState([]);
  useEffect(() => {
    fetchStokVaryant();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStokVaryant = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/stokvaryant/get", {
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["Id", "Ad"],
          },
          {
            TableName: "Stokvaryantdeger",
            ColumnNames: ["Ad", "Id", "StokVaryantId"],
          },
        ],
        ...requestBody,
      });
      if (response.status === 200) {
        let data = [];
        response.data.data.map((varyant, index) => {
          return data.push({
            index,
            label: varyant.Ad,
            value: varyant.Id,
            child: varyant.Stokvaryantdeger.map((varyantDeger) => ({
              label: varyantDeger.Ad,
              value: {
                Ad: varyantDeger.Ad,
                Id: varyantDeger.Id,
                StokVaryantId: varyant.Id,
              },
            })),
          });
        });
        setVaryantData(data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Form.Item
      name={formItemName}
      label={formItemLabel}
      rules={rules ? rules : [{ required: required }]}
      hidden={hidden}
    >
      <Select
        placeholder={placeholder || strings.stokVaryantSelectOption.placeHolder}
        loading={fetchLoading}
        onChange={(e) =>
          onSelectedVaryant(varyantData.find((x) => x.id === parseInt(e)))
        }
        disabled={disabled}
        mode="multiple"
      >
        {varyantData.map((item, index) =>
          item.child.map((child) => (
            <Option key={child.value} value={JSON.stringify(child.value)}>
              <Tag> {item.label}</Tag> {child.label}
            </Option>
          ))
        )}
      </Select>
    </Form.Item>
  );
}

export default StokVaryantSelectOption;
