import React, { useState, useEffect } from "react";
import {
  Card,
  PageHeader,
  Row,
  Col,
  Typography,
  Space,
  message,
  Button,
} from "antd";
import {
  FileTextOutlined,
  DropboxOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
  NumberOutlined,
  DashboardOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  SwapOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import useCurrency from "../hooks/useCurrency";
import { api } from "../helpers/ApiHelper";
import moment from "moment";

const { Title } = Typography;

function StokIslemDetay() {
  const [stokIslemData, setStokIslemData] = useState({});
  const [loading, setLoading] = useState(true);
  const { stokIslemId = null } = useParams();
  const history = useHistory();
  const { paraFormat } = useCurrency();

  useEffect(() => {
    getStokIslem();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getStokIslem = async () => {
    try {
      const response = await api.post("/stokislem/getstokislemlist", {
        Data: {
          Stokislem: {
            Id: stokIslemId,
          },
        },
        SelectColumns: [
          {
            ColumnNames: [
              "id",
              "stokVaryantGrupId",
              "miktar",
              "aciklama",
              "girisMi",
              "islemTarihi",
              "evrakNo",
              "stokDepoId",
              "stokDepoId",
              "faturaSatirId",
              "birimFiyat"
            ],
            TableName: "",
          },
          {
            ColumnNames: ["id", "ad"],
            TableName: "StokVaryantGrup.Stok",
          },
          {
            ColumnNames: ["id", "ad"],
            TableName: "StokVaryantGrup.Stok.StokBirim",
          },
          {
            ColumnNames: ["id", "ad"],
            TableName:
              "StokVaryantGrup.StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger",
          },
          {
            TableName: "StokDepo",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName: "Doviz",
            ColumnNames: ["id", "ad", "simge", "kod"],
          },
          {
            TableName: "FaturaSatir",
            ColumnNames: ["faturaId"],
          },
        ],
      });
      if (response.status === 200) {
        setStokIslemData(response.data.data[0]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Card loading={true} />;
  }
  
  return (
    <>
      <PageHeader
        title="Stok İşlem"
        onBack={() => history.goBack()}
        style={{ paddingLeft: 0 }}
      />
      <Card
        title={
          <Title level={4}>
            <FileTextOutlined />
            {`${stokIslemData.girisMi ? " Giriş" : " Çıkış"}`}
          </Title>
        }
        extra={
          <>
            {stokIslemData.faturaSatirId !== null && (
              <Button
                onClick={() =>
                  history.push(
                    `/FaturaDuzenle/${stokIslemData.FaturaSatir.faturaId}`
                  )
                }
                type="primary"
                size="large"
                icon={<FileTextOutlined />}
              >
                Faturaya Git
              </Button>
            )}
          </>
        }
      >
        <>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <DropboxOutlined /> Hizmet/Ürün Adı
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>{stokIslemData.StokVaryantGrup.Stok.ad}</Title>
              </Col>
            </Row>
            {stokIslemData.StokVaryantGrup.StokvaryantgrupHasStokvaryantdeger
              .length > 0 && (
              <Row gutter={24}>
                <Col span={8}>
                  <Title level={5} type="secondary">
                    <UnorderedListOutlined /> Varyant Adı
                  </Title>
                </Col>
                <Col span={16}>
                  <Title level={5}>
                    {stokIslemData.StokVaryantGrup.StokvaryantgrupHasStokvaryantdeger.map(
                      (item) => item.StokVaryantDeger.ad
                    ).join("-")}
                  </Title>
                </Col>
              </Row>
            )}

            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <CalendarOutlined /> İşlem Tarihi
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>
                  {moment(stokIslemData.islemTarihi).format("DD.MM.YYYY")}
                </Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <NumberOutlined /> Evrak No
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>
                  {stokIslemData.evrakNo === null ? "-" : stokIslemData.evrakNo}
                </Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <InboxOutlined /> İşlendiği Depo
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>{stokIslemData.StokDepo.ad}</Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <SwapOutlined /> Döviz Türü
                </Title>
              </Col>
              <Col span={16}>
                <Title
                  level={5}
                >{`${stokIslemData.Doviz.ad} ${stokIslemData.Doviz.simge}`}</Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <DollarCircleOutlined /> Birim Fiyat
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>{` ${stokIslemData.Doviz.simge}${paraFormat(
                  stokIslemData.birimFiyat
                )}`}</Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <DashboardOutlined /> Miktar
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>{stokIslemData.miktar}</Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <InfoCircleOutlined /> Açıklama
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>
                  {stokIslemData.aciklama === "" ? "-" : stokIslemData.aciklama}
                </Title>
              </Col>
            </Row>
          </Space>
        </>
      </Card>
    </>
  );
}

export default StokIslemDetay;
