import React from 'react'
import { PageHeader, FirmaBilgileriForm } from '../components/firmaBilgileri'
import { Col, Space } from 'antd'

export default function FirmaBilgileri() {
    return (
        <>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <PageHeader />
                <Col xxl={18} xl={18} lg={24} md={24} sm={24} xs={24}>
                    <FirmaBilgileriForm />
                </Col>
            </Space>
        </>
    )
}
