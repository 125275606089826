import React from 'react'
import { Col, Layout, Row } from 'antd'

const rowStyle = { minHeight: '100vh', paddingTop: 80 }

export default function Container({ children }) {
    return (
        <Layout>
            <Row
                type="flex"
                justify="center"
                align="top"
                style={rowStyle}
            >
                <Col>
                    {children}
                </Col>
            </Row>
        </Layout>
    )
}
