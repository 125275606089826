import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  message,
  notification,
  Select,
  Row,
  Col,
  InputNumber,
} from "antd";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import Datepicker from "../toolbox/DatePicker";
import DepoSelectOption from "../toolbox/DepoSelectOption";
import StokVaryantGrupSelectOption from "../toolbox/StokVaryantGrupSelectOption";
import CurrencyInput from "../toolbox/CurrencyInput";
import { api } from "../../helpers/ApiHelper";
import { useParams } from "react-router-dom";
import strings from "../../utils/Localization";
import useEvrakNo from "../../hooks/useEvrakNo";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";
import moment from "moment";
import DovizSelectOption from "../toolbox/DovizSelectOption";

const { Option } = Select;

const formSize = "middle";

function VaryantliStokIslemEkleModal({
  visible = false,
  handleCancel,
  girisMi = null,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [stokBirimId, setStokBirimId] = useState(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { stokId } = useParams();
  const { stokIslemEkle: localization } = strings.varyantliStokIslem.components;
  const { getStokIslemEvrakNo } = useEvrakNo();
  const { getDefaultSetting } = useVarsayilanDeger();

  useEffect(() => {
    if (girisMi !== null) {
      form.setFieldsValue({ girisMi: girisMi });
    }
    getStokIslemEvrakNo().then((evrakNo) => {
      form.setFieldsValue({ evrakNo: evrakNo });
    });
    form.setFieldsValue({
      stokVaryantGrupId: undefined,
      birimFiyat: 0,
    });
  }, [girisMi]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getStok();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeVaryantGrup = (e) => {
    const girisMi = form.getFieldValue("girisMi");
    const alis = e.stokvaryantgrupfiyat[0];
    const satis = e.stokvaryantgrupfiyat[1];

    if (girisMi === true) {
      form.setFieldsValue({ birimFiyat: alis.genelToplam });
    } else if (girisMi === false) {
      form.setFieldsValue({ birimFiyat: satis.genelToplam });
    }
  };

  const getStok = () => {
    api
      .post("/stok/get", {
        Data: {
          Id: stokId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["stokBirimId"],
          },
        ],
      })
      .then((res) => {
        if (res.status === 200) {
          setStokBirimId(res.data.data[0].stokBirimId);
        }
      })
      .catch((err) => message.error(err.message));
  };

  const onFinish = async (values) => {
    try {
      const {
        tarih,
        girisMi,
        evrakNo,
        depoId,
        miktar,
        aciklama,
        stokVaryantGrupId,
        birimFiyat,
        dovizId,
      } = values;
      setFetchLoading(true);
      const response = await api.post("/stokislem", {
        StokVaryantGrupId: stokVaryantGrupId,
        StokDepoId: depoId,
        evrakNo: evrakNo,
        Miktar: miktar,
        GirisMi: girisMi,
        Aciklama: aciklama,
        IslemTarihi: tarih,
        DovizId: dovizId,
        StokBirimId: stokBirimId,
        BirimFiyat: birimFiyat,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        getStokIslemEvrakNo().then((evrakNo) => {
          form.setFieldsValue({
            evrakNo: evrakNo,
          });
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "stokIslemEkleModal",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      centered
      width="50%"
    >
      <Form
        name="stokIslemEkleModal"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size={formSize}
        initialValues={{
          girisMi: girisMi,
          tarih: moment(),
          depoId: getDefaultSetting("stokDepo"),
          dovizId: getDefaultSetting("doviz"),
        }}
      >
        <Row gutter={12}>
          <Col flex={1}>
            <Form.Item
              name="tarih"
              label={localization.tarih}
              rules={[{ required: true }]}
            >
              <Datepicker />
            </Form.Item>
            <StokVaryantGrupSelectOption
              stokId={stokId}
              formItemLabel="Varyant Grup Seçiniz"
              formItemName="stokVaryantGrupId"
              required
              onChange={onChangeVaryantGrup}
            />
            <Form.Item
              name="girisMi"
              label={localization.islemTipi}
              rules={[{ required: true }]}
            >
              <Select disabled>
                <Option value={true} style={{ color: "green" }}>
                  {localization.islemTipiSelect.giris}
                </Option>
                <Option value={false} style={{ color: "red" }}>
                  {localization.islemTipiSelect.cikis}
                </Option>
              </Select>
            </Form.Item>
            <DepoSelectOption
              formItemLabel={localization.depo}
              formItemName="depoId"
            />
          </Col>
          <Col flex={1}>
            <Form.Item name="evrakNo" label={localization.evrakNo}>
              <Input />
            </Form.Item>
            <Form.Item name="aciklama" label={localization.aciklama}>
              <Input.TextArea rows={5} />
            </Form.Item>
            <Row>
              <Form.Item
                name="birimFiyat"
                label="Birim Fiyat"
                style={{ flex: 1, marginRight: 8 }}
                rules={[{ required: true }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
              <DovizSelectOption formItemName="dovizId" showKod required />
            </Row>
            <CurrencyInput
              required
              formItemLabel={localization.miktar}
              formItemName="miktar"
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default VaryantliStokIslemEkleModal;
