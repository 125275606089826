import { Select, Form, message } from "antd";
import React, { useState } from "react";
import { api } from "../../helpers/ApiHelper";
import strings from "../../utils/Localization";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

function CariKartSelectOption({
  formItemName,
  formItemLabel,
  required,
  allowClear,
  placeholder,
  onChange = () => {},
  hidden,
}) {
  const [cariKartData, setCariKartData] = useState([]);
  const { cariBaslik, cariPlaceHolder } = strings.cariKartSelectOption;

  const fetchCariKart = async (searchText) => {
    try {
      const response = await api.post("/carikart/get", {
        SearchText: {
          PropertyNames: null,
          Value: searchText,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: [
              "id",
              "firmaAdi",
              "yetkili",
              "vergIdairesi",
              "vergiNumarasi",
              "telefon",
              "cepTelefonu",
              "eMail",
              "il",
              "ilce",
              "adres",
              "sevkAdresi",
            ],
          },
        ],
        PagingOptions: {
          PageSize: 10,
          PageNumber: 1,
        },
      });
      if (response.status === 200) {
        let data = [];
        response.data.data.map((item) =>
          data.push({
            text: item.firmaAdi,
            value: item.id,
            data: item,
          })
        );
        setCariKartData(data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const onSearch = (searchText = "") => {
    if (searchText.length === 0) {
      setCariKartData([]);
    } else {
      fetchCariKart(searchText);
    }
  };

  return (
    <Form.Item
      name={formItemName}
      label={formItemLabel || cariBaslik}
      rules={[{ required: required, message: strings.mesaj.gerekliBirAlan }]}
      hidden={hidden}
    >
      <Select
        suffixIcon={<SearchOutlined size={24} />}
        showSearch
        onSearch={onSearch}
        notFoundContent={null}
        defaultActiveFirstOption={false}
        showArrow={true}
        filterOption={false}
        placeholder={placeholder || cariPlaceHolder}
        allowClear={allowClear}
        onChange={(e) =>
          onChange(cariKartData.find((x) => x.value === parseInt(e)).data)
        }
      >
        {cariKartData.map((d) => (
          <Option key={d.value}>{d.text}</Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default CariKartSelectOption;
