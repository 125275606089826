import React, { useState } from "react";
import { Button, Form, Divider, Input, Typography, message } from "antd";
import strings from "../utils/Localization";
import { useHistory } from "react-router-dom";
import { Layout } from "../components/sifremiUnuttum";
import { api } from "../helpers/ApiHelper";
const { Paragraph } = Typography;

const paragraphStyle = { textAlign: "center", letterSpacing: 1 };

export default function SifremiUnuttumMailDogrulama() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    slogan,
    eMailPlaceHolder,
    sifremiUnuttumButon,
    eMail,
    onayKoduGonderildi,
  } = strings.sifremiUnuttumMailDogrulama;

  const onFinish = async (values) => {
    try {
      const { eMail } = values;
      setFetchLoading(true);
      const response = await api.post("/auth/sifreunuttum", {
        Email: eMail,
      });
      if (response.status === 200) {
        message.success(onayKoduGonderildi);
        history.push({
          pathname: "/SifremiUnuttumKodDogrulama",
          state: { eMail: eMail },
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Layout>
      <Paragraph style={paragraphStyle}>{slogan}</Paragraph>
      <Divider />
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="basic"
        onFinish={onFinish}
      >
        <Form.Item
          label={eMail}
          name="eMail"
          rules={[
            {
              required: true,
              type: "email",
            },
          ]}
        >
          <Input placeholder={eMailPlaceHolder} autoFocus />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={fetchLoading}
          >
            {sifremiUnuttumButon}
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}
