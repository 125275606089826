import {
  Button,
  Card,
  Col,
  Form,
  Input,
  PageHeader,
  Divider,
  message,
  notification,
  Row,
} from "antd";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import DovizSelectOption from "../components/toolbox/DovizSelectOption";
import strings from "../utils/Localization";
import { yuzdeHesala } from "../helpers/HesaplamaHelper";
import { api } from "../helpers/ApiHelper";
import CurrencyInput from "../components/toolbox/CurrencyInput";

function StokVaryantGrupDuzenle() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const { baslik, stokBilgileri, stokAlisFiyati, stokSatisFiyati, guncelle } =
    strings.stokVaryantGrupDuzenle;
  const { stokVaryantGrupId } = useParams();

  useEffect(() => {
    fetchStok();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStok = async () => {
    try {
      setLoading(true);
      const response = await api.post("/stokvaryantgrup/get", {
        Data: {
          id: stokVaryantGrupId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["id", "barkod", "gtin", "kod", "raf", "aciklama"],
          },
          {
            TableName: "StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName:
              "StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger.StokVaryant",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName: "Stokvaryantgrupfiyat",
            ColumnNames: [
              "id",
              "fiyat",
              "otvTutar",
              "otvOran",
              "oivTutar",
              "oivOran",
              "kdvTutar",
              "kdvOran",
              "genelToplam",
              "satisMi",
            ],
          },
          {
            TableName: "Stokvaryantgrupfiyat.Doviz",
            ColumnNames: ["id", "ad", "kod", "simge"],
          },
        ],
      });
      if (response.status === 200) {
        const {
          barkod,
          gtin,
          kod,
          raf,
          aciklama,
          Stokvaryantgrupfiyat,
          // StokvaryantgrupHasStokvaryantdeger,
        } = response.data.data[0];

        const {
          fiyat: satisFiyat,
          kdvOran: satisKdvOran,
          otvOran: satisOtvOran,
          genelToplam: satisGenelToplam,
          Doviz: { id: satisDovizId },
        } = Stokvaryantgrupfiyat[0];
        const {
          fiyat: alisFiyat,
          kdvOran: alisKdvOran,
          otvOran: alisOtvOran,
          genelToplam: alisGenelToplam,
          Doviz: { id: alisDovizId },
        } = Stokvaryantgrupfiyat[1];

        form.setFields([
          {
            name: "barkodNo",
            value: barkod,
          },
          {
            name: "stokRafi",
            value: raf,
          },
          {
            name: "stokKodu",
            value: kod,
          },
          {
            name: "aciklama",
            value: aciklama,
          },
          {
            name: "gtinNumarasi",
            value: gtin,
          },
          {
            name: "vergilerHaricAlisFiyat",
            value: alisFiyat,
          },
          {
            name: "alisDovizId",
            value: String(alisDovizId),
          },
          {
            name: "alisKdvOran",
            value: alisKdvOran,
          },
          {
            name: "alisOtvOran",
            value: alisOtvOran,
          },
          {
            name: "vergilerDahilAlisFiyat",
            value: alisGenelToplam,
          },
          {
            name: "vergilerHaricSatisFiyat",
            value: satisFiyat,
          },
          {
            name: "satisDovizId",
            value: String(satisDovizId),
          },
          {
            name: "satisKdvOran",
            value: satisKdvOran,
          },
          {
            name: "satisOtvOran",
            value: satisOtvOran,
          },
          {
            name: "vergilerDahilSatisFiyat",
            value: satisGenelToplam,
          },
        ]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      const {
        barkodNo,
        stokRafi,
        stokKodu,
        gtinNumarasi,
        aciklama,
        vergilerHaricAlisFiyat,
        alisDovizId,
        alisKdvOran,
        alisOtvOran,
        vergilerDahilAlisFiyat,
        vergilerHaricSatisFiyat,
        satisDovizId,
        satisKdvOran,
        satisOtvOran,
        vergilerDahilSatisFiyat,
      } = values;

      //Satış KDV - OTV tutar
      const satisOtvTutar = yuzdeHesala(satisOtvOran, vergilerHaricSatisFiyat);
      const satisOtvliTutar =
        parseFloat(vergilerHaricSatisFiyat) + satisOtvTutar;
      const satisKdvTutar = yuzdeHesala(satisOtvliTutar, satisKdvOran);

      //Alış KDV - OTV tutar
      const alisOtvTutar = yuzdeHesala(alisOtvOran, vergilerHaricAlisFiyat);
      const alisOtvliTutar = parseFloat(vergilerHaricAlisFiyat) + alisOtvTutar;
      const alisKdvTutar = yuzdeHesala(alisOtvliTutar, alisKdvOran);
      setFetchLoading(true);

      const response = await api.put("/stokvaryantgrup/update", {
        Id: stokVaryantGrupId,
        Aciklama: aciklama,
        Barkod: barkodNo,
        Gtin: gtinNumarasi,
        Kod: stokKodu,
        Raf: stokRafi,
        ResimUrl: "",
        Stokvaryantgrupfiyat: [
          {
            DovizId: satisDovizId,
            Fiyat: vergilerHaricSatisFiyat,
            GenelToplam: vergilerDahilSatisFiyat,
            KdvOran: satisKdvOran,
            KdvTutar: satisKdvTutar,
            // OivOran: stokFiyat.satis.oivOran,
            // OivTutar: stokFiyat.satis.oivTutar,
            OtvOran: satisOtvOran,
            OtvTutar: satisOtvTutar,
            SatisMi: true,
          },
          {
            DovizId: alisDovizId,
            Fiyat: vergilerHaricAlisFiyat,
            GenelToplam: vergilerDahilAlisFiyat,
            KdvOran: alisKdvOran,
            KdvTutar: alisKdvTutar,
            // OivOran: stokFiyat.alis.oivOran,
            // OivTutar: stokFiyat.alis.oivTutar,
            OtvOran: alisOtvOran,
            OtvTutar: alisOtvTutar,
            SatisMi: false,
          },
        ],
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const alisFiyatHesaplama = (item) => {
    const {
      vergilerHaricAlisFiyat,
      alisKdvOran,
      alisOtvOran,
      vergilerDahilAlisFiyat,
    } = form.getFieldsValue([
      "vergilerHaricAlisFiyat",
      "alisKdvOran",
      "alisOtvOran",
      "vergilerDahilAlisFiyat",
    ]);
    const otvTutar = yuzdeHesala(alisOtvOran, vergilerHaricAlisFiyat);
    const otvliTutar = parseFloat(vergilerHaricAlisFiyat) + otvTutar;
    const kdvTutar = yuzdeHesala(otvliTutar, alisKdvOran);
    if (item.target.id === "vergilerHaricAlisFiyat") {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        { name: "vergilerDahilAlisFiyat", value: vergiDahilFiyat },
      ]);
    } else if (item.target.id === "vergilerDahilAlisFiyat") {
      const vergiHaricFiyat = parseFloat(
        vergilerDahilAlisFiyat /
          (alisKdvOran / 100 + 1) /
          (alisOtvOran / 100 + 1)
      ).toFixed(2);
      form.setFields([
        { name: "vergilerHaricAlisFiyat", value: vergiHaricFiyat },
      ]);
    } else {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        { name: "vergilerDahilAlisFiyat", value: vergiDahilFiyat },
      ]);
    }
  };
  const satisFiyatHesaplama = (item) => {
    const {
      vergilerHaricSatisFiyat,
      satisKdvOran,
      satisOtvOran,
      vergilerDahilSatisFiyat,
    } = form.getFieldsValue([
      "vergilerHaricSatisFiyat",
      "satisKdvOran",
      "satisOtvOran",
      "vergilerDahilSatisFiyat",
    ]);
    const otvTutar = yuzdeHesala(satisOtvOran, vergilerHaricSatisFiyat);
    const otvliTutar = parseFloat(vergilerHaricSatisFiyat) + otvTutar;
    const kdvTutar = yuzdeHesala(otvliTutar, satisKdvOran);
    if (item.target.id === "vergilerHaricSatisFiyat") {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        { name: "vergilerDahilSatisFiyat", value: vergiDahilFiyat },
      ]);
    } else if (item.target.id === "vergilerDahilSatisFiyat") {
      const vergiHaricFiyat = parseFloat(
        vergilerDahilSatisFiyat /
          (satisKdvOran / 100 + 1) /
          (satisOtvOran / 100 + 1)
      ).toFixed(2);
      form.setFields([
        { name: "vergilerHaricSatisFiyat", value: vergiHaricFiyat },
      ]);
    } else {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        { name: "vergilerDahilSatisFiyat", value: vergiDahilFiyat },
      ]);
    }
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title={baslik}
        onBack={() => history.goBack()}
        extra={
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            style={{ float: "right" }}
            loading={fetchLoading}
            form="myForm"
          >
            {guncelle}
          </Button>
        }
      />
      <br />
      <Form
        form={form}
        onFinish={onFinish}
        size="middle"
        labelCol={{ span: 8 }}
        labelAlign="left"
        id="myForm"
      >
        <Row gutter={24}>
          <Col flex={1}>
            <Card loading={loading}>
              <Form.Item label={stokBilgileri.barkodNUmarasi} name="barkodNo">
                <Input />
              </Form.Item>
              <Form.Item label={stokBilgileri.stokRafi} name="stokRafi">
                <Input />
              </Form.Item>
              <Form.Item label={stokBilgileri.stokKodu} name="stokKodu">
                <Input />
              </Form.Item>
              <Form.Item label={stokBilgileri.gtinNumarasi} name="gtinNumarasi">
                <Input />
              </Form.Item>
              <Form.Item label={stokBilgileri.aciklama} name="aciklama">
                <Input.TextArea rows={1} />
              </Form.Item>
            </Card>
          </Col>
          <Col flex={1}>
            <Card loading={loading}>
              <CurrencyInput
                formItemLabel={stokAlisFiyati.vergilerHaricAlisFiyati}
                formItemName="vergilerHaricAlisFiyat"
                required
                addonAfter={
                  <DovizSelectOption
                    size="middle"
                    formItemName="alisDovizId"
                    required
                    formItemLabel={stokAlisFiyati.alisDovizTuru}
                    showKod
                    titleHidden
                    disabled
                    noStyle
                  />
                }
                onChange={alisFiyatHesaplama}
              />
              <CurrencyInput
                formItemLabel={stokAlisFiyati.alisKdvOran}
                formItemName="alisKdvOran"
                required
                onChange={alisFiyatHesaplama}
              />
              <CurrencyInput
                formItemLabel={stokAlisFiyati.alisOtvOran}
                formItemName="alisOtvOran"
                required
                onChange={alisFiyatHesaplama}
              />
              <CurrencyInput
                formItemLabel={stokAlisFiyati.vergilerDahilAlisFiyati}
                formItemName="vergilerDahilAlisFiyat"
                required
                addonAfter={
                  <DovizSelectOption
                    size="middle"
                    formItemName="alisDovizId"
                    required
                    formItemLabel={stokAlisFiyati.alisDovizTuru}
                    showKod
                    titleHidden
                    disabled
                    noStyle
                  />
                }
                onChange={alisFiyatHesaplama}
              />
              <Divider />
              <CurrencyInput
                formItemLabel={stokSatisFiyati.vergilerHaricSatisFiyati}
                formItemName="vergilerHaricSatisFiyat"
                required
                addonAfter={
                  <DovizSelectOption
                    size="middle"
                    formItemName="satisDovizId"
                    required
                    formItemLabel={stokSatisFiyati.satisDovizTuru}
                    showKod
                    titleHidden
                    disabled
                    noStyle
                  />
                }
                onChange={satisFiyatHesaplama}
              />
              <CurrencyInput
                formItemLabel={stokSatisFiyati.satisKdvOran}
                formItemName="satisKdvOran"
                required
                onChange={satisFiyatHesaplama}
              />
              <CurrencyInput
                formItemLabel={stokSatisFiyati.satisOtvOran}
                formItemName="satisOtvOran"
                required
                onChange={satisFiyatHesaplama}
              />
              <CurrencyInput
                formItemLabel={stokSatisFiyati.vergilerDahilSatisFiyati}
                formItemName="vergilerDahilSatisFiyat"
                required
                addonAfter={
                  <DovizSelectOption
                    size="middle"
                    formItemName="satisDovizId"
                    required
                    formItemLabel={stokSatisFiyati.satisDovizTuru}
                    showKod
                    titleHidden
                    disabled
                    noStyle
                  />
                }
                onChange={satisFiyatHesaplama}
              />
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default StokVaryantGrupDuzenle;
