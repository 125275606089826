export const tarihFormatDuzelt = (dbTarih) => {
  try {
    let tarih = dbTarih.split('T')[0]
    let tarihArray = tarih.split('-')
    return `${tarihArray[2]}.${tarihArray[1]}.${tarihArray[0]}`
  } catch (error) {
    alert(error.message)
  }
}

export const dateToStingFormat = (date) => {
  if (date == null) return ''
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const year = date.getFullYear()
  const output = day + '.' + month + '.' + year
  return output
}
