import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  message,
  notification,
  Select,
  Row,
  Col,
  InputNumber,
} from "antd";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import Datepicker from "../toolbox/DatePicker";
import DepoSelectOption from "../toolbox/DepoSelectOption";
import CurrencyInput from "../toolbox/CurrencyInput";
import { api } from "../../helpers/ApiHelper";
import { useParams } from "react-router-dom";
import strings from "../../utils/Localization";
import useEvrakNo from "../../hooks/useEvrakNo";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";
import moment from "moment";
import DovizSelectOption from "../toolbox/DovizSelectOption";

const { Option } = Select;

const formSize = "middle";

function StokIslemEkleModal({ visible = false, handleCancel, girisMi = null }) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [stokBirimId, setStokBirimId] = useState(null);
  const [fiyat, setFiyat] = useState({ satisFiyat: 0, alisFiyat: 0 });
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { varyantGrupId, stokId } = useParams();
  const { stokIslemEkle: localization } = strings.stokIslem.components;
  const { getStokIslemEvrakNo } = useEvrakNo();
  const { getDefaultSetting } = useVarsayilanDeger();

  useEffect(() => {
    if (girisMi !== null) {
      form.setFieldsValue({
        girisMi: girisMi,
        birimFiyat: getBirimFiyat(),
      });
    }
    getStokIslemEvrakNo().then((evrakNo) => {
      form.setFieldsValue({ evrakNo: evrakNo });
    });
  }, [girisMi, fiyat]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getStok();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getStok = () => {
    api
      .post("/stok/get", {
        Data: {
          Id: stokId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["stokBirimId"],
          },
          {
            TableName: "Stokvaryantgrup.Stokvaryantgrupfiyat",
            ColumnNames: ["genelToplam"],
          },
        ],
      })
      .then((res) => {
        if (res.status === 200) {
          setFiyat({
            satisFiyat:
              res.data.data[0].Stokvaryantgrup[0].Stokvaryantgrupfiyat[0]
                .genelToplam,
            alisFiyat:
              res.data.data[0].Stokvaryantgrup[0].Stokvaryantgrupfiyat[1]
                .genelToplam,
          });
          setStokBirimId(res.data.data[0].stokBirimId);
        }
      })
      .catch((err) => message.error(err.message));
  };

  const getBirimFiyat = () => {
    if (girisMi !== null) {
      if (girisMi === true) {
        return fiyat.alisFiyat;
      } else {
        return fiyat.satisFiyat;
      }
    } else return 0;
  };

  const onFinish = async (values) => {
    try {
      const {
        tarih,
        girisMi,
        evrakNo,
        depoId,
        miktar,
        aciklama,
        birimFiyat,
        dovizId,
      } = values;
      setFetchLoading(true);
      const response = await api.post("/stokislem", {
        StokVaryantGrupId: varyantGrupId,
        evrakNo: evrakNo,
        StokDepoId: depoId,
        Miktar: miktar,
        GirisMi: girisMi,
        Aciklama: aciklama,
        IslemTarihi: tarih,
        DovizId: dovizId,
        StokBirimId: stokBirimId,
        BirimFiyat: birimFiyat,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        getStokIslemEvrakNo().then((evrakNo) => {
          form.setFieldsValue({ evrakNo: evrakNo });
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "stokIslemEkleModal",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      centered
      width="50%"
    >
      <Form
        name="stokIslemEkleModal"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          girisMi: girisMi,
          tarih: moment(),
          depoId: getDefaultSetting("stokDepo"),
          dovizId: getDefaultSetting("doviz"),
          birimFiyat: getBirimFiyat(),
        }}
        size={formSize}
      >
        <Row gutter={12}>
          <Col flex={1}>
            <Form.Item
              name="tarih"
              label={localization.tarih}
              rules={[{ required: true }]}
            >
              <Datepicker />
            </Form.Item>
            <Form.Item
              name="girisMi"
              label={localization.islemTipi}
              rules={[{ required: true }]}
            >
              <Select disabled>
                <Option value={true} style={{ color: "green" }}>
                  {localization.islemTipiSelect.giris}
                </Option>
                <Option value={false} style={{ color: "red" }}>
                  {localization.islemTipiSelect.cikis}
                </Option>
              </Select>
            </Form.Item>
            <DepoSelectOption
              formItemLabel={localization.depo}
              formItemName="depoId"
            />
          </Col>
          <Col flex={1}>
            <Form.Item name="evrakNo" label={localization.evrakNo}>
              <Input />
            </Form.Item>
            <Form.Item name="aciklama" label={localization.aciklama}>
              <Input.TextArea rows={5} />
            </Form.Item>
            <Row>
              <Form.Item
                name="birimFiyat"
                label="Birim Fiyat"
                style={{ flex: 1, marginRight: 8 }}
                rules={[{ required: true }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
              <DovizSelectOption formItemName="dovizId" showKod required />
            </Row>
            <CurrencyInput
              required
              formItemLabel={localization.miktar}
              formItemName="miktar"
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default StokIslemEkleModal;
