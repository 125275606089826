import React from "react";
import { Form, Select } from "antd";
import { odemeSekliTypes } from "../../types/odemeSekliTypes";
import strings from "../../utils/Localization";

const { Option } = Select;

function OdemeSekliSelectOption({
  formItemName,
  formItemLabel,
  required,
  placeholder,
  hidden,
  disabled,
  hiddenAcikHesap = false,
  hiddenNakit = false,
  hiddenKrediKarti = false,
  hiddenHavale = false,
  allowClear,
}) {
  return (
    <Form.Item
      name={formItemName}
      label={formItemLabel}
      rules={[{ required: required }]}
      hidden={hidden}
    >
      <Select
        placeholder={
          placeholder || strings.odemeSekliSelectOption.odemeSekliPlaceHolder
        }
        disabled={disabled}
        allowClear={allowClear}
      >
        {!hiddenAcikHesap && (
          <Option value={odemeSekliTypes.ACIK_HESAP}>
            {strings.odemeSekliSelectOption.acikHesap}
          </Option>
        )}
        {!hiddenNakit && (
          <Option value={odemeSekliTypes.NAKIT}>
            {strings.odemeSekliSelectOption.nakit}
          </Option>
        )}
        {!hiddenKrediKarti && (
          <Option value={odemeSekliTypes.KREDI_KARTI}>
            {strings.odemeSekliSelectOption.krediKarti}
          </Option>
        )}
        {!hiddenHavale && (
          <Option value={odemeSekliTypes.HAVALE}>
            {strings.odemeSekliSelectOption.havale}
          </Option>
        )}
      </Select>
    </Form.Item>
  );
}

export default OdemeSekliSelectOption;
