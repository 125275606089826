import { Space, Layout as AntLayout, Image, Card } from "antd";
import React from "react";
import Logo104 from "../../assets/images/logo104.png";

const formWidth = 380;
const formRadius = 24;

const formStyle = {
  borderRadius: formRadius,
  width: formWidth,
};

function Layout({ children }) {
  return (
    <AntLayout>
      <Space
        direction="vertical"
        align="center"
        size="large"
        style={{ minHeight: "100vh", marginTop: 80 }}
      >
        <Image src={Logo104} width={104} />
        <Card style={formStyle}>{children}</Card>
      </Space>
    </AntLayout>
  );
}

export default Layout;
