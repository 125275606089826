import React from "react";
import { Row, Col, Typography } from "antd";
import {
  VadeliTahsilatCicleProgress,
  VadeliOdemeCicleProgress,
  TahsilatOdemeBarChart,
  KasaBakiyeleri,

  BankaBakiyeleri,
} from "../components/panel";
import strings from "../utils/Localization";

const { Title } = Typography;

export default function Panel() {
  return (
    <>
      <Title level={3}>{strings.panel.baslik}</Title>
      <br />

      <VadeliTahsilatCicleProgress
        title={strings.panel.components.vadeliTahsilatCicleProgress.baslik}
      />
      <br />
      <VadeliOdemeCicleProgress
        title={strings.panel.components.vadeliOdemeCicleProgress.baslik}
      />
      <br />
      <TahsilatOdemeBarChart />
      <br />
      <Row gutter={[24, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <KasaBakiyeleri />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <BankaBakiyeleri />
        </Col>
      </Row>
      <br />
    </>
  );
}
