import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Select,
  message,
  notification,
} from "antd";
import Datepicker from "../toolbox/DatePicker";
import moment from "moment";
import strings from "../../utils/Localization";
import { api } from "../../helpers/ApiHelper";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useEvrakNo from "../../hooks/useEvrakNo";

const { Option } = Select;

const formSize = "middle";

function BankaHesapIslemEkleModal({
  visible = false,
  handleCancel,
  girisMi = null,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const { bankaHesapId } = useParams();
  const dispatch = useDispatch();
  const { bankaHesapIslemEkleModal: localization } = strings.bankaHesapIslem;
  const { getBankaHesapIslemEvrakNo } = useEvrakNo();

  useEffect(() => {
    form.setFieldsValue({
      girisMi: girisMi,
    });
    getBankaHesapIslemEvrakNo().then((evrakNo) => {
      form.setFieldsValue({ evrakNo: evrakNo });
    });
  }, [girisMi]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const { evrakNo, aciklama, girisMi, tutar, islemTarihi } = values;
      setFetchLoading(true);
      const response = await api.post("/bankahesapislem/", {
        BankaHesapId: bankaHesapId,
        EvrakNo: evrakNo,
        Aciklama: aciklama,
        VirmanMi: 0,
        GirisMi: girisMi ? 1 : 0,
        Tutar: tutar,
        IslemTarihi: islemTarihi,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        getBankaHesapIslemEvrakNo().then((evrakNo) => {
          form.setFieldsValue({ evrakNo: evrakNo });
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "bankaHesapIslemEkleForm",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      width="50%"
    >
      <Form
        form={form}
        id="bankaHesapIslemEkleForm"
        size={formSize}
        layout="vertical"
        initialValues={{
          islemTarihi: moment(),
          girisMi: girisMi,
        }}
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col flex={1}>
            <Form.Item
              name="islemTarihi"
              label={localization.islemTarihi}
              rules={[{ required: true }]}
            >
              <Datepicker />
            </Form.Item>
            <Form.Item
              name="girisMi"
              label={localization.islemTipi}
              rules={[{ required: true }]}
            >
              <Select>
                <Option value={true} style={{ color: "green" }}>
                  {localization.islemTipiSelect.giris}
                </Option>
                <Option value={false} style={{ color: "red" }}>
                  {localization.islemTipiSelect.cikis}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item label={localization.evrakNo} name="evrakNo">
              <Input />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              label={localization.tutar}
              name="tutar"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item label={localization.aciklama} name="aciklama">
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default BankaHesapIslemEkleModal;
