import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Button,
  Space,
  Card,
  Form,
  Input,
  Col,
  message,
  notification,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import StokBirimSelectOption from "../components/toolbox/StokBirimSelectOption";
import StokKategoriSelectOption from "../components/toolbox/StokKategoriSelectOption";
import { api } from "../helpers/ApiHelper";
import strings from "../utils/Localization";

function VaryantliStokDuzenle() {
  const [form] = Form.useForm();
  const history = useHistory();
  const { stokId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { varyantliStokDuzenle: localization } = strings;

  useEffect(() => {
    fetchStok();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStok = async () => {
    try {
      setLoading(true);
      const response = await api.post("/stok/get", {
        Data: {
          Id: stokId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["ad", "aciklama", "id"],
          },
          {
            TableName: "StokKategori",
            ColumnNames: ["id"],
          },
          {
            TableName: "StokBirim",
            ColumnNames: ["id"],
          },
        ],
      });
      if (response.status === 200) {
        const { ad, aciklama, StokBirim, StokKategori } = response.data.data[0];
        form.setFieldsValue({
          urunAdi: ad,
          stokBirimId: String(StokBirim.id),
          stokKategoriId: String(StokKategori.id),
          aciklama: aciklama,
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      const { urunAdi, stokBirimId, stokKategoriId, aciklama } = values;
      setFetchLoading(true);
      const response = await api.put("/stok", {
        Id: stokId,
        Ad: urunAdi,
        StokBirimId: stokBirimId,
        StokKategoriId: stokKategoriId,
        Aciklama: aciklama,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <PageHeader
        ghost={false}
        onBack={() => history.goBack()}
        title={localization.baslik}
      />
      <Col xxl={8} xl={8} lg={16} md={16} sm={24} xs={24}>
        <Card loading={loading}>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item name="urunAdi" label={localization.urunAdi}>
              <Input />
            </Form.Item>
            <StokBirimSelectOption formItemName="stokBirimId" />
            <StokKategoriSelectOption formItemName="stokKategoriId" />
            <Form.Item name="aciklama" label={localization.aciklama}>
              <Input.TextArea rows={2} />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                size="large"
                htmlType="submit"
                type="primary"
                style={{ float: "right" }}
                loading={fetchLoading}
              >
                {localization.guncelleButon}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Space>
  );
}

export default VaryantliStokDuzenle;
