import { Card, message, Collapse } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { api } from "../../helpers/ApiHelper";
import useCurrency from "../../hooks/useCurrency";
import strings from "../../utils/Localization";

const { Panel } = Collapse;

function CariBakiye() {
  const [cariSonBakiyeData, setCariSonBakiyeData] = useState([]);
  const { cariKartId } = useParams();
  const rerenderReducer = useSelector((state) => state.rerenderReducer);

  useEffect(() => {
    fetchCariSonBakiye();
  }, [rerenderReducer]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCariSonBakiye = async () => {
    try {
      const response = await api.post(
        `carikart/getsonbakiye/${cariKartId}`,
        {}
      );
      if (response.status === 200) {
        setCariSonBakiyeData(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Collapse defaultActiveKey={"0"}>
        {cariSonBakiyeData.map((item, index) => {
          const { dovizAd, dovizSimge, dovizKod, giren, cikan, bakiye } = item;
          return (
            <Panel
              header={`${dovizKod} ${strings.cariBakiye.bakiye}`}
              key={index}
            >
              <BakiyeItem
                key={index}
                doviz={{ dovizAd, dovizSimge, dovizKod }}
                giris={giren}
                cikis={cikan}
                bakiye={bakiye}
              />
            </Panel>
          );
        })}
      </Collapse>
    </Card>
  );
}

export default CariBakiye;

const BakiyeItem = ({ doviz = {}, giris, cikis, bakiye }) => {
  const {
    doviz: dovizString,
    giris: girisString,
    cikis: cikisString,
    bakiye: bakiyeString,
  } = strings.cariDetay.components.cariBakiye;
  const { paraFormat } = useCurrency();

  const gridStyle = {
    width: "50%",
    textAlign: "center",
    padding: 4,
  };
  const gridTitleStyle = {
    ...gridStyle,
    fontWeight: "600",
  };

  return (
    <>
      <Card.Grid style={gridTitleStyle} hoverable={false}>
        {dovizString}
      </Card.Grid>
      <Card.Grid
        style={gridStyle}
      >{`${doviz.dovizAd} (${doviz.dovizKod})`}</Card.Grid>
      <Card.Grid style={gridTitleStyle} hoverable={false}>
        {girisString}
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        {doviz.dovizSimge}
        {paraFormat(giris)}
      </Card.Grid>
      <Card.Grid style={gridTitleStyle} hoverable={false}>
        {cikisString}
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        {doviz.dovizSimge}
        {paraFormat(cikis)}
      </Card.Grid>
      <Card.Grid style={gridTitleStyle} hoverable={false}>
        {bakiyeString}
      </Card.Grid>
      <Card.Grid style={{ ...gridStyle, marginBottom: 16 }}>
        {doviz.dovizSimge}
        {paraFormat(bakiye)}
      </Card.Grid>
    </>
  );
};
