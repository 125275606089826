import { Select, Form, message } from "antd";
import React, { useState } from "react";
import { api } from "../../helpers/ApiHelper";
import strings from "../../utils/Localization";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

function StokSelectOption({
  formItemName,
  formItemLabel,
  required,
  allowClear,
  placeholder,
  labelHidden,
  requestBody = {},
  hidden = false,
  onChange = () => {},
}) {
  const [selectOptionData, setSelectOptionData] = useState([]);
  const [stokData, setStokData] = useState([]);
  const { stokBaslik, stokPlaceHolder } = strings.stokSelectOption;

  const fetchStok = async (searchText) => {
    try {
      const response = await api.post("/stok/get", {
        Includes: ["Stokvaryantgrup", "Stokvaryantgrup.Stokvaryantgrupfiyat"],
        SearchText: {
          PropertyNames: null,
          Value: searchText,
        },
        PagingOptions: {
          PageSize: 10,
          PageNumber: 1,
        },
        ...requestBody,
      });
      if (response.status === 200) {
        let data = [];
        response.data.data.map((item) =>
          data.push({
            text: item.ad,
            value: item.stokvaryantgrup[0].id,
          })
        );
        setStokData(response.data.data);
        setSelectOptionData(data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const onSearch = (searchText = "") => {
    if (searchText.length === 0) {
      setSelectOptionData([]);
    } else {
      fetchStok(searchText);
    }
  };

  return (
    <Form.Item
      name={formItemName}
      label={labelHidden ? "" : formItemLabel || stokBaslik}
      rules={[{ required: required }]}
      hidden={hidden}
    >
      <Select
        suffixIcon={<SearchOutlined size={24} />}
        showSearch
        onSearch={onSearch}
        notFoundContent={null}
        defaultActiveFirstOption={false}
        showArrow={true}
        filterOption={false}
        placeholder={placeholder || stokPlaceHolder}
        allowClear={allowClear}
        onChange={(e) =>
          onChange(
            stokData.find((x) => x.stokvaryantgrup[0].id === parseInt(e))
          )
        }
      >
        {selectOptionData.map((d) => (
          <Option key={d.value}>{d.text}</Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default StokSelectOption;
