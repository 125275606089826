import React from "react";
import {
  Button,
  Space,
  Tag,
  notification,
  message,
  Popconfirm,
  Card,
  Form,
  Row,
  Col,
  InputNumber,
  Radio,
  Spin,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import CursorTableItem from "../toolbox/CursorTableItem";
import { useHistory } from "react-router-dom";
import ListFetchContent from "../toolbox/FetchListContent";
import { tarihFormatDuzelt } from "../../helpers/TarihHelper";
import useCurrency from "../../hooks/useCurrency";
import strings from "../../utils/Localization";
import { useState } from "react";
import { faturaTypes } from "../../types/faturaTypes";
import { api } from "../../helpers/ApiHelper";
import DatepickerRanger from "../toolbox/DatepickerRanger";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import moment from "moment";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";

const initialFilterRequestData = {
  Data: {
    FaturaTip: null,
    DovizId: null,
  },
  DateOptions: [
    {
      DatePropertyName: "FaturaTarihi",
      FirstDate: null,
      LastDate: null,
    },
  ],
  BetweenOptions: [
    {
      PropertyName: "GenelToplam",
      First: null,
      Last: null,
    },
  ],
};

export default function FaturaListe() {
  const [dataSource, setDataSource] = useState({});
  const [filterRequestData, setFilterRequestData] = useState({
    ...initialFilterRequestData,
  });
  const [spinning, setSpinning] = useState(false);
  const history = useHistory();
  const { paraFormat } = useCurrency();
  const dispatch = useDispatch();
  const [detailSearchForm] = Form.useForm();
  const { faturaListe, detayliArama } = strings.faturaListe.components;

  const columns = [
    {
      title: faturaListe.firmaAdi,
      dataIndex: "firmaAdi",
      key: "firmaAdi",
      render: (text, row, index) => (
        <CursorTableItem
          text={text}
          onClick={() => history.push(`/FaturaDuzenle/${row.id}`)}
        />
      ),
    },
    {
      title: faturaListe.faturaNo,
      dataIndex: "faturaNo",
      key: "faturaNo",
    },
    {
      title: faturaListe.faturaTipi,
      dataIndex: "faturaTip",
      key: "faturaTip",
      render: (text) => (
        <Tag color={getFaturaTipColor(text)} style={{ fontSize: 14 }}>
          {text}
        </Tag>
      ),
    },
    {
      title: faturaListe.faturaTarihi,
      dataIndex: "faturaTarihi",
      key: "faturaTarihi",
      render: (text) => tarihFormatDuzelt(text),
    },
    {
      title: faturaListe.genelToplam,
      dataIndex: "genelToplam",
      key: "genelToplam",
      render: (text, row, index) => `${row.Doviz.simge}${paraFormat(text)}`,
    },
    {
      title: faturaListe.islemler,
      dataIndex: "islemler",
      key: "islemler",
      render: (text, row, index) => (
        <Space size="middle">
          <Button
            onClick={() => fetchFaturaMakbuz(row.id)}
            type="default"
            shape="circle"
            icon={<FileTextOutlined />}
            size={"large"}
          />
          <Button
            onClick={() => history.push(`/FaturaDuzenle/${row.id}`)}
            type="default"
            shape="circle"
            icon={<EditOutlined />}
            size={"large"}
          />
          <Popconfirm
            title={strings.mesaj.silmeIslemUyari}
            onConfirm={() => remove(row.id)}
          >
            <Button
              type="default"
              shape="circle"
              icon={<DeleteOutlined />}
              size={"large"}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getFaturaTipColor = (tip) => {
    switch (tip) {
      case faturaTypes.SATIS_FATURASI:
        return "cyan";
      case faturaTypes.ALIS_FATURASI:
        return "red";
      case faturaTypes.ALIS_IADE_FATURASI:
        return "blue";
      case faturaTypes.SATIS_IADE_FATURASI:
        return "purple";
      default:
        return "geekblue";
    }
  };

  const remove = async (faturaId) => {
    try {
      const response = await api.delete(`/fatura/${faturaId}`, {});
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== faturaId),
        });
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchFaturaMakbuz = async (faturaId) => {
    try {
      setSpinning(true);
      const response = await api.post("/rapordosya/faturamakbuz", {
        FaturaId: faturaId,
        format: "pdf",
      });

      if (response.status === 200) {
        window.open(response.data, "_blank");
      } else message.error(strings.mesaj.islemBasarisiz);
    } catch (error) {
      message.error(error.message);
    } finally {
      setSpinning(false);
    }
  };

  const detayliAramaOnFinish = async (props) => {
    const { islemTarihi, faturaTipi, minTutar, maxTutar, dovizId } = props;

    let newData = { ...initialFilterRequestData };

    newData = {
      Data: {
        DovizId: dovizId !== undefined ? dovizId : null,
        FaturaTip: faturaTipi !== undefined ? faturaTipi : null,
      },
      DateOptions: [
        {
          DatePropertyName: "FaturaTarihi",
          FirstDate:
            islemTarihi !== undefined
              ? `${moment(islemTarihi[0]).format("YYYY-MM-DD")} 00:00:00`
              : null,
          LastDate:
            islemTarihi !== undefined
              ? `${moment(islemTarihi[1]).format("YYYY-MM-DD")} 23:59:59`
              : null,
        },
      ],
      BetweenOptions: [
        {
          PropertyName: "GenelToplam",
          First: minTutar !== undefined ? minTutar : null,
          Last: maxTutar !== undefined ? maxTutar : null,
        },
      ],
    };
    await setFilterRequestData(newData);
    dispatch(setRerender());
  };
  const detayliAramaReset = async () => {
    await setFilterRequestData({ ...initialFilterRequestData });
    detailSearchForm.resetFields();

    dispatch(setRerender());
  };

  return (
    <Spin spinning={spinning}>
      <ListFetchContent
        detailSearch={
          <Card title={detayliArama.baslik}>
            <Form
              form={detailSearchForm}
              name="detailSearchForm"
              layout="vertical"
              onFinish={detayliAramaOnFinish}
              initialValues={{
                faturaTipi: null,
              }}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <DatepickerRanger
                    formName="islemTarihi"
                    formLabel={detayliArama.islemTarihi}
                  />
                </Col>
                <Col span={8}>
                  <Space
                    direction="vertical"
                    align="center"
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      name="faturaTipi"
                      label={detayliArama.faturaTipi}
                    >
                      <Radio.Group>
                        <Space direction="vertical">
                          <Radio value={null}>
                            {detayliArama.tumFaturalariGoster}
                          </Radio>
                          <Radio value={faturaTypes.ALIS_FATURASI}>
                            {detayliArama.alisFaturalariniGoster}
                          </Radio>
                          <Radio value={faturaTypes.SATIS_FATURASI}>
                            {detayliArama.satisFaturalariniGoster}
                          </Radio>
                          <Radio value={faturaTypes.ALIS_IADE_FATURASI}>
                            {detayliArama.alisIadeFaturalariniGoster}
                          </Radio>
                          <Radio value={faturaTypes.SATIS_IADE_FATURASI}>
                            {detayliArama.satisIadeFaturalariniGoster}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space direction="vertical">
                    <Space size="small">
                      <Form.Item label={detayliArama.minTutar} name="minTutar">
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                      <DovizSelectOption
                        formItemLabel={detayliArama.doviz}
                        formItemName="dovizId"
                      />
                    </Space>
                    <Space size="small">
                      <Form.Item label={detayliArama.maxTutar} name="maxTutar">
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                      <DovizSelectOption
                        formItemLabel={detayliArama.doviz}
                        formItemName="dovizId"
                      />
                    </Space>
                  </Space>
                </Col>
              </Row>
              <Space align="baseline" style={{ float: "right" }}>
                <Button
                  type="default"
                  size="middle"
                  icon={<ClearOutlined />}
                  style={{ float: "right" }}
                  onClick={detayliAramaReset}
                >
                  {detayliArama.temizle}
                </Button>
                <Button
                  type="default"
                  size="middle"
                  icon={<SearchOutlined />}
                  style={{ float: "right" }}
                  form="detailSearchForm"
                  htmlType="submit"
                >
                  {detayliArama.aramayiBaslat}
                </Button>
              </Space>
            </Form>
          </Card>
        }
        columns={columns}
        requestUrl="/fatura/get"
        requestBody={{
          SelectColumns: [
            {
              ColumnNames: [
                "id",
                "faturaTip",
                "firmaAdi",
                "faturaNo",
                "faturaTarihi",
                "genelToplam",
              ],
              TableName: "",
            },
            {
              TableName: "Doviz",
              ColumnNames: ["id", "ad", "simge"],
            },
          ],
          ...filterRequestData,
        }}
        dataSource={dataSource}
        onDataSource={(e) => setDataSource(e)}
      />
    </Spin>
  );
}
