import React from 'react'
import { PageHeader, FaturaListe as FaturaListeTable } from '../components/faturaListe'
import { Space } from 'antd'

export default function FaturaListe() {
    return (
        <>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <PageHeader />
                <FaturaListeTable />
            </Space>
        </>
    )
}
