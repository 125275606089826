import {
  Button,
  Popconfirm,
  Space,
  Card,
  Form,
  Input,
  Row,
  Col,
  notification,
  message,
} from "antd";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import FetchListContent from "../toolbox/FetchListContent";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import strings from "../../utils/Localization";
import CursorTableItem from "../toolbox/CursorTableItem";
import { api } from "../../helpers/ApiHelper";

function StokVaryantGrupListe() {
  const [dataSource, setDataSource] = useState([]);
  const { stokId } = useParams();
  const { stokVaryantGrupListe: localization } =
    strings.varyantliStokDetay.components;
  const history = useHistory();

  const columns = [
    {
      title: localization.varyantGrup,
      dataIndex: "varyantGrup",
      key: "varyantGrup",
      render: (text, row, index) => {
        return (
          <CursorTableItem
            text={`${row.StokvaryantgrupHasStokvaryantdeger.map(
              (item) => item.StokVaryantDeger.Ad
            ).join("-")} (${row.stokIslemSayisi})`}
          />
        );
      },
    },
    {
      title: localization.islemler,
      dataIndex: "islemler",
      key: "islemler",
      render: (text, row, index) => (
        <Space size="middle">
          <Button
            type="default"
            shape="circle"
            icon={<EditOutlined />}
            size={"large"}
            onClick={() => history.push(`/StokVaryantGrupDuzenle/${row.id}`)}
          />
          <Popconfirm
            title={strings.mesaj.silmeIslemUyari}
            onConfirm={() => {
              if (row.stokIslemSayisi > 0) {
                message.error({
                  content: strings.mesaj.stokVaryantGrupSilme,
                  style: { marginTop: "20vh" },
                });
              } else remove(row.id);
            }}
          >
            <Button
              type="default"
              shape="circle"
              icon={<DeleteOutlined />}
              size={"large"}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const remove = async (stokVaryantGrupId) => {
    try {
      const response = await api.delete(
        `/stokvaryantgrup/${stokVaryantGrupId}`,
        {}
      );
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== stokVaryantGrupId),
        });
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const expandedRowRender = (record) => {
    const satis = record.Stokvaryantgrupfiyat[0];
    const alis = record.Stokvaryantgrupfiyat[1];

    return (
      <Form size="middle" layout="vertical">
        <Space direction="vertical" style={{ width: "100%" }}>
          <Card title={localization.ekstraVeri.digerBilgiler.baslik}>
            <Row gutter={12}>
              <Col flex={1}>
                <Form.Item label={localization.ekstraVeri.digerBilgiler.barkod}>
                  <Input value={record.Barkod} readOnly />
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item label={localization.ekstraVeri.digerBilgiler.gtin}>
                  <Input value={record.Gtin} readOnly />
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item label={localization.ekstraVeri.digerBilgiler.kod}>
                  <Input value={record.Kod} readOnly />
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item label={localization.ekstraVeri.digerBilgiler.raf}>
                  <Input value={record.Raf} readOnly />
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item
                  label={localization.ekstraVeri.digerBilgiler.aciklama}
                >
                  <Input value={record.Aciklama} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Row gutter={12}>
            <Col flex={1}>
              <Card title={localization.ekstraVeri.alisFiyatBilgileri}>
                <Form.Item
                  label={localization.ekstraVeri.fiyatlandirma.vergiHaricFiyat}
                >
                  <Input
                    value={alis.Fiyat}
                    addonAfter={`${alis.Doviz.Kod}`}
                    readOnly
                  />
                </Form.Item>
                <Row gutter={12}>
                  <Col flex={1}>
                    <Form.Item
                      label={localization.ekstraVeri.fiyatlandirma.kdvOran}
                    >
                      <Input value={alis.KdvOran} addonBefore="%" readOnly />
                    </Form.Item>
                  </Col>
                  <Col flex={1}>
                    <Form.Item
                      label={localization.ekstraVeri.fiyatlandirma.kdvTutar}
                    >
                      <Input
                        value={alis.KdvTutar}
                        addonAfter={`${alis.Doviz.Kod}`}
                        readOnly
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col flex={1}>
                    <Form.Item
                      label={localization.ekstraVeri.fiyatlandirma.otvOran}
                    >
                      <Input value={alis.OtvOran} addonBefore="%" readOnly />
                    </Form.Item>
                  </Col>
                  <Col flex={1}>
                    <Form.Item
                      label={localization.ekstraVeri.fiyatlandirma.otvTutar}
                    >
                      <Input
                        value={alis.OtvTutar}
                        addonAfter={`${alis.Doviz.Kod}`}
                        readOnly
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label={localization.ekstraVeri.fiyatlandirma.vergiDahilTutar}
                >
                  <Input
                    value={alis.GenelToplam}
                    addonAfter={`${alis.Doviz.Kod}`}
                    readOnly
                  />
                </Form.Item>
              </Card>
            </Col>
            <Col flex={1}>
              <Card title={localization.ekstraVeri.satisFiyatBilgileri}>
                <Form.Item
                  label={localization.ekstraVeri.fiyatlandirma.vergiHaricFiyat}
                >
                  <Input
                    value={satis.Fiyat}
                    addonAfter={`${satis.Doviz.Kod}`}
                    readOnly
                  />
                </Form.Item>
                <Row gutter={12}>
                  <Col flex={1}>
                    <Form.Item
                      label={localization.ekstraVeri.fiyatlandirma.kdvOran}
                    >
                      <Input value={satis.KdvOran} addonBefore="%" readOnly />
                    </Form.Item>
                  </Col>
                  <Col flex={1}>
                    <Form.Item
                      label={localization.ekstraVeri.fiyatlandirma.kdvTutar}
                    >
                      <Input
                        value={satis.KdvTutar}
                        addonAfter={`${satis.Doviz.Kod}`}
                        readOnly
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col flex={1}>
                    <Form.Item
                      label={localization.ekstraVeri.fiyatlandirma.otvOran}
                    >
                      <Input value={satis.OtvOran} addonBefore="%" readOnly />
                    </Form.Item>
                  </Col>
                  <Col flex={1}>
                    <Form.Item
                      label={localization.ekstraVeri.fiyatlandirma.otvTutar}
                    >
                      <Input
                        value={satis.OtvTutar}
                        addonAfter={`${satis.Doviz.Kod}`}
                        readOnly
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label={localization.ekstraVeri.fiyatlandirma.vergiDahilTutar}
                >
                  <Input
                    value={satis.GenelToplam}
                    addonAfter={`${satis.Doviz.Kod}`}
                    readOnly
                  />
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Space>
      </Form>
    );
  };

  return (
    <FetchListContent
      dataSource={dataSource}
      onDataSource={(e) => setDataSource(e)}
      requestUrl={`/stokvaryantgrup/get`}
      requestBody={{
        Data: {
          StokId: stokId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["id", "Barkod", "Gtin", "Kod", "Raf", "Aciklama"],
          },
          {
            TableName: "StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger",
            ColumnNames: ["Id", "Ad"],
          },
          {
            TableName:
              "StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger.StokVaryant",
            ColumnNames: ["Id", "Ad"],
          },
          {
            TableName: "Stokvaryantgrupfiyat",
            ColumnNames: [
              "id",
              "Fiyat",
              "OtvTutar",
              "OtvOran",
              "OivTutar",
              "OivOran",
              "KdvTutar",
              "KdvOran",
              "GenelToplam",
              "SatisMi",
            ],
          },
          {
            TableName: "Stokvaryantgrupfiyat.Doviz",
            ColumnNames: ["Id", "Ad", "Kod", "Simge"],
          },
          {
            TableName: "Stokislem:stokIslemSayisi",
            Operator: "Count",
          },
        ],
      }}
      columns={columns}
      expandable={{ expandedRowRender }}
    />
  );
}

export default StokVaryantGrupListe;
