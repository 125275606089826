import React from "react";
import { Form, Select } from "antd";
import { faturaTypes } from "../../types/faturaTypes";
import strings from "../../utils/Localization";

const { Option } = Select;

function FaturaTipSelectOption({
  formItemName,
  formItemLabel,
  required,
  placeholder,
  hidden,
  disabled,
  allowClear,
}) {
  const { faturaSelectOption: localization } = strings;
  return (
    <Form.Item
      name={formItemName}
      label={formItemLabel || localization.faturaTip}
      rules={[{ required: required }]}
      hidden={hidden}
    >
      <Select
        placeholder={placeholder || localization.faturaTipPlaceHolder}
        disabled={disabled}
        allowClear={allowClear}
      >
        <Option value={faturaTypes.SATIS_FATURASI}>
          {localization.satisFaturasi.nakit}
        </Option>
        <Option value={faturaTypes.ALIS_FATURASI}>
          {localization.alisFaturasi}
        </Option>
        <Option value={faturaTypes.SATIS_IADE_FATURASI}>
          {localization.satisIadeFaturasi}
        </Option>
        <Option value={faturaTypes.ALIS_IADE_FATURASI}>
          {localization.alisIadeFaturasi}
        </Option>
      </Select>
    </Form.Item>
  );
}

export default FaturaTipSelectOption;
