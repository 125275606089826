import React, { useState } from "react";
import {
  PageHeader,
  Form,
  Input,
  Col,
  Row,
  Card,
  Button,
  Divider,
  Space,
  message,
  notification,
} from "antd";
import { useHistory } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import StokBirimSelectOption from "../components/toolbox/StokBirimSelectOption";
import StokKategoriSelectOption from "../components/toolbox/StokKategoriSelectOption";
import StokVaryantSelectOption from "../components/toolbox/StokVaryantSelectOption";
import { PlusOutlined, SaveFilled, DeleteOutlined } from "@ant-design/icons";
import CurrencyInput from "../components/toolbox/CurrencyInput";
import DovizSelectOption from "../components/toolbox/DovizSelectOption";
import useVarsayilanDeger from "../hooks/useVarsayilanDeger";
import { yuzdeHesala } from "../helpers/HesaplamaHelper";
import { api } from "../helpers/ApiHelper";
import strings from "../utils/Localization";

function VaryantliStokEkle() {
  const [form] = useForm();
  const history = useHistory();
  const { getDefaultSetting } = useVarsayilanDeger();
  const [fetchLoading, setFetchLoading] = useState(false);
  const { varyantliStokEkle: localization } = strings;

  const onFinish = async (values) => {
    try {
      const {
        urunAdi,
        stokBirimId,
        stokKategoriId,
        aciklama,
        varyantGrup = [],
      } = values;

      if (varyantGrup.length === 0) {
        return message.warning("Lütfen varyant oluşturunuz");
      }

      let varyantGrupData = [];
      varyantGrup.map((item) => {
        return varyantGrupData.push({
          Barkod: item.barkod,
          Aciklama: item.aciklama,
          Kod: item.kod,
          Gtin: item.gtin,
          Raf: item.raf,
          ResimUrl: "",
          resimAd: new Date().valueOf(),
          StokvaryantgrupHasStokvaryantdeger: Array.isArray(item.varyant)
            ? item.varyant.map((varyantItem) => {
                return { StokVaryantDegerId: varyantItem.Id };
              })
            : [{ StokVaryantDegerId: item.varyant.Id }],
          Stokvaryantgrupfiyat: [
            {
              DovizId: item.satisDovizId,
              OtvTutar: item.satisOtvTutar,
              OtvOran: item.satisOtvOran,
              KdvTutar: item.satisKdvTutar,
              KdvOran: item.satisKdvOran,
              Fiyat: item.vergilerHaricSatisFiyat,
              GenelToplam: item.vergilerDahilSatisFiyat,
              SatisMi: 1,
            },
            {
              DovizId: item.alisDovizId,
              OtvTutar: item.alisOtvTutar,
              OtvOran: item.alisOtvOran,
              KdvTutar: item.alisKdvTutar,
              KdvOran: item.alisKdvOran,
              Fiyat: item.vergilerHaricAlisFiyat,
              GenelToplam: item.vergilerDahilAlisFiyat,
              SatisMi: 0,
            },
          ],
        });
      });
      setFetchLoading(true);
      const response = await api.post("/stok", {
        StokKategoriId: stokKategoriId,
        StokBirimId: stokBirimId,
        ad: urunAdi,
        aciklama: aciklama,
        Stokvaryantgrup: varyantGrupData,
      });

      if (response.status === 200) {
        notification["success"]({
          description: strings.mesaj.islemBasarili,
          message: strings.mesaj.basarliBaslik,
        });
        form.resetFields();
        form.setFieldsValue({ varyantGrup: [] });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const createVaryant = () => {
    const { selectStokVaryant } = form.getFieldValue();
    const jsonData = selectStokVaryant.map((item) => {
      return JSON.parse(item);
    });

    const seciliVaryantIdArray = jsonData
      .map((item) => item.StokVaryantId)
      .filter((v, i, a) => a.indexOf(v) === i);

    let varyantGrupArray = [];
    seciliVaryantIdArray.map((seciliVaryantId) => {
      let data = [];
      jsonData
        .filter((x) => x.StokVaryantId === seciliVaryantId)
        .map((i) => {
          return data.push(i);
        });
      return varyantGrupArray.push(data);
    });

    var result = varyantGrupArray.reduce((a, b) =>
      a.reduce((r, v) => r.concat(b.map((w) => [].concat(v, w))), [])
    );

    form.setFields([
      {
        name: "varyantGrup",
        value: result.map((item) => ({
          baslik: Array.isArray(item) ? item.map((i) => i.Ad + " - ") : item.Ad,
          varyant: item,
          barkod: "",
          gtin: "",
          kod: "",
          raf: "",
          aciklama: "",
          vergilerHaricAlisFiyat: 0,
          alisDovizId: String(getDefaultSetting("doviz")),
          alisKdvOran: 0,
          alisKdvTutar: 0,
          alisOtvOran: 0,
          alisOtvTutar: 0,
          vergilerDahilAlisFiyat: 0,
          vergilerHaricSatisFiyat: 0,
          satisDovizId: String(getDefaultSetting("doviz")),
          satisKdvOran: 0,
          satisKdvTutar: 0,
          satisOtvOran: 0,
          satisOtvTutar: 0,
          vergilerDahilSatisFiyat: 0,
        })),
      },
    ]);
  };

  const satisFiyatHesaplama = (item, name) => {
    const { varyantGrup } = form.getFieldsValue();
    const {
      vergilerHaricSatisFiyat,
      satisKdvOran,
      satisOtvOran,
      vergilerDahilSatisFiyat,
    } = varyantGrup[name];

    let yeniVergilerHaricSatisFiyat = vergilerHaricSatisFiyat;
    if (item.target.id === `varyantGrup_${name}_vergilerDahilSatisFiyat`) {
      yeniVergilerHaricSatisFiyat = parseFloat(
        vergilerDahilSatisFiyat /
          (satisKdvOran / 100 + 1) /
          (satisOtvOran / 100 + 1)
      ).toFixed(2);
    }
    const otvTutar = yuzdeHesala(satisOtvOran, yeniVergilerHaricSatisFiyat);
    const otvliTutar = parseFloat(yeniVergilerHaricSatisFiyat) + otvTutar;
    const kdvTutar = yuzdeHesala(otvliTutar, satisKdvOran);

    form.setFields([
      {
        name: ["varyantGrup", name, "satisKdvTutar"],
        value: kdvTutar,
      },
      {
        name: ["varyantGrup", name, "satisOtvTutar"],
        value: otvTutar,
      },
    ]);

    if (item.target.id === `varyantGrup_${name}_vergilerHaricSatisFiyat`) {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerDahilSatisFiyat"],
          value: vergiDahilFiyat,
        },
      ]);
    } else if (
      item.target.id === `varyantGrup_${name}_vergilerDahilSatisFiyat`
    ) {
      const vergiHaricFiyat = parseFloat(
        vergilerDahilSatisFiyat /
          (satisKdvOran / 100 + 1) /
          (satisOtvOran / 100 + 1)
      ).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerHaricSatisFiyat"],
          value: vergiHaricFiyat,
        },
      ]);
    } else {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerDahilSatisFiyat"],
          value: vergiDahilFiyat,
        },
      ]);
    }
  };

  const alisFiyatHesaplama = (item, name) => {
    const { varyantGrup } = form.getFieldsValue();
    const {
      vergilerHaricAlisFiyat,
      alisKdvOran,
      alisOtvOran,
      vergilerDahilAlisFiyat,
    } = varyantGrup[name];

    let yeniVergilerHaricAlisFiyat = vergilerHaricAlisFiyat;
    if (item.target.id === `varyantGrup_${name}_vergilerDahilAlisFiyat`) {
      yeniVergilerHaricAlisFiyat = parseFloat(
        vergilerDahilAlisFiyat /
          (alisKdvOran / 100 + 1) /
          (alisOtvOran / 100 + 1)
      ).toFixed(2);
    }
    const otvTutar = yuzdeHesala(alisOtvOran, yeniVergilerHaricAlisFiyat);
    const otvliTutar = parseFloat(yeniVergilerHaricAlisFiyat) + otvTutar;
    const kdvTutar = yuzdeHesala(otvliTutar, alisKdvOran);

    form.setFields([
      {
        name: ["varyantGrup", name, "alisKdvTutar"],
        value: kdvTutar,
      },
      {
        name: ["varyantGrup", name, "alisOtvTutar"],
        value: otvTutar,
      },
    ]);

    if (item.target.id === `varyantGrup_${name}_vergilerHaricAlisFiyat`) {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerDahilAlisFiyat"],
          value: vergiDahilFiyat,
        },
      ]);
    } else if (
      item.target.id === `varyantGrup_${name}_vergilerDahilAlisFiyat`
    ) {
      const vergiHaricFiyat = parseFloat(
        vergilerDahilAlisFiyat /
          (alisKdvOran / 100 + 1) /
          (alisOtvOran / 100 + 1)
      ).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerHaricAlisFiyat"],
          value: vergiHaricFiyat,
        },
      ]);
    } else {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerDahilAlisFiyat"],
          value: vergiDahilFiyat,
        },
      ]);
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <PageHeader
        onBack={() => history.goBack()}
        title={localization.baslik}
        ghost={false}
        extra={
          <Button
            type="primary"
            form="varyantliStokEkleForm"
            icon={<SaveFilled />}
            size="large"
            htmlType="submit"
            loading={fetchLoading}
          >
            {localization.kaydetButton}
          </Button>
        }
      />
      <Form
        form={form}
        onFinish={onFinish}
        size="middle"
        layout="vertical"
        id="varyantliStokEkleForm"
      >
        <Space size="large" style={{ width: "100%" }} direction="vertical">
          <Card>
            <Row gutter={24}>
              <Col flex={1}>
                <Form.Item
                  label={localization.urunAdi}
                  name="urunAdi"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <StokBirimSelectOption formItemName="stokBirimId" />
              </Col>
              <Col flex={1}>
                <StokKategoriSelectOption formItemName="stokKategoriId" />
                <Form.Item label={localization.aciklama} name="aciklama">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row>
              <div style={{ flex: 1 }}>
                <StokVaryantSelectOption
                  formItemName="selectStokVaryant"
                  formItemLabel={localization.stokVaryantlari}
                  required
                />
              </div>
              <Form.Item
                label=" "
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.selectStokVaryant !==
                  currentValues.selectStokVaryant
                }
              >
                {({ getFieldValue }) => {
                  const currentSelectStokVaryant =
                    getFieldValue("selectStokVaryant");
                  let disabled = true;
                  if (currentSelectStokVaryant !== undefined) {
                    if (currentSelectStokVaryant.length > 0) disabled = false;
                  }
                  return (
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={createVaryant}
                      disabled={disabled}
                    >
                      {localization.varyantOlusturButton}
                    </Button>
                  );
                }}
              </Form.Item>
            </Row>
          </Card>
          <Form.List name="varyantGrup">
            {(fields, { add, remove }) => (
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                size="large"
              >
                {fields.map(({ key, name, fieldKey }) => {
                  const { varyantGrup } = form.getFieldValue();
                  return (
                    <Card
                      title={varyantGrup[name].baslik}
                      extra={[
                        <Button
                          onClick={() => remove(name)}
                          icon={<DeleteOutlined />}
                        />,
                      ]}
                    >
                      <Row gutter={12}>
                        <Col flex={1}>
                          <Form.Item
                            name={[name, "barkod"]}
                            label={localization.varyantGrup.barkod}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col flex={1}>
                          <Form.Item
                            name={[name, "gtin"]}
                            label={localization.varyantGrup.gtin}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col flex={1}>
                          <Form.Item
                            name={[name, "kod"]}
                            label={localization.varyantGrup.kod}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col flex={1}>
                          <Form.Item
                            name={[name, "raf"]}
                            label={localization.varyantGrup.raf}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col flex={1}>
                          <Form.Item
                            name={[name, "aciklama"]}
                            label={localization.varyantGrup.aciklama}
                          >
                            <Input.TextArea rows={2} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={24}>
                        <Col flex={1}>
                          <CurrencyInput
                            formItemName={[name, "vergilerHaricAlisFiyat"]}
                            formItemLabel={
                              localization.varyantGrup.stokAlisFiyati
                                .vergilerHaricAlisFiyati
                            }
                            required
                            addonAfter={
                              <DovizSelectOption
                                size="middle"
                                formItemName={[name, "alisDovizId"]}
                                required
                                showKod
                                titleHidden
                                noStyle
                              />
                            }
                            onChange={(e) => alisFiyatHesaplama(e, name)}
                          />
                          <CurrencyInput
                            formItemName={[name, "alisOtvOran"]}
                            formItemLabel={
                              localization.varyantGrup.stokAlisFiyati
                                .alisOtvOran
                            }
                            required
                            onChange={(e) => alisFiyatHesaplama(e, name)}
                          />
                          <CurrencyInput
                            formItemName={[name, "alisKdvOran"]}
                            formItemLabel={
                              localization.varyantGrup.stokAlisFiyati
                                .alisKdvOran
                            }
                            required
                            onChange={(e) => alisFiyatHesaplama(e, name)}
                          />
                          <CurrencyInput
                            formItemName={[name, "vergilerDahilAlisFiyat"]}
                            formItemLabel={
                              localization.varyantGrup.stokAlisFiyati
                                .vergilerDahilAlisFiyati
                            }
                            required
                            addonAfter={
                              <DovizSelectOption
                                size="middle"
                                formItemName={[name, "alisDovizId"]}
                                required
                                showKod
                                titleHidden
                                noStyle
                              />
                            }
                            onChange={(e) => alisFiyatHesaplama(e, name)}
                          />
                        </Col>
                        <Col flex={1}>
                          <CurrencyInput
                            formItemName={[name, "vergilerHaricSatisFiyat"]}
                            formItemLabel={
                              localization.varyantGrup.stokSatisFiyati
                                .vergilerHaricSatisFiyati
                            }
                            required
                            addonAfter={
                              <DovizSelectOption
                                size="middle"
                                formItemName={[name, "satisDovizId"]}
                                required
                                showKod
                                titleHidden
                                noStyle
                              />
                            }
                            onChange={(e) => satisFiyatHesaplama(e, name)}
                          />
                          <CurrencyInput
                            formItemName={[name, "satisOtvOran"]}
                            formItemLabel={
                              localization.varyantGrup.stokSatisFiyati
                                .satisOtvOran
                            }
                            required
                            onChange={(e) => satisFiyatHesaplama(e, name)}
                          />
                          <CurrencyInput
                            formItemName={[name, "satisKdvOran"]}
                            formItemLabel={
                              localization.varyantGrup.stokSatisFiyati
                                .satisKdvOran
                            }
                            required
                            onChange={(e) => satisFiyatHesaplama(e, name)}
                          />
                          <CurrencyInput
                            formItemName={[name, "vergilerDahilSatisFiyat"]}
                            formItemLabel={
                              localization.varyantGrup.stokSatisFiyati
                                .vergilerDahilSatisFiyati
                            }
                            required
                            addonAfter={
                              <DovizSelectOption
                                size="middle"
                                formItemName={[name, "satisDovizId"]}
                                required
                                showKod
                                titleHidden
                                noStyle
                              />
                            }
                            onChange={(e) => satisFiyatHesaplama(e, name)}
                          />
                        </Col>
                      </Row>
                    </Card>
                  );
                })}
              </Space>
            )}
          </Form.List>
        </Space>
      </Form>
      <br />
    </Space>
  );
}

export default VaryantliStokEkle;
