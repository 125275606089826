import React, { useState } from "react";
import {
  Button,
  Space,
  Tag,
  Popconfirm,
  notification,
  message,
  Spin,
  Card,
  Form,
  Row,
  Col,
  Radio,
  InputNumber,
} from "antd";
import useCurrency from "../../hooks/useCurrency";
import strings from "../../utils/Localization";
import CursorTableItem from "../toolbox/CursorTableItem";
import {
  EditOutlined,
  DeleteOutlined,
  FileTextOutlined,
  SearchOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import FetchListContent from "../toolbox/FetchListContent";
import moment from "moment";
import { teklifSiparisTypes } from "../../types/teklifSiparisTypes";
import { api } from "../../helpers/ApiHelper";
import { setRerender } from "../../redux/actions/rerenderAction";
import { useDispatch } from "react-redux";
import DatepickerRanger from "../toolbox/DatepickerRanger";
import DovizSelectOption from "../toolbox/DovizSelectOption";

const initialFilterRequestData = {
  Data: {
    Tip: null,
    DovizId: null,
  },
  DateOptions: [
    {
      DatePropertyName: "TeklifSiparisTarihi",
      FirstDate: null,
      LastDate: null,
    },
  ],
  BetweenOptions: [
    {
      PropertyName: "GenelToplam",
      First: null,
      Last: null,
    },
  ],
};

function TeklifSiparisListeTable() {
  const [dataSource, setDataSource] = useState([]);
  const [filterRequestData, setFilterRequestData] = useState({
    ...initialFilterRequestData,
  });
  const [spinnig, setSpinnig] = useState(false);
  const { paraFormat } = useCurrency();
  const history = useHistory();
  const dispatch = useDispatch();
  const [detailSearchForm] = Form.useForm();
  const { teklifSiparisListeTable: localization, detayliArama } =
    strings.teklifSipariListe.components;

  const columns = [
    {
      title: localization.firmaAdi,
      dataIndex: "firmaAdi",
      key: "firmaAdi",
      render: (text, row, index) => (
        <CursorTableItem
          text={text}
          onClick={() => history.push(`/TeklifSiparisDuzenle/${row.id}`)}
        />
      ),
    },
    {
      title: localization.evrakNo,
      dataIndex: "teklifSiparisNo",
      key: "teklifSiparisNo",
    },
    {
      title: localization.evrakTipi,
      dataIndex: "tip",
      key: "tip",
      filters: [
        {
          text: localization.evrakTip.teklif,
          value: teklifSiparisTypes.TEKLIF,
        },
        {
          text: localization.evrakTip.siparis,
          value: teklifSiparisTypes.SIPARIS,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.tip.indexOf(value) === 0,
      render: (text) => (
        <Tag color={getTeklifSiparisTipColor(text)} style={{ fontSize: 14 }}>
          {text}
        </Tag>
      ),
    },
    {
      title: localization.evrakTarihi,
      dataIndex: "teklifSiparisTarihi",
      key: "teklifSiparisTarihi",
      render: (text) => moment(text).format("DD.MM.YYYY"),
    },
    {
      title: localization.genelToplam,
      dataIndex: "genelToplam",
      key: "genelToplam",
      render: (text, row, index) => `${row.Doviz.simge}${paraFormat(text)}`,
    },
    {
      title: localization.islemler,
      dataIndex: "islemler",
      key: "islemler",
      render: (text, row, index) => (
        <Space size="middle">
          <Button
            onClick={() => fetchTeklifSiparisMabuz(row.id, row.tip)}
            type="default"
            shape="circle"
            icon={<FileTextOutlined />}
            size={"large"}
          />
          <Button
            onClick={() => history.push(`/TeklifSiparisDuzenle/${row.id}`)}
            type="default"
            shape="circle"
            icon={<EditOutlined />}
            size={"large"}
          />
          <Popconfirm
            title={strings.mesaj.silmeIslemUyari}
            onConfirm={() => remove(row.id)}
          >
            <Button
              type="default"
              shape="circle"
              icon={<DeleteOutlined />}
              size={"large"}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getTeklifSiparisTipColor = (tip) => {
    switch (tip) {
      case teklifSiparisTypes.TEKLIF:
        return "cyan";
      case teklifSiparisTypes.SIPARIS:
        return "magenta";
      default:
        return "geekblue";
    }
  };

  const remove = async (teklifSiparisId) => {
    try {
      const response = await api.delete(
        `/teklifsiparis/${teklifSiparisId}`,
        {}
      );
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== teklifSiparisId),
        });
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const fetchTeklifSiparisMabuz = async (teklifSiparisId, teklifSiparisTip) => {
    try {
      setSpinnig(true);
      const response = await api.post(
        `/rapordosya/${
          teklifSiparisTip === teklifSiparisTypes.TEKLIF
            ? "teklifmakbuz"
            : "siparismakbuz"
        }`,
        teklifSiparisTip === teklifSiparisTypes.TEKLIF
          ? { TeklifId: teklifSiparisId, format: "pdf" }
          : { SiparisId: teklifSiparisId, format: "pdf" }
      );

      if (response.status === 200) {
        window.open(response.data, "_blank");
      } else message.warning(strings.mesaj.islemBasarisiz);
    } catch (error) {
      message.error(error.message);
    } finally {
      setSpinnig(false);
    }
  };
  const detayliAramaOnFinish = async (props) => {
    const { islemTarihi, evrakTipi, minTutar, maxTutar, dovizId } = props;

    let newData = { ...initialFilterRequestData };

    newData = {
      Data: {
        DovizId: dovizId !== undefined ? dovizId : null,
        Tip: evrakTipi !== undefined ? evrakTipi : null,
      },
      DateOptions: [
        {
          DatePropertyName: "TeklifSiparisTarihi",
          FirstDate:
            islemTarihi !== undefined
              ? `${moment(islemTarihi[0]).format("YYYY-MM-DD")} 00:00:00`
              : null,
          LastDate:
            islemTarihi !== undefined
              ? `${moment(islemTarihi[1]).format("YYYY-MM-DD")} 23:59:59`
              : null,
        },
      ],
      BetweenOptions: [
        {
          PropertyName: "GenelToplam",
          First: minTutar !== undefined ? minTutar : null,
          Last: maxTutar !== undefined ? maxTutar : null,
        },
      ],
    };
    await setFilterRequestData(newData);
    dispatch(setRerender());
  };
  const detayliAramaReset = async () => {
    await setFilterRequestData({ ...initialFilterRequestData });
    detailSearchForm.resetFields();

    dispatch(setRerender());
  };
  return (
    <Spin spinning={spinnig}>
      <FetchListContent
        detailSearch={
          <Card title={detayliArama.baslik}>
            <Form
              form={detailSearchForm}
              name="detailSearchForm"
              layout="vertical"
              onFinish={detayliAramaOnFinish}
              initialValues={{
                evrakTipi: null,
              }}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <DatepickerRanger
                    formName="islemTarihi"
                    formLabel={detayliArama.islemTarihi}
                  />
                </Col>
                <Col span={8}>
                  <Space
                    direction="vertical"
                    align="center"
                    style={{ width: "100%" }}
                  >
                    <Form.Item name="evrakTipi" label={detayliArama.evrakTipi}>
                      <Radio.Group>
                        <Space direction="vertical">
                          <Radio value={null}>
                            {detayliArama.tumEvraklariGoster}
                          </Radio>
                          <Radio value={teklifSiparisTypes.TEKLIF}>
                            {detayliArama.teklifleriGoster}
                          </Radio>
                          <Radio value={teklifSiparisTypes.SIPARIS}>
                            {detayliArama.siparisleriGoster}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space direction="vertical">
                    <Space size="small">
                      <Form.Item label={detayliArama.minTutar} name="minTutar">
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                      <DovizSelectOption
                        formItemLabel={detayliArama.doviz}
                        formItemName="dovizId"
                      />
                    </Space>
                    <Space size="small">
                      <Form.Item label={detayliArama.maxTutar} name="maxTutar">
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                      <DovizSelectOption
                        formItemLabel={detayliArama.doviz}
                        formItemName="dovizId"
                      />
                    </Space>
                  </Space>
                </Col>
              </Row>
              <Space align="baseline" style={{ float: "right" }}>
                <Button
                  type="default"
                  size="middle"
                  icon={<ClearOutlined />}
                  style={{ float: "right" }}
                  onClick={detayliAramaReset}
                >
                  {detayliArama.temizle}
                </Button>
                <Button
                  type="default"
                  size="middle"
                  icon={<SearchOutlined />}
                  style={{ float: "right" }}
                  form="detailSearchForm"
                  htmlType="submit"
                >
                  {detayliArama.aramayiBaslat}
                </Button>
              </Space>
            </Form>
          </Card>
        }
        columns={columns}
        requestUrl="/teklifsiparis/get"
        requestBody={{
          SelectColumns: [
            {
              ColumnNames: [
                "id",
                "firmaAdi",
                "teklifSiparisNo ",
                "tip",
                "teklifSiparisTarihi",
                "genelToplam",
              ],
              TableName: "",
            },
            {
              TableName: "Doviz",
              ColumnNames: ["id", "ad", "simge"],
            },
          ],
          ...filterRequestData,
        }}
        dataSource={dataSource}
        onDataSource={(e) => setDataSource(e)}
      />
    </Spin>
  );
}

export default TeklifSiparisListeTable;
