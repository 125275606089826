import React, { useState } from "react";
import { PageHeader as MyPageHeader, Button } from "antd";
import { useHistory } from "react-router-dom";
import strings from "../../utils/Localization";
import { PlusOutlined } from "@ant-design/icons";
import BankaHesapEkleModal from "../bankaHesap/BankaHesapEkleModal";

export default function PageHeader() {
  const [bankaHesapEkleModalVisible, setBankaHesapEkleModalVisible] =
    useState(false);
  const history = useHistory();
  const { pageHeader } = strings.bankaHesapListe.components;

  return (
    <>
      <MyPageHeader
        ghost={false}
        onBack={() => history.goBack()}
        title={pageHeader.baslik}
        subTitle={pageHeader.altBaslik}
        extra={[
          <Button
            onClick={() => setBankaHesapEkleModalVisible(true)}
            type="primary"
            size="large"
            icon={<PlusOutlined />}
          >
            {pageHeader.bankaHesapEkleButon}
          </Button>,
        ]}
      />
      <BankaHesapEkleModal
        visible={bankaHesapEkleModalVisible}
        handleCancel={() => setBankaHesapEkleModalVisible(false)}
      />
    </>
  );
}
