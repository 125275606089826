import React, { useEffect, useState } from "react";
import { api } from "../../helpers/ApiHelper";
import { message, Select, Form } from "antd";
import strings from "../../utils/Localization";

const { Option } = Select;

function CariGrupSelectOption({ formItemName }) {
  const [cariGrupData, setCariGrupData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { cariGrupPlaceHolder, cariGrupBaslik } = strings.cariGrupSelectOption;

  useEffect(() => {
    fetchCariGrup();
  }, []);

  const fetchCariGrup = async () => {
    try {
      setFetchLoading(true);

      const response = await api.post("/carigrup/get", {});
      if (response.status === 200) {
        setCariGrupData(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Form.Item
      name={formItemName}
      label={cariGrupBaslik}
      rules={[{ required: true }]}
    >
      <Select
        placeholder={cariGrupPlaceHolder}
        allowClear
        loading={fetchLoading}
      >
        {cariGrupData.map((item, index) => (
          <Option key={index} value={String(item.id)}>
            {item.ad}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default CariGrupSelectOption;
