import React, { useEffect, useState } from "react";
import { Form, message, Select } from "antd";
import strings from "../../utils/Localization";
import { api } from "../../helpers/ApiHelper";

const { Option } = Select;

function StokVaryantGrupSelectOption({
  formItemName,
  formItemLabel,
  required,
  placeholder,
  hidden,
  rules,
  stokId = null,
  onChange = () => {},
  disabled,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [stokVaryantGrupData, setStokVaryantGrupData] = useState([]);
  useEffect(() => {
    fetchStokVaryantGrup();
  }, [stokId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStokVaryantGrup = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/stokvaryantgrup/get", {
        Data: {
          StokId: stokId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["id", "barkod"],
          },
          {
            TableName: "StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName:
              "StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger.StokVaryant",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName: "Stokvaryantgrupfiyat",
            ColumnNames: [
              "id",
              "fiyat",
              "otvTutar",
              "otvOran",
              "oivTutar",
              "oivOran",
              "kdvTutar",
              "kdvOran",
              "genelToplam",
              "satisMi",
            ],
          },
          {
            TableName: "Stokvaryantgrupfiyat.Doviz",
            ColumnNames: ["Id", "Ad", "Kod", "Simge"],
          },
        ],
      });
      if (response.status === 200) {
        setStokVaryantGrupData(
          response.data.data.map((item) => {
            return {
              label: item.StokvaryantgrupHasStokvaryantdeger.map(
                (varyantGrupItem) => {
                  return varyantGrupItem.StokVaryantDeger.ad;
                }
              ).join(" "),
              value: item.id,
              barkod: item.barkod,
              stokvaryantgrupfiyat: item.Stokvaryantgrupfiyat,
            };
          })
        );
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Form.Item
      name={formItemName}
      label={formItemLabel}
      rules={rules ? rules : [{ required: required }]}
      hidden={hidden}
    >
      <Select
        placeholder={
          placeholder || strings.stokVaryantGrupSelectOption.placeHolder
        }
        loading={fetchLoading}
        onChange={(e) =>
          onChange(stokVaryantGrupData.find((x) => x.value === parseInt(e)))
        }
        disabled={disabled}
      >
        {stokVaryantGrupData.map((item, index) => (
          <Option key={index} value={String(item.value)}>
            {`${item.label}`}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default StokVaryantGrupSelectOption;
