import React, { useState, useEffect } from "react";
import { Modal, Form, message, notification } from "antd";
import strings from "../../utils/Localization";
import TahsilatOdemeForm from "../cariIslem/TahsilatOdemeForm";
import { cariIslemTurTypes } from "../../types/cariIslemTurTypes";
import { useParams } from "react-router-dom";
import { api } from "../../helpers/ApiHelper";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useEvrakNo from "../../hooks/useEvrakNo";

export default function CariOdemeEkleModal({ visible = false, handleCancel }) {
  const [form] = Form.useForm();
  const [fetchLoading, setFetchLoading] = useState(false);
  const { cariKartId } = useParams();
  const { odemeEkle } = strings.cariIslem;
  const dispatch = useDispatch();
  const { getCariIslemEvrakNo } = useEvrakNo();

  useEffect(() => {
    getCariIslemEvrakNo().then((evrakNo) => {
      form.setFieldsValue({ evrakNo: evrakNo });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        islemTarihi,
        evrakNo,
        aciklama,
        dovizId,
        odemeSekli,
        kasaId,
        bankaHesapId,
        tutar,
      } = values;
      setFetchLoading(true);
      const response = await api.post("/cariislem/add", {
        CariKartId: cariKartId,
        DovizId: dovizId,
        BankaHesapId: bankaHesapId,
        KasaId: kasaId,
        CariIslemTur: cariIslemTurTypes.ODEME,
        EvrakNo: evrakNo,
        Aciklama: aciklama,
        Carpan: 1,
        Tutar: tutar,
        VadeTarih: islemTarihi,
        IslemTarih: islemTarihi,
        OdemeSekli: odemeSekli,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        getCariIslemEvrakNo().then((evrakNo) => {
          form.setFieldsValue({ evrakNo: evrakNo });
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={odemeEkle.baslik}
      visible={visible}
      onCancel={handleCancel}
      width={"60%"}
      okButtonProps={{
        size: "large",
        form: "odemeEkleForm",
        htmlType: "submit",
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: "large" }}
      centered
    >
      <TahsilatOdemeForm form={form} id="odemeEkleForm" onFinish={onFinish} />
    </Modal>
  );
}
