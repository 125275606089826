import React, { useState } from "react";
import { Card, Button, Space, Popconfirm, message, notification } from "antd";
import strings from "../../utils/Localization";
import FetchListContent from "../toolbox/FetchListContent";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { api } from "../../helpers/ApiHelper";
import { PlusOutlined } from "@ant-design/icons";
import CariGrupEkleModal from "../cariGrup/CariGrupEkleModal";
import CariGrupDuzenleModal from "../cariGrup/CariGrupDuzenleModal";
import CursorTableItem from "../toolbox/CursorTableItem";

const CariGrupListe = () => {
  const [dataSource, setDataSource] = useState({});
  const [cariGrupEkleModalVisible, setCariGrupEkleModalVisible] =
    useState(false);
  const [cariGrupDuzenleModalVisible, setCariGrupDuzenleModalVisible] =
    useState(false);
  const [duzenleData, setDuzenleData] = useState({});
  const { cariGrupListe: localization } = strings;

  const columns = [
    {
      title: localization.grupAdi,
      dataIndex: "ad",
      key: "ad",
      sorter: (a, b) => a.ad.length - b.ad.length,
      render: (text, row, index) => (
        <CursorTableItem text={`${row.ad} (${row.cariKartSayisi})`} />
      ),
    },
    {
      title: localization.islemler,
      key: "edit",
      dataIndex: "edit",
      render: (text, row, index) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setDuzenleData(row);
              setCariGrupDuzenleModalVisible(true);
            }}
            type="default"
            shape="circle"
            icon={<EditOutlined />}
            size={"large"}
          />
          <Popconfirm
            title={strings.mesaj.silmeIslemUyari}
            onConfirm={() => {
              if (row.cariKartSayisi > 0) {
                message.error({
                  content: strings.mesaj.cariGrupSilme,
                  style: { marginTop: "20vh" },
                });
              } else remove(row.id);
            }}
          >
            <Button
              type="default"
              shape="circle"
              icon={<DeleteOutlined />}
              size={"large"}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const remove = async (cariGrupId) => {
    try {
      const response = await api.delete(`/carigrup/${cariGrupId}`, {});
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== cariGrupId),
        });
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  return (
    <>
      <Card
        title={localization.baslik}
        extra={[
          <Button
            key="1"
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => setCariGrupEkleModalVisible(true)}
          >
            {localization.cariGrupEkleButon}
          </Button>,
        ]}
      >
        <FetchListContent
          columns={columns}
          requestUrl="carigrup/get"
          deleteUrl="carigrup/"
          dataSource={dataSource}
          onDataSource={(e) => setDataSource(e)}
          requestBody={{
            SelectColumns: [
              {
                TableName: "",
                ColumnNames: ["ad", "id"],
              },
              {
                TableName: "Carikart:cariKartSayisi",
                Operator: "Count",
              },
            ],
          }}
        />
      </Card>
      <CariGrupEkleModal
        visible={cariGrupEkleModalVisible}
        handleCancel={() => setCariGrupEkleModalVisible(false)}
      />
      <CariGrupDuzenleModal
        visible={cariGrupDuzenleModalVisible}
        handleCancel={() => setCariGrupDuzenleModalVisible(false)}
        data={duzenleData}
      />
    </>
  );
};

export default CariGrupListe;
