import React, { useState } from "react";
import { Modal, Form, Input, message, notification } from "antd";
import { api } from "../../helpers/ApiHelper";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import strings from "../../utils/Localization";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";

const formSize = "middle";

function KasaEkleModal({ visible = false, handleCancel }) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { kasaEkleModal: localization } = strings.kasa;

  const onFinish = async (values) => {
    try {
      setFetchLoading(true);
      const { kasaAdi, dovizId } = values;

      const response = await api.post("/kasa", {
        Ad: kasaAdi,
        DovizId: dovizId,
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "kasaEkleModal",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
    >
      <Form
        name="kasaEkleModal"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size={formSize}
      >
        <Form.Item
          name="kasaAdi"
          label={localization.kasaAdi}
          rules={[{ required: true }]}
        >
          <Input placeholder={localization.kasaAdiPlaceHolder} />
        </Form.Item>
        <DovizSelectOption formItemName="dovizId" required />
      </Form>
    </Modal>
  );
}

export default KasaEkleModal;
