import React, { useState, useEffect } from "react";
import { Form, message, notification } from "antd";
import { useLocation } from "react-router-dom";
import { TeklifSiparisLayout } from "../components/teklifSiparisEkle";
import { teklifSiparisTypes } from "../types/teklifSiparisTypes";
import strings from "../utils/Localization";
import { api } from "../helpers/ApiHelper";
import useEvrakNo from "../hooks/useEvrakNo";
import { useHistory } from "react-router-dom";

function TeklifSiparisEkle() {
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = Form.useForm();
  const { state = { teklifSiparisTip: teklifSiparisTypes.TEKLIF } } =
    useLocation();
  const { teklifSiparisEkle: localization } = strings;
  const { getTeklifEvrakNo, getSiparisEvrakNo } = useEvrakNo();
  const history = useHistory();

  useEffect(() => {
    form.setFieldsValue({ teklifSiparisTip: state.teklifSiparisTip });
    if (state.teklifSiparisTip === teklifSiparisTypes.TEKLIF) {
      getTeklifEvrakNo().then((evrakNo) => {
        form.setFieldsValue({ evrakNo: evrakNo });
      });
    } else if (state.teklifSiparisTip === teklifSiparisTypes.SIPARIS) {
      getSiparisEvrakNo().then((evrakNo) => {
        form.setFieldsValue({ evrakNo: evrakNo });
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        teklifSiparisTip,
        cariKartId,
        dovizId,
        evrakNo,
        duzenlemeTarihi,
        gecerlilikTarihi,
        aciklama,
        stopajOran,
        stopajTutar,
        tevkifatOran,
        tevkifatTutar,
        toplamSatirIskontoTutar,
        araToplamTutar,
        genelIskontoOran,
        genelIskontoTutar,
        genelIskontoTip,
        genelToplamTutar,
        teklifSiparisSatir = [
          {
            aciklama: "",
            stokId: null,
            varyantId: null,
            miktar: 1,
            birimFiyat: 0,
            kdvOran: 0,
            kdvTutar: 0,
            otvOran: 0,
            otvTutar: 0,
            toplam: 0,
            iskontoOran: 0,
            iskontoTip: "yuzde",
            iskontoTutar: 0,
          },
        ],
        firmaAdi,
        yetkili,
        vergiDairesi,
        vergiNumarasi,
        telefon,
        cepTelefonu,
        eMail,
      } = values;
      let requestData = {
        CariKartId: cariKartId,
        DovizId: dovizId,
        Tip: teklifSiparisTip,
        FirmaAdi: firmaAdi,
        Yetkili: yetkili,
        VergIdairesi: vergiDairesi,
        VergiNumarasi: vergiNumarasi,
        Telefon: telefon,
        CepTelefonu: cepTelefonu,
        EMail: eMail,
        TeklifSiparisNo: evrakNo,
        TeklifSiparisTarihi: duzenlemeTarihi,
        GecerlilikTarihi: gecerlilikTarihi,
        Aciklama: aciklama,
        StopajOran: stopajOran,
        StopajTutar: stopajTutar,
        TevkifatOran: tevkifatOran,
        TevkifatTutar: tevkifatTutar,
        Toplam: araToplamTutar,
        SatirIskontoToplamTutar: toplamSatirIskontoTutar,
        AraToplamIskontoOran: genelIskontoOran,
        AraToplamIskontoTutar: genelIskontoTutar,
        AraToplamIskontoTip: genelIskontoTip === "yuzde" ? "Oran" : "Tutar",
        GenelToplam: genelToplamTutar,
        Teklifsiparissatir: teklifSiparisSatir.map((item) => ({
          StokVaryantGrupId: item.stokVaryantGrupId,
          StokBirimId: item.stokBirimId,
          UrunAdi: item.urunAdi,
          Miktar: item.miktar,
          BirimFiyat: item.birimFiyat,
          Barkod: item.barkod,
          Aciklama: item.aciklama,
          OtvTutar: item.otvTutar,
          OtvOran: item.otvOran,
          GenelToplam: item.toplam,
          // OivTutar: 20,
          // OivOran: 25,
          KdvTutar: item.kdvTutar,
          KdvOran: item.kdvOran,
          Teklifsiparissatiriskonto: [
            {
              Oran: item.iskontoOran,
              Tutar: item.iskontoTutar,
              IskontoTip: item.iskontoTip === "yuzde" ? "Oran" : "Tutar",
            },
          ],
        })),
      };

      setSaveLoading(true);
      const response = await api.post("/teklifSiparis", requestData);
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        history.goBack();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <>
      <TeklifSiparisLayout
        form={form}
        title={state.teklifSiparisTip}
        saveButtonTitle={localization.kaydetButon}
        saveButtonLoading={saveLoading}
        onFinish={onFinish}
      />
    </>
  );
}

export default TeklifSiparisEkle;
