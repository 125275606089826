import React, { useEffect, useState } from "react";
import { Card, Space, Form, message } from "antd";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import strings from "../../utils/Localization";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";
import DatepickerRanger from "../toolbox/DatepickerRanger";
import moment from "moment";
import { api } from "../../helpers/ApiHelper";

export default function TahsilatOdemeBarChart() {
  const [form] = Form.useForm();
  const { getDefaultSetting } = useVarsayilanDeger();
  const defaultDovizId = String(getDefaultSetting("doviz"));
  const [chartData, setChartData] = useState({});
  const [fetchLoading, setFetchLoading] = useState(true);

  useEffect(() => {
    form.setFields([
      {
        name: "tarih",
        value: [moment().add(-7, "days"), moment()],
      },
      {
        name: "dovizId",
        value: defaultDovizId,
      },
    ]);
    onValuesChange();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onValuesChange = async (values) => {
    try {
      const baslangicTarih = moment(form.getFieldValue("tarih")[0]).format(
        "YYYY-MM-DD"
      );
      const bitisTarihi = moment(form.getFieldValue("tarih")[1]).format(
        "YYYY-MM-DD"
      );
      const dovizId = form.getFieldValue("dovizId");

      const response = await api.post("/cariislem/gunluktahsilatodeme", {
        BaslangicTarih: baslangicTarih,
        BitisTarih: bitisTarihi,
        DovizId: dovizId,
      });

      if (response.status === 200) {
        let data = [];
        for (
          var m = moment(baslangicTarih);
          m.diff(bitisTarihi, "days") <= 0;
          m.add(1, "days")
        ) {
          const currentDate = moment(m);

          const tahsilat = response.data.data.tahsilat.find(
            (x) =>
              moment(x.tarih).format("DD-MM-YYYY") ===
              currentDate.format("DD-MM-YYYY")
          );
          const odeme = response.data.data.odeme.find(
            (x) =>
              moment(x.tarih).format("DD-MM-YYYY") ===
              currentDate.format("DD-MM-YYYY")
          );

          data.push({
            Tarih: m.format("DD-MM-YYYY"),
            MomentTarih: m.format("YYYY-MM-DD"),
            Tahsilat: tahsilat !== undefined ? tahsilat.tutar : 0,
            Ödeme: odeme !== undefined ? odeme.tutar : 0,
          });
        }

        setChartData(data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Card
      title={strings.panel.components.tahsilatOdemeBarChart.baslik}
      extra={
        <Form form={form} onValuesChange={onValuesChange}>
          <Space>
            <DatepickerRanger formName="tarih" size="middle" />
            <DovizSelectOption formItemName="dovizId" titleHidden  size="middle"/>
          </Space>
        </Form>
      }
      loading={fetchLoading}
    >
      <ResponsiveContainer width={"100%"} height={350}>
        <BarChart
          data={chartData}
          style={{ width: "100%" }}
          //  onClick={(e) => console.log(e)}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Tarih" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Ödeme" fill="#ee7a6b" />
          <Bar dataKey="Tahsilat" fill="#3f78b2" />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
}
