import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  PageHeader,
  Row,
  Space,
  Form,
  Input,
  Divider,
  message,
  notification,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import StokVaryantSelectOption from "../components/toolbox/StokVaryantSelectOption";
import useVarsayilanDeger from "../hooks/useVarsayilanDeger";
import { yuzdeHesala } from "../helpers/HesaplamaHelper";
import strings from "../utils/Localization";
import CurrencyInput from "../components/toolbox/CurrencyInput";
import DovizSelectOption from "../components/toolbox/DovizSelectOption";
import { api } from "../helpers/ApiHelper";
import { useSelector } from "react-redux";

const formSize = "middle";

function StokVaryantGrupEkle() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const { getDefaultSetting } = useVarsayilanDeger();
  const history = useHistory();
  const { stokId } = useParams();
  const { stokVaryantGrupEkle: localization } = strings;
  const selectedStokReducer = useSelector((state) => state.selectedStokReducer);
 

  const onFinish = async (values) => {
    try {
      const { varyantGrup } = values;
      const varyantGrupValues = varyantGrup[0];
      setFetchLoading(true);
      const response = await api.post("/stokvaryantgrup", {
        StokId: stokId,
        Barkod: varyantGrupValues.barkod,
        Aciklama: varyantGrupValues.aciklama,
        Kod: varyantGrupValues.kod,
        Gtin: varyantGrupValues.gtin,
        Raf: varyantGrupValues.raf,
        ResimUrl: "",
        resimAd: new Date().valueOf(),
        StokvaryantgrupHasStokvaryantdeger: Array.isArray(
          varyantGrupValues.varyant
        )
          ? varyantGrupValues.varyant.map((varyantItem) => {
              return { StokVaryantDegerId: varyantItem.Id };
            })
          : [{ StokVaryantDegerId: varyantGrupValues.varyant.Id }],
        Stokvaryantgrupfiyat: [
          {
            DovizId: varyantGrupValues.satisDovizId,
            OtvTutar: varyantGrupValues.satisOtvTutar,
            OtvOran: varyantGrupValues.satisOtvOran,
            KdvTutar: varyantGrupValues.satisKdvTutar,
            KdvOran: varyantGrupValues.satisKdvOran,
            Fiyat: varyantGrupValues.vergilerHaricSatisFiyat,
            GenelToplam: varyantGrupValues.vergilerDahilSatisFiyat,
            SatisMi: 1,
          },
          {
            DovizId: varyantGrupValues.alisDovizId,
            OtvTutar: varyantGrupValues.alisOtvTutar,
            OtvOran: varyantGrupValues.alisOtvOran,
            KdvTutar: varyantGrupValues.alisKdvTutar,
            KdvOran: varyantGrupValues.alisKdvOran,
            Fiyat: varyantGrupValues.vergilerHaricAlisFiyat,
            GenelToplam: varyantGrupValues.vergilerDahilAlisFiyat,
            SatisMi: 0,
          },
        ],
      });
      if (response.status === 200) {
        notification["success"]({
          description: strings.mesaj.islemBasarili,
          message: strings.mesaj.basarliBaslik,
        });
        form.resetFields();
        form.setFieldsValue({ varyantGrup: [] });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const createVaryant = () => {
    const { selectStokVaryant } = form.getFieldValue();

    const jsonData = selectStokVaryant.map((item) => {
      return JSON.parse(item);
    });

    const seciliVaryantIdArray = jsonData
      .map((item) => item.StokVaryantId)
      .filter((v, i, a) => a.indexOf(v) === i);

    let varyantGrupArray = [];
    seciliVaryantIdArray.map((seciliVaryantId) => {
      let data = [];
      jsonData
        .filter((x) => x.StokVaryantId === seciliVaryantId)
        .map((i) => {
          return data.push(i);
        });
      return varyantGrupArray.push(data);
    });
    
    let durum = true;
    varyantGrupArray.map((item) => {
      if (item.length > 1) {
        durum = false;
        return message.warning(localization.varyantTurUyari);
      }
      return null
    });

    if (durum === false) return null;
    var result = varyantGrupArray.reduce((a, b) =>
      a.reduce((r, v) => r.concat(b.map((w) => [].concat(v, w))), [])
    );

    form.setFields([
      {
        name: "varyantGrup",
        value: result.map((item) => ({
          baslik: Array.isArray(item) ? item.map((i) => i.Ad + " - ") : item.Ad,
          varyant: item,
          barkod: "",
          gtin: "",
          kod: "",
          raf: "",
          aciklama: "",
          vergilerHaricAlisFiyat: 0,
          alisDovizId: String(getDefaultSetting("doviz")),
          alisKdvOran: 0,
          alisKdvTutar: 0,
          alisOtvOran: 0,
          alisOtvTutar: 0,
          vergilerDahilAlisFiyat: 0,
          vergilerHaricSatisFiyat: 0,
          satisDovizId: String(getDefaultSetting("doviz")),
          satisKdvOran: 0,
          satisKdvTutar: 0,
          satisOtvOran: 0,
          satisOtvTutar: 0,
          vergilerDahilSatisFiyat: 0,
        })),
      },
    ]);
  };

  const satisFiyatHesaplama = (item, name) => {
    const { varyantGrup } = form.getFieldsValue();
    const {
      vergilerHaricSatisFiyat,
      satisKdvOran,
      satisOtvOran,
      vergilerDahilSatisFiyat,
    } = varyantGrup[name];

    let yeniVergilerHaricSatisFiyat = vergilerHaricSatisFiyat;
    if (item.target.id === `varyantGrup_${name}_vergilerDahilSatisFiyat`) {
      yeniVergilerHaricSatisFiyat = parseFloat(
        vergilerDahilSatisFiyat /
          (satisKdvOran / 100 + 1) /
          (satisOtvOran / 100 + 1)
      ).toFixed(2);
    }
    const otvTutar = yuzdeHesala(satisOtvOran, yeniVergilerHaricSatisFiyat);
    const otvliTutar = parseFloat(yeniVergilerHaricSatisFiyat) + otvTutar;
    const kdvTutar = yuzdeHesala(otvliTutar, satisKdvOran);

    form.setFields([
      {
        name: ["varyantGrup", name, "satisKdvTutar"],
        value: kdvTutar,
      },
      {
        name: ["varyantGrup", name, "satisOtvTutar"],
        value: otvTutar,
      },
    ]);

    if (item.target.id === `varyantGrup_${name}_vergilerHaricSatisFiyat`) {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerDahilSatisFiyat"],
          value: vergiDahilFiyat,
        },
      ]);
    } else if (
      item.target.id === `varyantGrup_${name}_vergilerDahilSatisFiyat`
    ) {
      const vergiHaricFiyat = parseFloat(
        vergilerDahilSatisFiyat /
          (satisKdvOran / 100 + 1) /
          (satisOtvOran / 100 + 1)
      ).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerHaricSatisFiyat"],
          value: vergiHaricFiyat,
        },
      ]);
    } else {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerDahilSatisFiyat"],
          value: vergiDahilFiyat,
        },
      ]);
    }
  };

  const alisFiyatHesaplama = (item, name) => {
    const { varyantGrup } = form.getFieldsValue();
    const {
      vergilerHaricAlisFiyat,
      alisKdvOran,
      alisOtvOran,
      vergilerDahilAlisFiyat,
    } = varyantGrup[name];

    let yeniVergilerHaricAlisFiyat = vergilerHaricAlisFiyat;
    if (item.target.id === `varyantGrup_${name}_vergilerDahilAlisFiyat`) {
      yeniVergilerHaricAlisFiyat = parseFloat(
        vergilerDahilAlisFiyat /
          (alisKdvOran / 100 + 1) /
          (alisOtvOran / 100 + 1)
      ).toFixed(2);
    }
    const otvTutar = yuzdeHesala(alisOtvOran, yeniVergilerHaricAlisFiyat);
    const otvliTutar = parseFloat(yeniVergilerHaricAlisFiyat) + otvTutar;
    const kdvTutar = yuzdeHesala(otvliTutar, alisKdvOran);

    form.setFields([
      {
        name: ["varyantGrup", name, "alisKdvTutar"],
        value: kdvTutar,
      },
      {
        name: ["varyantGrup", name, "alisOtvTutar"],
        value: otvTutar,
      },
    ]);

    if (item.target.id === `varyantGrup_${name}_vergilerHaricAlisFiyat`) {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerDahilAlisFiyat"],
          value: vergiDahilFiyat,
        },
      ]);
    } else if (
      item.target.id === `varyantGrup_${name}_vergilerDahilAlisFiyat`
    ) {
      const vergiHaricFiyat = parseFloat(
        vergilerDahilAlisFiyat /
          (alisKdvOran / 100 + 1) /
          (alisOtvOran / 100 + 1)
      ).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerHaricAlisFiyat"],
          value: vergiHaricFiyat,
        },
      ]);
    } else {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        {
          name: ["varyantGrup", name, "vergilerDahilAlisFiyat"],
          value: vergiDahilFiyat,
        },
      ]);
    }
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical" size="large">
      <PageHeader
        onBack={() => history.goBack()}
        title={localization.pageHeader.baslik}
        ghost={false}
        extra={
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            form="varyantGrupEkle"
            loading={fetchLoading}
          >
            {localization.pageHeader.kaydet}
          </Button>
        }
      />
      <Form
        id="varyantGrupEkle"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size={formSize}
      >
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card>
            <Row gutter={12}>
              <Col span={16}>
                <StokVaryantSelectOption
                  formItemName="selectStokVaryant"
                  formItemLabel={localization.varyantGrupSeciniz}
                  requestBody={{ Id: selectedStokReducer.varyantId }}
                />
              </Col>
              <Col span={8}>
                <Form.Item label=" ">
                  <Button type="primary" onClick={createVaryant}>
                   {localization.varyantGrupOlustur}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        <Form.List name="varyantGrup" >
          {(fields, { add, remove }) => (
            <Space direction="vertical" style={{ width: "100%" }} size="large">
              {fields.map(({ key, name, fieldKey }) => {
                const { varyantGrup } = form.getFieldValue();
                return (
                  <Card title={varyantGrup[name].baslik}>
                    <Row gutter={12}>
                      <Col flex={1}>
                        <Form.Item
                          name={[name, "barkod"]}
                          label={localization.varyantGrup.barkod}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name={[name, "gtin"]}
                          label={localization.varyantGrup.gtin}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name={[name, "kod"]}
                          label={localization.varyantGrup.kod}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name={[name, "raf"]}
                          label={localization.varyantGrup.raf}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name={[name, "aciklama"]}
                          label={localization.varyantGrup.aciklama}
                        >
                          <Input.TextArea rows={2} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row gutter={24}>
                      <Col flex={1}>
                        <CurrencyInput
                          formItemName={[name, "vergilerHaricAlisFiyat"]}
                          formItemLabel={
                            localization.varyantGrup.stokAlisFiyati
                              .vergilerHaricAlisFiyati
                          }
                          required
                          addonAfter={
                            <DovizSelectOption
                              size="middle"
                              formItemName={[name, "alisDovizId"]}
                              required
                              showKod
                              titleHidden
                              noStyle
                            />
                          }
                          onChange={(e) => alisFiyatHesaplama(e, name)}
                        />
                        <CurrencyInput
                          formItemName={[name, "alisOtvOran"]}
                          formItemLabel={
                            localization.varyantGrup.stokAlisFiyati.alisOtvOran
                          }
                          required
                          onChange={(e) => alisFiyatHesaplama(e, name)}
                        />
                        <CurrencyInput
                          formItemName={[name, "alisKdvOran"]}
                          formItemLabel={
                            localization.varyantGrup.stokAlisFiyati.alisKdvOran
                          }
                          required
                          onChange={(e) => alisFiyatHesaplama(e, name)}
                        />
                        <CurrencyInput
                          formItemName={[name, "vergilerDahilAlisFiyat"]}
                          formItemLabel={
                            localization.varyantGrup.stokAlisFiyati
                              .vergilerDahilAlisFiyati
                          }
                          required
                          addonAfter={
                            <DovizSelectOption
                              size="middle"
                              formItemName={[name, "alisDovizId"]}
                              required
                              showKod
                              titleHidden
                              noStyle
                            />
                          }
                          onChange={(e) => alisFiyatHesaplama(e, name)}
                        />
                      </Col>
                      <Col flex={1}>
                        <CurrencyInput
                          formItemName={[name, "vergilerHaricSatisFiyat"]}
                          formItemLabel={
                            localization.varyantGrup.stokSatisFiyati
                              .vergilerHaricSatisFiyati
                          }
                          required
                          addonAfter={
                            <DovizSelectOption
                              size="middle"
                              formItemName={[name, "satisDovizId"]}
                              required
                              showKod
                              titleHidden
                              noStyle
                            />
                          }
                          onChange={(e) => satisFiyatHesaplama(e, name)}
                        />
                        <CurrencyInput
                          formItemName={[name, "satisOtvOran"]}
                          formItemLabel={
                            localization.varyantGrup.stokSatisFiyati
                              .satisOtvOran
                          }
                          required
                          onChange={(e) => satisFiyatHesaplama(e, name)}
                        />
                        <CurrencyInput
                          formItemName={[name, "satisKdvOran"]}
                          formItemLabel={
                            localization.varyantGrup.stokSatisFiyati
                              .satisKdvOran
                          }
                          required
                          onChange={(e) => satisFiyatHesaplama(e, name)}
                        />
                        <CurrencyInput
                          formItemName={[name, "vergilerDahilSatisFiyat"]}
                          formItemLabel={
                            localization.varyantGrup.stokSatisFiyati
                              .vergilerDahilSatisFiyati
                          }
                          required
                          addonAfter={
                            <DovizSelectOption
                              size="middle"
                              formItemName={[name, "satisDovizId"]}
                              required
                              showKod
                              titleHidden
                              noStyle
                            />
                          }
                          onChange={(e) => satisFiyatHesaplama(e, name)}
                        />
                      </Col>
                    </Row>
                  </Card>
                );
              })}
            </Space>
          )}
        </Form.List>
      </Form>
    </Space>
  );
}

export default StokVaryantGrupEkle;
