import React, { useState } from "react";
import { Form, Card, Input, Button, message, notification } from "antd";
import strings from "../../utils/Localization";
import { api } from "../../helpers/ApiHelper";
const md5 = require("md5");

export default function SifremiDegistirForm() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const { sifremiDegistirForm } = strings.sifremiDegistir.components;

  const onFinish = async (values) => {
    try {
      setFetchLoading(true);
      const { sifre, yeniSifre } = values;

      const response = await api.post("/auth/sifreyenile", {
        Email: localStorage.getItem("eMail"),
        EskiSifre: md5(sifre),
        YeniSifre: md5(yeniSifre),
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Card>
      <Form form={form} layout="vertical" size="middle" onFinish={onFinish}>
        <Form.Item
          name="sifre"
          label={sifremiDegistirForm.mevcutSifre}
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="yeniSifre"
          label={sifremiDegistirForm.yeniSifre}
          rules={[{ required: true }, { min: 6 }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="yeniSifreTekrar"
          label={sifremiDegistirForm.yeniSifreTekrar}
          dependencies={["yeniSifre"]}
          rules={[
            { required: true },
            { min: 6 },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("yeniSifre") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(sifremiDegistirForm.sifreUyusmuyor)
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Button
          type="primary"
          size="large"
          style={{ float: "right" }}
          loading={fetchLoading}
          htmlType="submit"
        >
          {sifremiDegistirForm.guncelleButton}
        </Button>
      </Form>
    </Card>
  );
}
