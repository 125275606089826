import React, { useEffect, useState } from "react";
import { Card, Collapse, message } from "antd";
import { api } from "../../helpers/ApiHelper";
import useCurrency from "../../hooks/useCurrency";
import strings from "../../utils/Localization";
import { useSelector } from "react-redux";

const { Panel } = Collapse;

function StokBakiye({ stokVaryantGrupIds = [] }) {
  const [bakiye, setBakiye] = useState({
    bakiye: 0,
    cikan: 0,
    giren: 0,
    varyantGrup: [],
  });
  const { stokBakiye: localization } = strings.varyantliStokDetay.components;
  const rerenderReducer = useSelector((state) => state.rerenderReducer);

  useEffect(() => {
    fetchStokBakiye();
  }, [rerenderReducer]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStokBakiye = async () => {
    try {
      const response = await api.post(`/stok/getsonbakiye`, {
        stokVaryantGrupIds: stokVaryantGrupIds,
      });
      if (response.status === 200) {
        setBakiye(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Collapse defaultActiveKey={["999999"]}>
        <Panel key={"999999"} header={localization.genelBakiye} showArrow>
          <BakiyeItem
            giris={bakiye.giren}
            cikis={bakiye.cikan}
            bakiye={bakiye.bakiye}
          />
        </Panel>
        {bakiye.varyantGrup.map((item, index) => {
          const { varyantGrupAd, giren, cikan, bakiye } = item;
          return (
            <Panel
              header={`${localization.varyant} ${varyantGrupAd}`}
              key={index}
            >
              <BakiyeItem giris={giren} cikis={cikan} bakiye={bakiye} />
            </Panel>
          );
        })}
      </Collapse>
    </Card>
  );
}

export default StokBakiye;

const BakiyeItem = ({ giris, cikis, bakiye }) => {
  const { paraFormat } = useCurrency();
  const { stokBakiye: localization } = strings.varyantliStokDetay.components;

  const gridStyle = {
    width: "50%",
    textAlign: "center",
    padding: 4,
  };
  const gridTitleStyle = {
    ...gridStyle,
    fontWeight: "600",
  };

  return (
    <>
      <Card.Grid style={gridTitleStyle} hoverable={false}>
        {localization.giris}
      </Card.Grid>
      <Card.Grid style={gridStyle}>{paraFormat(giris)}</Card.Grid>
      <Card.Grid style={gridTitleStyle} hoverable={false}>
        {localization.cikis}
      </Card.Grid>
      <Card.Grid style={gridStyle}>{paraFormat(cikis)}</Card.Grid>
      <Card.Grid style={gridTitleStyle} hoverable={false}>
        {localization.bakiye}
      </Card.Grid>
      <Card.Grid style={{ ...gridStyle, marginBottom: 16 }}>
        {paraFormat(bakiye)}
      </Card.Grid>
    </>
  );
};
