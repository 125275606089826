import React from "react";
import { Form, Row, Col, Input, InputNumber } from "antd";
import moment from "moment";
import Datepicker from "../toolbox/DatePicker";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import OdemeSekliSelectOption from "../toolbox/OdemeSekliSelectOption";
import strings from "../../utils/Localization";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";
import { odemeSekliTypes } from "../../types/odemeSekliTypes";

export default function AlacakBorcForm({
  id,
  onFinish,
  form,
  formSize = "middle",
}) {
  const { getDefaultSetting } = useVarsayilanDeger();
  const { alacakBorcForm } = strings.cariIslem.components;
  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.dovizId !== undefined) {
      form.setFieldsValue({
        kasaId: null,
        bankaHesapId: null,
      });
    }
  };
  return (
    <Form
      form={form}
      id={id}
      layout="vertical"
      size="middle"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      initialValues={{
        vadeTarihi: moment(),
        islemTarihi: moment(),
        evrakNo: "",
        aciklama: "",
        dovizId: getDefaultSetting("doviz"),
        odemeSekli: odemeSekliTypes.ACIK_HESAP,
        kasaId: null,
        bankaHesapId: null,
        tutar: 0,
      }}
    >
      <Row gutter={24}>
        <Col flex={1}>
          <Form.Item
            label={alacakBorcForm.vadeTarihi}
            name="vadeTarihi"
            rules={[{ required: true }]}
          >
            <Datepicker size={formSize} />
          </Form.Item>
          <Form.Item
            label={alacakBorcForm.islemTarihi}
            name="islemTarihi"
            rules={[{ required: true }]}
          >
            <Datepicker size={formSize} />
          </Form.Item>
          <Form.Item label={alacakBorcForm.evrakNo} name="evrakNo" required>
            <Input />
          </Form.Item>
          <Form.Item label={alacakBorcForm.aciklama} name="aciklama">
            <Input.TextArea rows={5} placeholder={""} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <DovizSelectOption formItemName="dovizId" size={formSize} required />

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.dovizId !== currentValues.dovizId
            }
          >
            {({ getFieldValue }) => {
              const currentDovizId = getFieldValue("dovizId");
              if (currentDovizId !== null) {
                return (
                  <OdemeSekliSelectOption
                    formItemName="odemeSekli"
                    formItemLabel={alacakBorcForm.odemeSekli}
                    required
                    size={formSize}
                    hiddenHavale
                    hiddenKrediKarti
                    hiddenNakit
                  />
                );
              }
            }}
          </Form.Item>
          <Form.Item
            label={alacakBorcForm.tutar}
            name="tutar"
            rules={[{ required: true }]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
