import React from 'react'
import { Switch } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { setDarkTheme, setLightTheme } from '../../../redux/actions/themeAction'
import strings from '../../../utils/Localization'

const TemaSecim = (props) => {
    const dispatch = useDispatch()
    const currentTheme = useSelector(state => state.themeReducer)

    const changeTheme = () => {
        currentTheme === "dark" ?
            dispatch(setLightTheme()) :
            dispatch(setDarkTheme())
    }

    return (
        <Switch
            checked={currentTheme === "dark" ? true : false}
            onChange={() => changeTheme()}
            checkedChildren={strings.navBar.temaSecim.aktif}
            unCheckedChildren={strings.navBar.temaSecim.pasif}
        />
    )
}

export default TemaSecim
