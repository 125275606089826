import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  tr: {
    logoName: "EminHesap",
    mesaj: {
      basarliBaslik: "Başarılı",
      islemBasarili: "İşlem başarılı",
      islemBasarisiz: "İşlem başarısız",
      silmeIslemUyari:
        "İşleminiz kalıcı olarak silinecektir. Onaylıyormusunuz?",
      cariKartSilme:
        "Silmek istediğiniz carikartın alt işlemleri bulunmaktadır. Lütfen öncelikle alt işlemlerini siliniz.",
      cariGrupSilme:
        "Silmek istediğiniz cari grubun bağlanıtılı cari kartları bulunmaktadır. Lütfen öncelikle carikartlarda işlem yapınız.",
      stokSilme:
        "Silmek istediğiniz stoğun alt işlemleri bulunmaktadır. Lütfen öncelikle alt işlemlerini siliniz.",
      stokBirimSilme:
        "Silmek istediğiniz stok birim ile bağlanıtılı stoklar bulunmaktadır. Lütfen öncelikle stoklarda işlem yapınız.",
      stokKategoriSilme:
        "Silmek istediğiniz stok kategori ile bağlanıtılı stoklar bulunmaktadır. Lütfen öncelikle stoklarda işlem yapınız.",
      stokVaryantSilme:
        "Silmek istediğiniz stok varyantın, varyant değerleri mevcut. Lütfen öncelikle varyant değerlede işlem yapınız.",
      stokVaryantDegerSilme:
        "Silmek istediğiniz stok varyant değerin, stoklar ile bağlantısı mevcut. Lütfen öncelikle stoklarda işlem yapınız.",
      stokVaryantGrupSilme:
        "Silmek istediğiniz stok varyant grubun, stok işlem ile bağlantısı mevcut. Lütfen öncelikle alt işlemlerini siliniz.",
      stokIslemSilme:
        "Silmek istediğiniz stok işlemin fatura ile bağlantısı mevcuttur. Lütfen öncellikle fatura bölümünde işlem yapınız.",
      stokDepoSilme:
        "Silmek istediğiniz stok deposunun bağlanıtılı stok işlemleri bulunmaktadır. Lütfen öncelikle stoklarda işlem yapınız.",
      kasaSilme:
        "Silmek istediğiniz kasanın alt işlemleri mevcut. Lütfen öncelikle alt işlemlerini siliniz.",
      bankaHesapSilme:
        "Silmek istedğiniz banka hesabının alt işlemleri mevcut. Lütfen öncelikle alt işlemlerini siliniz.",
      cariIslemFaturaSilme:
        "Silmek istediğiniz işlemin fatura ile bağlantısı mevcut. Lütfen fatura bölümünden işlem yapınız",
      stokIslemFaturaSilme:
        "Silmek istediğiniz işlemin fatura ile bağlantısı mevcut. Lütfen fatura bölümünden işlem yapınız",
      stokIslemFaturaDuzenleme:
        "Düzenlemek istediğiniz işlemin fatura ile bağlantısı mevcut. Lütfen fatura bölümünden işlem yapınız",
      gerekliBirAlan: "gerekli bir alan",
    },
    sideBar: {
      panel: "Panel",
      cariKart: "CariKart",
      faturalar: "Faturalar",
      teklifSiparis: "Teklif Sipariş",
      stoklar: "Stoklar",
      kasalar: "Kasalar",
      bankaHesaplar: "Banka Hesaplar",
      raporlar: "Raporlar",
      raporlarAltMenu: {
        cariRapor: "Cari Raporu",
        cariIslemRapor: "Cari İşlem Raporu",
        cariEksteRapor: "Cari Ekstre Raporu",
        kasaDetayRapor: "Kasa Detay Raporu",
        bankaDetayRapor: "Banka Detay Raporu",
        stokRapor: "Stok Raporu",
        stokHareketRapor: "Stok Hareket Raporu",
        stokEkstreRapor: "Stok Ekstre Raporu",
        faturaRapor: "Fatura Raporu",
      },
    },
    navBar: {
      temaSecim: {
        aktif: "Karanlık Mod Aktif",
        pasif: "Karanlık Mod Pasif",
      },
      donemSecim: {
        baslik: "Dönem",
      },
      kullaniciAyarSecim: {
        firmaBilgileri: "Firma Bilgileri",
        sifremiDegistir: "Şifremi Değiştir",
        varsayilanAyarlar: "Varsayılan Ayarlar",
        ayarlar: "Ayarlar",
        cikisYap: "Çıkış Yap",
      },
    },
    panel: {
      baslik: "Güncel Durum",
      components: {
        vadeliTahsilatCicleProgress: {
          baslik: "Vadeli Tahsilat İşlemleri",
          toplamTahsilEdilecek: "Toplam Tahsil Edilecek",
          vadesiGecmisTahsilatlar: "Vadesi Geçmiş Tahsilatlar",
          genelToplam: "Genel Toplam",
        },
        vadeliOdemeCicleProgress: {
          baslik: "Vadeli Ödeme İşlemleri",
          toplamOdenecek: "Toplam Ödenecek",
          vadesiGecmisOdemeler: "Vadesi Geçmiş Ödemeler",
          genelToplam: "Genel Toplam",
        },
        tahsilatOdemeBarChart: {
          baslik: "Yapılmış Tahsilat & Ödemeler",
          tahsilat: "Tahsilat",
          odeme: "Ödeme",
          tarih: "tarih",
        },
        bankaBakiyeleri: {
          baslik: "Banka Bakiyeleri",
          bankaAdi: "Banka Adı",
          bakiye: "Bakiye",
        },
        kasaBakiyeleri: {
          baslik: "Kasa Bakiyeleri",
          kasaAdi: "Kasa Adı",
          bakiye: "Bakiye",
        },
      },
    },
    vadeliTahsilatDetay: {
      baslik: {
        vadesiGecmisTahsilatlar: "Vadesi Geçmiş Tahsilatlar",
        vadesiGelecekTahsilatlar: "Vadesi Gelecek Tahsilatlar",
        tumYapilacakTahsilatlar: "Tüm Yapılacak Tahsilatlar",
      },
      vadeTarihi: "Vade Tarihi",
      islemTarihi: "İşlem Tarihi",
      evrakNo: "Evrak No",
      durum: "Durum",
      tutar: "Tutar",
      tahsilEdilenTutar: "Tahsil Edilen Tutar",
      kalanTutar: "Kalan Tutar",
      islemler: "İşlemler",
      detayaGit: "Detaya Git",
      vadesiGelecekGun: "Gün Kaldı",
      vadesiGecmisGün: "Gün Geçti",
    },
    vadeliOdemeDetay: {
      baslik: {
        vadesiGecmisOdemeler: "Vadesi Geçmiş Ödemeler",
        vadesiGelecekOdemeler: "Vadesi Gelecek Ödemeler",
        tumYapilacakOdemeler: "Tüm Yapılacak Ödemeler",
      },
      vadeTarihi: "Vade Tarihi",
      islemTarihi: "İşlem Tarihi",
      evrakNo: "Evrak No",
      durum: "Durum",
      tutar: "Tutar",
      OdenenTutar: "Ödenen Edilen Tutar",
      kalanTutar: "Kalan Tutar",
      islemler: "İşlemler",
      detayaGit: "Detaya Git",
      vadesiGelecekGun: "Gün Kaldı",
      vadesiGecmisGün: "Gün Geçti",
    },
    cari: {
      menu: {
        cariKart: "Cari Kart Listesi",
        carigrup: "Cari Grup Listesi",
      },
    },
    cariKartListe: {
      baslik: "Cari Kart Listesi",
      cariEkleButon: "CariKart Ekle",
      firmaAdi: "Firma Adı",
      yetkili: "Yetkili",
      telefon: "Telefon Numarası",
      vergiNumarasi: "Vergi Numarası",
      il: "İl",
      bakiye: "Bakiye",
      islemler: "İşlemler",
    },
    cariGrupListe: {
      baslik: "Cari Grup Listesi",
      cariGrupEkleButon: "Cari Grup Ekle",
      grupAdi: "Grup Adı",
      islemler: "İşlemler",
    },
    cariDetay: {
      components: {
        pageHeader: {
          baslik: "Cari Detayı",
          altBaslik:
            "tahsilat, ödeme, alacaklandırma, borçlandırma, fatura oluştur...",
        },
        profil: {
          alacak: "Alacak",
          borc: "Borç",
          toplam: "Toplam",
          tumBilgileriGoster: "Tüm Bilgileri Göster",
        },
        profilDetay: {
          baslik: "Cari Bilgileri",
          firmaAdi: "Firma Adı",
          unvan: "Unvan",
          cariGrup: "Cari Grup",
          telefon: "Telefon",
          cepTelefonu: "Cep Telefonu",
          eMail: "EMail",
          vergiDairesi: "Vergi Dairesı",
          vergiNumarasi: "Vergi Numarası",
          il: "İl",
          ilce: "İlçe",
          adres: "Adres",
          sevkAdresi: "Sevk Adresi",
          kapatButon: "Kapat",
        },
        islemMenu: {
          tahsilatYap: "Tahsilat Yap",
          odemeYap: "Ödeme Yap",
          alacaklandir: "Alacaklandır",
          borclandir: "Borçlandır",
        },
        cariIslemList: {
          baslik: "Cari İşlemler",
          aramaPlaceHolder: "evrak no, açıklama, tutar ara...",
          tarih: "Tarih",
          vadeTarihi: "Vade Tarihi",
          evrakNo: "Evrak No",
          aciklama: "Açıklama",
          cariIslemTur: "İşlem Türü",
          tutar: "Tutar",
          islemler: "İşlemler",
        },
        cariBakiye: {
          doviz: "Döviz",
          giris: "Giriş",
          cikis: "Çıkış",
          bakiye: "Bakiye",
        },
        detayliArama: {
          baslik: "Detaylı Arama",
          islemTarihi: "İşlem Tarihi",
          vadeTarihi: "Vade Tarihi",
          islemTipi: "İşlem Tipi",
          tumIslemTipleriniGoster: "Tüm işlem tiplerini göster",
          tahsilatIslemleriniGoster: "Tahsilat işlemlerini göster",
          odemeIslemleriniGoster: "Ödeme işlemlerini göster",
          alacaklandirmaIslemleriniGoster: "Alacaklandırma işlemlerini göster",
          borclandirmaIslemleriniGoster: "Borçlandırma işlemlerini göster",
          minTutar: "Min. Tutar",
          maxTutar: "Max. Tutar",
          doviz: "Döviz",
          temizle: "Temizle",
          aramayiBaslat: "Aramayı Başlat",
        },
      },
    },
    faturaListe: {
      components: {
        pageHeader: {
          baslik: "Faturalar",
          altBaslik: "fatura listesi",
          faturaEkleButon: "Fatura Ekle",
        },
        faturaListe: {
          firmaAdi: "Firma Adı",
          faturaNo: "Fatura No",
          faturaTipi: "Fatura Tipi",
          faturaTarihi: "Fatura Tarihi",
          genelToplam: "Genel Toplam",
          islemler: "İşlemler",
        },
        islemButton: {
          yeniSatisFaturasi: "Yeni Satış Faturası",
          yeniAlisFaturasi: "Yeni Alış Faturası",
          yeniSatisIadeFaturasi: "Yeni Satış İade Faturası",
          yeniAlisIadeFaturasi: "Yeni Alış İade Faturası",
        },
        detayliArama: {
          baslik: "Detaylı Arama",
          islemTarihi: "İşlem Tarihi",
          faturaTipi: "Fatura Tipi",
          tumFaturalariGoster: "Tüm Faturaları Göster",
          alisFaturalariniGoster: "Alış Faturalarını Göster",
          satisFaturalariniGoster: "Alış Faturalarını Göster",
          alisIadeFaturalariniGoster: "Alış İade Faturalarını Göster",
          satisIadeFaturalariniGoster: "Satış İade Faturalarını Göster",
          minTutar: "Min. Tutar",
          maxTutar: "Max. Tutar",
          doviz: "Döviz",
          temizle: "Temizle",
          aramayiBaslat: "Aramayı Başlat",
        },
      },
    },
    tarih: {
      ay: [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık",
      ],
      hafta: ["Pa", "Pt", "Sl", "Ça", "Pe", "Cu", "Ct"],
      format: "DD-MM-YYYY",
      baslangicTarih: "Başlangıç Tarihi",
      bitisTarihi: "Bitiş Tarihi",
      tarihSeciniz: "Tarih Seçiniz...",
    },
    cariKart: {
      cariKartEkleModal: {
        baslik: "Cari Kart Ekle",
        firmaAdi: "Firma Adı",
        firmaAdiPlaceHolder: "firma adı giriniz...",
        yetkili: "Yetkili Adı",
        yetkiliPlaceHolder: "yetkili adı giriniz",
        vergiDairesi: "Vergi Dairesi",
        vergiDairesiPlaceHolder: "vergi dairesi giriniz...",
        vergiNumarasi: "Vergi Numarası",
        vergiNumarasiPlaceHolder: "vergi numarası giriniz...",
        telefon: "Telefon",
        telefonPlaceHolder: "Telefon numarası giriniz...",
        cepTelefonu: "Cep Telefonu",
        cepTelefonuPlaceHolder: "cep telefonu giriniz...",
        eMail: "E-Mail Giriniz",
        eMailPlaceHolder: "email giriniz...",
        cariKodu: "Cari Kodu",
        cariKoduPlaceHolder: "cari kodu giriniz...",
        il: "İl",
        ilPlaceHolder: "il giriniz...",
        ilce: "İlçe",
        ilcePlaceHolder: "ilçe giriniz...",
        adres: "Adres",
        adresPlaceHolder: "adres giriniz...",
        sevkAdresi: "Sevk Adresi",
        sevkAdresiPlaceHolder: "sevk adresi giriniz...",
      },
      cariKartDuzenleModal: {
        baslik: "Cari Kart Düzenle",
        firmaAdi: "Firma Adı",
        firmaAdiPlaceHolder: "firma adı giriniz...",
        yetkili: "Yetkili Adı",
        yetkiliPlaceHolder: "yetkili adı giriniz",
        vergiDairesi: "Vergi Dairesi",
        vergiDairesiPlaceHolder: "vergi dairesi giriniz...",
        vergiNumarasi: "Vergi Numarası",
        vergiNumarasiPlaceHolder: "vergi numarası giriniz...",
        telefon: "Telefon",
        telefonPlaceHolder: "Telefon numarası giriniz...",
        cepTelefonu: "Cep Telefonu",
        cepTelefonuPlaceHolder: "cep telefonu giriniz...",
        eMail: "E-Mail Giriniz",
        eMailPlaceHolder: "email giriniz...",
        cariKodu: "Cari Kodu",
        cariKoduPlaceHolder: "cari kodu giriniz...",
        il: "İl",
        ilPlaceHolder: "il giriniz...",
        ilce: "İlçe",
        ilcePlaceHolder: "ilçe giriniz...",
        adres: "Adres",
        adresPlaceHolder: "adres giriniz...",
        sevkAdresi: "Sevk Adresi",
        sevkAdresiPlaceHolder: "sevk adresi giriniz...",
      },
    },
    cariGrup: {
      cariGrupEkleModal: {
        baslik: "Cari Grup Ekle",
        cariGrupAdi: "Cari Grup Adi",
        cariGrupAdiPlaceHolder: "cari grup adı giriniz...",
      },
      cariGrupDuzenleModal: {
        baslik: "Cari Grup Düzenle",
        cariGrupAdi: "Cari Grup Adi",
        cariGrupAdiPlaceHolder: "cari grup adı giriniz...",
      },
    },
    cariIslem: {
      components: {
        tahsilatOdemeForm: {
          islemTarihi: "İşlem Tarihi",
          evrakNo: "Evrak No",
          aciklama: "Açıklama",
          odemeSekli: "Ödeme Şekli",
          kasa: "Kasa",
          bankaHesap: "Banka Hesap",
          tutar: "Tutar",
        },
        alacakBorcForm: {
          vadeTarihi: "Vade Tarihi",
          islemTarihi: "İşlem Tarihi",
          evrakNo: "Evrak No",
          aciklama: "Açıklama",
          odemeSekli: "Ödeme Şekli",
          tutar: "Tutar",
        },
      },
      alacaklandirmaEkle: {
        baslik: "Alacaklandrıma İşlemi",
      },
      borclandirmaEkle: {
        baslik: "Borçlandırma İşlemi",
      },
      tahsilatEkle: {
        baslik: "Tahsilat Ekle",
      },
      odemeEkle: {
        baslik: "Ödeme Ekle",
      },
      cariIslemDuzenle: {
        baslik: "Cari İşlem Düzenle",
        vadeTarihi: "Vade Tarihi",
        islemTarihi: "İşlem Tarihi",
        tutar: "Tutar",
        evrakNo: "Evrak No",
        aciklama: "Açıklama",
      },
    },
    cariBakiye: {
      bakiye: "Bakiye",
    },
    kasaListe: {
      components: {
        pageHeader: {
          baslik: "Kasalar",
          altBaslik: "kasa listesi",
          kasaEkleButon: "Kasa Ekle",
        },
        KasaListTable: {
          kasaAdi: "Kasa Adı",
          bakiye: "Bakiye",
          islemler: "İşlemler",
        },
      },
    },
    kasa: {
      kasaEkleModal: {
        baslik: "Kasa Ekle",
        kasaAdi: "Kasa Adı",
        kasaAdiPlaceHolder: "kasa adı giriniz...",
      },
      kasaDuzenleModal: {
        baslik: "Kasa Düzenle",
        kasaAdi: "Kasa Adı",
        kasaAdiPlaceHolder: "kasa adı giriniz...",
      },
    },
    kasaDetay: {
      components: {
        pageHeader: {
          baslik: "Kasa Detayı",
          altBaslik: "giriş, çıkış, virman işlemleri...",
        },
        profil: {
          giris: "Giriş",
          cikis: "Çıkış",
          toplam: "Genel Toplam",
        },
        kasaIslemListe: {
          baslik: "Kasa İşlemleri",
          aramaPlaceHolder: "evrak no, açıklama, tutar ara...",
          tarih: "Tarih",
          evrakNo: "Evrak No",
          aciklama: "Açıklama",
          unvan: "Ünvan",
          tutar: "Tutar",
          islemler: "İşlemler",
          virmanIslemi: "Virman İşlemi",
        },
        islemMenu: {
          girisYap: "Giriş Yap",
          cikisYap: "Çıkış Yap",
          kasayaVirman: "Kasaya Virman",
          bankayaVirman: "Bankaya Virman",
        },
        detayliArama: {
          baslik: "Detaylı Arama",
          islemTarihi: "İşlem Tarihi",
          islemTipi: "İşlem Tipi",
          tumIslemTipleriniGoster: "Tüm işlem tiplerini göster",
          girisIslemleriniGoster: "Giriş işlemlerini göster",
          cikisIslemleriniGoster: "Çıkış işlemlerini göster",
          virmanTipi: "Virman Tipi",
          tumVirmanTipleriniGoster: "Tüm virman tiplerini göster",
          kasayaVirmanIslemleriniGoster: "Kasaya virman işlemlerini göster",
          bankayaVirmanIslemleriniGoster: "Bankaya virman işlemlerini göster",
          minTutar: "Min. Tutar",
          maxTutar: "Max. Tutar",
          temizle: "Temizle",
          aramayiBaslat: "Aramayı Başlat",
        },
      },
    },
    kasaIslem: {
      kasayaVirman: {
        baslik: "Kasadan Kasaya Virman",
        islemTarihi: "İşlem Tarihi",
        evrakNo: "Evrak No",
        aciklama: "Açıklama",
        gondericiKasa: "Gönderici Kasa",
        aliciKasa: "Alıcı Kasa",
        cikanTutar: "Çıkan Tutar",
        tutar: "Tutar",
        doviz: "Döviz",
        kur: "Kur",
        girenTutar: "Giren Tutar",
      },
      bankayaVirman: {
        baslik: "Kasadan Bankaya Virman",
        islemTarihi: "İşlem Tarihi",
        evrakNo: "Evrak No",
        aciklama: "Açıklama",
        gondericiKasa: "Gönderici Kasa",
        aliciBankaHesap: "Alıcı Banka Hesap",
        cikanTutar: "Çıkan Tutar",
        tutar: "Tutar",
        doviz: "Döviz",
        kur: "Kur",
        girenTutar: "Giren Tutar",
      },
      kasaIslemEkleModal: {
        baslik: "Kasa İşlem Ekle",
        islemTarihi: "İşlem Tarihi",
        islemTipi: "İşlem Tipi",
        islemTipiSelect: {
          giris: "Giriş",
          cikis: "Çıkış",
        },
        evrakNo: "Evrak No",
        tutar: "Tutar",
        aciklama: "Açıklama",
      },
      virmanDuzenleModal: {
        baslik: "Virman Düzenle",
        islemTarihi: "İşlem Tarihi",
        gonderici: "Gönderici",
        alici: "Alıcı",
        evrakNo: "Evrak No",
        aciklama: "Açıklama",
        cikanTutar: "Çıkan Tutar",
        doviz: "Döviz",
        kur: "Kur",
        girenTutar: "Giren Tutar",
      },
      kasaIslemDuzenleModal: {
        baslik: "Kasa İşlem Düzenleme",
        islemTarihi: "İşlem Tarihi",
        islemTipi: "İşlem Tipi",
        islemTipiSelect: {
          giris: "Giriş",
          cikis: "Çıkış",
        },
        evrakNo: "Evrak No",
        tutar: "Tutar",
        aciklama: "Açıklama",
      },
    },
    bankaHesapListe: {
      components: {
        pageHeader: {
          baslik: "Banka Hesapları",
          altBaslik: "",
          bankaHesapEkleButon: "Banka Hesap Ekle",
        },
        bankaHesapListTable: {
          hesapAdi: "Hesap Adı",
          bankaAdi: "Banka Adı",
          sube: "Şube",
          hesapNo: "Hesap No",
          bakiye: "Bakiye",
          islemler: "İşlemler",
        },
      },
    },
    bankaHesap: {
      bankaHesapEkleModal: {
        baslik: "Banka Hesap Ekle",
        hesapAdi: "Hesap Adı",
        hesapAdiPlaceHolder: "hesap adı giriniz...",
        sube: "Şube",
        subePlaceHolder: "şube giriniz...",
        subeNo: "Şube No",
        subeNoPlaceHolder: "şube no giriniz...",
        hesapNo: "Hesap No",
        hesapNoPlaceHolder: "hesap no giriniz...",
        ibanNo: "Iban No",
        ibanNoPlaceHolder: "iban no giriniz...",
      },
      bankaHesapDuzenleModal: {
        baslik: "Banka Hesap Düzenle",
        hesapAdi: "Hesap Adı",
        hesapAdiPlaceHolder: "hesap adı giriniz...",
        sube: "Şube",
        subePlaceHolder: "şube giriniz...",
        subeNo: "Şube No",
        subeNoPlaceHolder: "şube no giriniz...",
        hesapNo: "Hesap No",
        hesapNoPlaceHolder: "hesap no giriniz...",
        ibanNo: "Iban No",
        ibanNoPlaceHolder: "iban no giriniz...",
      },
    },
    bankaHesapDetay: {
      components: {
        pageHeader: {
          baslik: "Banka Hesap Detay",
          altBaslik: "giriş, çıkış, virman işlemleri...",
        },
        profil: {
          giris: "Giriş",
          cikis: "Çıkış",
          toplam: "Toplam",
          tumBilgileriGoster: "Tüm Bilgileri Göster",
        },
        profilDetay: {
          baslik: "Banka Hesap Bilgileri",
          bankaAdi: "Banka Adı",
          hesapAdi: "Hesap Adı",
          subeAdi: "Şube Adı",
          subeNo: "Şube No",
          hesapNo: "Hesap No",
          ibanNo: "Iban No",
          dovizTuru: "Döviz Türü",
          kapatButon: "Kapat",
        },
        bankaHesapIslemListe: {
          baslik: "Banka Hesap İşlemleri",
          aramaPlaceHolder: "evrak no, açıklama, tutar ara...",
          tarih: "Tarih",
          evrakNo: "Evrak No",
          aciklama: "Açıklama",
          unvan: "Ünvan",
          tutar: "Tutar",
          islemler: "İşlemler",
          virmanIslemi: "Virman İşlemi",
        },
        islemMenu: {
          girisYap: "Giriş Yap",
          cikisYap: "Çıkış Yap",
          kasayaVirman: "Kasaya Virman",
          bankayaVirman: "Bankaya Virman",
        },
        detayliArama: {
          baslik: "Detaylı Arama",
          islemTarihi: "İşlem Tarihi",
          islemTipi: "İşlem Tipi",
          tumIslemTipleriniGoster: "Tüm işlem tiplerini göster",
          girisIslemleriniGoster: "Giriş işlemlerini göster",
          cikisIslemleriniGoster: "Çıkış işlemlerini göster",
          virmanTipi: "Virman Tipi",
          tumVirmanTipleriniGoster: "Tüm virman tiplerini göster",
          bankayaVirmanIslemleriniGoster: "Bankaya virman işlemlerini göster",
          kasayaVirmanIslemleriniGoster: "Kasaya virman işlemlerini göster",
          minTutar: "Min. Tutar",
          maxTutar: "Max. Tutar",
          temizle: "Temizle",
          aramayiBaslat: "Aramayı Başlat",
        },
      },
    },
    bankaHesapIslem: {
      bankaHesapIslemEkleModal: {
        baslik: "Banka Hesap İşlem Ekle",
        islemTarihi: "İşlem Tarihi",
        islemTipi: "İşlem Tipi",
        islemTipiSelect: {
          giris: "Giriş",
          cikis: "Çıkış",
        },
        evrakNo: "Evrak No",
        tutar: "Tutar",
        aciklama: "Açıklama",
      },
      bankayaVirman: {
        baslik: "Bankadan Bankaya Virman",
        islemTarihi: "İşlem Tarihi",
        evrakNo: "Evrak No",
        aciklama: "Açıklama",
        gondericiBankaHesap: "Gönderici Banka Hesap",
        aliciBankaHesap: "Alıcı Banka Hesap",
        cikanTutar: "Çıkan Tutar",
        tutar: "Tutar",
        doviz: "Döviz",
        kur: "Kur",
        girenTutar: "Giren Tutar",
      },
      kasayaVirman: {
        baslik: "Bankadan Kasaya Virman",
        islemTarihi: "İşlem Tarihi",
        evrakNo: "Evrak No",
        aciklama: "Açıklama",
        gondericiBankaHesap: "Gönderici Banka Hesap",
        aliciKasa: "Alıcı Kasa",
        cikanTutar: "Çıkan Tutar",
        tutar: "Tutar",
        doviz: "Döviz",
        kur: "Kur",
        girenTutar: "Giren Tutar",
      },
      BankaHesapIslemDuzenleModal: {
        baslik: "Banka Hesap İşlem Düzenleme",
        islemTarihi: "İşlem Tarihi",
        islemTipi: "İşlem Tipi",
        islemTipiSelect: {
          giris: "Giriş",
          cikis: "Çıkış",
        },
        evrakNo: "Evrak No",
        tutar: "Tutar",
        aciklama: "Açıklama",
      },
    },
    girisYap: {
      components: {
        girisYapForm: {
          eMailPlaceHolder: "Lütfen email adresinizi giriniz...",
          sifrePlaceHolder: "Lütfen şifrenizi giriniz...",
          girisYapButon: "Giriş Yap",
          kayitOlButon: "Hemen Ücretsiz Kayıt Ol",
          kurallar: {
            emailZorunlu: "email adresi boş geçilimez!",
            emailFormat: "email formatı yanlış!",
            sifreZorunlu: "şifre boş geçilemez!",
            sifreMinUzunluk: "şifre en az 6 haneli olmalı!",
          },
        },
        kayitOlButton: {
          butonYazi: "Kayit Ol",
        },
        iletisimBilgi: {
          telefonNumarasi: "545 555 33 22",
        },
        sifremiUnuttumButon: {
          butonYazi: "Şifremi Unuttum",
        },
        demoButton: {
          butonYazi: "Demo Hesabı",
        },
      },
    },
    kayitOl: {
      slogan: "Firma bilgilerinizi girerek hemen ücretsiz hesap oluştur!",
      components: {
        kayitOlForm: {
          vergiNumarasi: "Vergi No",
          firmaAdi: "Firma Adı",
          yetkili: "Yetkili",
          telefon: "Telefon",
          sektor: "Sektör",
          sektorPlaceHolder: "Lütfen sektör seçiniz",
          eMail: "EMail",
          sifre: "Şifre",
          sifreTekrar: "Şifre Tekrar",
          kayitOlButon: "Hemen Ücretsiz Kayıt Ol!",
          kurallar: {
            sifreOnay: "şifre ile şifre onay uyuşmuyor!",
            vergiNumarasiSayiUyari: "Vergi No sayı olmadır",
          },
        },
      },
    },
    sifremiUnuttumMailDogrulama: {
      slogan:
        "Parolanızı yenilemeniz için gereken bilgileri kayıtlı olan e-posta adresinize gönderebiliriz.",
      eMailPlaceHolder: "email adresi giriniz...",
      sifremiUnuttumButon: "Mail Gönder",
      eMail: "EMail",
      onayKoduGonderildi:
        "Mail adresinize onay kodu gönderildi. Lütfen onay kodunuzu giriniz.",
    },
    sifremiUnuttumKodDogrulama: {
      slogan: "Mail adresinize gelen kodu lütfen onaylayınız.",
      kodOnayButon: "Kodu Onayla",
      dogrulamaKodu: "Doğrulama Kodu",
      dogrulamaKoduPlaceHolder: "Doğrulama kodu giriniz...",
    },
    sifremiUnuttumYeniSifre: {
      slogan: "Yeni bir şifre oluşturun.",
      kaydetButon: "Kaydet",
      sifre: "Şifre",
      sifrePlaceHolder: "Şifre giriniz...",
      sifreTekrar: "Şifre Tekrar",
      sifreTekrarPlaceHolder: "Şifre tekrar giriniz...",
      sifreUyusmuyor: "Şifre ile şifre tekrar uyuşmuyor.",
      sifreDegistirildi: "Şifre başarılı bir şekilde değiştirildi",
    },
    stok: {
      components: {
        pageHeader: {
          baslik: "Stok",
          altBaslik:
            "stok listesi, stok grup, stok birim, stok özellik, stok indirim, depo listesi",
        },
        menu: {
          stokListesi: "Stok Listesi",
          stokKategorileri: "Stok Kategorileri",
          stokBirimleri: "Stok Birimleri",
          stokVaryantlari: "Stok Varyantları",
          depoListesi: "Depo Listesi",
        },
      },
    },
    stokDepoListe: {
      components: {
        stokDepoListe: {
          baslik: "Depo Listesi",
          depoAd: "Ad",
          islemler: "İşlemler",
          depoEkleButon: "Depo Ekle",
        },
      },
    },
    stokDepoEkleModal: {
      baslik: "Stok Depo Ekle",
      depoAd: "Depo Adı",
      depoAdPlaceHolder: "Depo adı giriniz...",
    },
    stokDepoDuzenleModal: {
      baslik: "Stok Depo Düzenle",
      depoAd: "Depo Adı",
      depoAdPlaceHolder: "Depo adı giriniz...",
    },
    stokBirimListe: {
      components: {
        stokBirimListe: {
          baslik: "Stok Birim Listesi",
          birimAd: "Ad",
          islemler: "İşlemler",
          birimEkleButom: "Birim Ekle",
        },
      },
    },
    stokBirimEkleModal: {
      baslik: "Stok Birim Ekle",
      birimAd: "Birim Adı",
      birimAdPlaceHolder: "Birim adı giriniz...",
    },
    stokBirimDuzenleModal: {
      baslik: "Stok Birim Düzenle",
      birimAd: "Birim Adı",
      birimAdPlaceHolder: "Birim adı giriniz...",
    },
    stokKategoriListe: {
      components: {
        stokKategoriListe: {
          baslik: "Stok Kategori Liste",
          kategoriAd: "Ad",
          islemler: "İşlemler",
          stokKategoriEkleButon: "Stok Kategori Ekle",
        },
      },
    },
    stokKategoriEkleModal: {
      baslik: "Stok Kategori Ekle",
      kategoriAd: "Kategori Adı",
      kategoriAdPlaceHolder: "Kategori adı giriniz...",
    },
    stokKategoriDuzenleModal: {
      baslik: "Stok Kategori Düzenle",
      kategoriAd: "Kategori Adı",
      kategoriAdPlaceHolder: "Kategori adı giriniz...",
    },
    stokVaryant: {
      components: {
        stokVaryantListe: {
          baslik: "Varyant Listesi",
          varyantAdi: "Ad",
          islemler: "İşlemler",
          varyantEkleButon: "Varyant Ekle",
        },
        stokVaryantEkle: {
          baslik: "Stok Varyant Ekle",
          varyantAdi: "Varyant Adı",
          varyantDeger: "Varyant Değer",
          degerGirinizPlaceHolder: "değer giriniz...",
          varyantDegerEkle: "Varyant Değer Ekle",
        },
        stokVaryantDuzenle: {
          baslik: "Stok Varyant Düzenle",
          varyantAdi: "Varyant Adı",
        },
      },
    },
    stokVaryantDeger: {
      components: {
        stokVaryantDegerEkle: {
          baslik: "Stok Varyant Değer Ekle",
          varyantDegerAdi: "Varyant Değer Adı",
        },
        stokVaryantDegerDuzenle: {
          baslik: "Stok Varyant Değer Düzenle",
          varyantDegerAdi: "Varyant Değer Adı",
          silButton: "Sil",
          guncelleButton: "Güncelle",
        },
      },
    },
    stokListe: {
      components: {
        stokListe: {
          baslik: "Stok Listesi",
          stokAd: "Stok Adı",
          stokKategori: "Stok Kategori",
          birim: "Birim",
          varyantSayisi: "Varyant Sayısı",
          miktar: "Miktar",
          islemler: "İşlemler",
          stokEkleButon: "Stok Ekle",
          hizliStokEkle: "Hızlı Stok Ekle",
          varyantliStokEkle: "Varyantlı Stok Ekle",
        },
      },
    },
    stokEkle: {
      baslik: "Stok Ekle",
      urunDetayiAddonAfter: "Ürün Detayı",
      digerVergilerAddonAfter: "Diğer Vergiler",
      kaydet: "Kaydet",
      stokBilgileri: {
        baslik: "Stok Bilgileri",
        urunAdi: "Ürün Adı",
        birim: "Birim",
        kategori: "Kategori",
        barkodNUmarasi: "Barkod Numarası",
        stokRafi: "Stok Rafı",
        stokKodu: "Stok Kodu",
        gtinNumarasi: "Gtin Numarası",
        aciklama: "Açıklama",
      },
      stokAlisFiyati: {
        baslik: "Alış Fiyatı",
        vergilerHaricAlisFiyati: "Vergiler Hariç Alış Fiyatı",
        alisKdvOran: "Alış Kdv Oran",
        alisOtvOran: "Alış ÖTV Oran",
        vergilerDahilAlisFiyati: "Vergiler Dahil Alış Fiyatı",
        alisDovizTuru: "Alış Döviz Türü",
      },
      stokSatisFiyati: {
        baslik: "Satış Fiyatı",
        vergilerHaricSatisFiyati: "Vergiler Hariç Satış Fiyatı",
        satisKdvOran: "Satış Kdv Oran",
        satisOtvOran: "Satış ÖTV Oran",
        vergilerDahilSatisFiyati: "Vergiler Dahil Satış Fiyatı",
        satisDovizTuru: "Satış Döviz Türü",
      },
    },
    stokDuzenle: {
      baslik: "Stok Düzenle",
      urunDetayiAddonAfter: "Ürün Detayı",
      digerVergilerAddonAfter: "Diğer Vergiler",
      guncelle: "Güncelle",
      stokBilgileri: {
        baslik: "Stok Bilgileri",
        urunAdi: "Ürün Adı",
        birim: "Birim",
        kategori: "Kategori",
        barkodNUmarasi: "Barkod Numarası",
        stokRafi: "Stok Rafı",
        stokKodu: "Stok Kodu",
        gtinNumarasi: "Gtin Numarası",
        aciklama: "Açıklama",
      },
      stokAlisFiyati: {
        baslik: "Alış Fiyatı",
        vergilerHaricAlisFiyati: "Vergiler Hariç Alış Fiyatı",
        alisKdvOran: "Alış Kdv Oran",
        alisOtvOran: "Alış ÖTV Oran",
        vergilerDahilAlisFiyati: "Vergiler Dahil Alış Fiyatı",
        alisDovizTuru: "Alış Döviz Türü",
      },
      stokSatisFiyati: {
        baslik: "Satış Fiyatı",
        vergilerHaricSatisFiyati: "Vergiler Hariç Satış Fiyatı",
        satisKdvOran: "Satış Kdv Oran",
        satisOtvOran: "Satış ÖTV Oran",
        vergilerDahilSatisFiyati: "Vergiler Dahil Satış Fiyatı",
        satisDovizTuru: "Satış Döviz Türü",
      },
    },
    stokVaryantGrupDuzenle: {
      baslik: "Stok Varyant Grup Düzenle",
      guncelle: "Güncelle",
      stokBilgileri: {
        baslik: "Stok Bilgileri",
        barkodNUmarasi: "Barkod Numarası",
        stokRafi: "Stok Rafı",
        stokKodu: "Stok Kodu",
        gtinNumarasi: "Gtin Numarası",
        aciklama: "Açıklama",
      },
      stokAlisFiyati: {
        baslik: "Alış Fiyatı",
        vergilerHaricAlisFiyati: "Vergiler Hariç Alış Fiyatı",
        alisKdvOran: "Alış Kdv Oran",
        alisOtvOran: "Alış ÖTV Oran",
        vergilerDahilAlisFiyati: "Vergiler Dahil Alış Fiyatı",
        alisDovizTuru: "Alış Döviz Türü",
      },
      stokSatisFiyati: {
        baslik: "Satış Fiyatı",
        vergilerHaricSatisFiyati: "Vergiler Hariç Satış Fiyatı",
        satisKdvOran: "Satış Kdv Oran",
        satisOtvOran: "Satış ÖTV Oran",
        vergilerDahilSatisFiyati: "Vergiler Dahil Satış Fiyatı",
        satisDovizTuru: "Satış Döviz Türü",
      },
    },
    stokDetay: {
      baslik: "Stok Detayı",
      islemButton: {
        girisYap: "Giriş Yap",
        cikisYap: "Çıkış Yap",
      },
      profil: {
        giris: "Giriş",
        cikis: "Çıkış",
        kalan: "Kalan",
      },
      stokIslemListe: {
        islemTarihi: "İşlem Tarihi",
        aciklama: "Açıklama",
        depo: "Depo",
        miktar: "Miktar",
        islemler: "İşlemler",
        faturaIslemi: "Fatura İşlemi",
      },
      detayliArama: {
        baslik: "Detaylı Arama",
        islemTarihi: "İşlem Tarihi",
        depo: "Depo",
        islemTipi: "İşlem Tipi",
        tumIslemTipleriniGoster: "Tüm işlem tiplerini göster",
        girisIslemleriniGoster: "Giriş işlemlerini göster",
        cikisIslemleriniGoster: "Çıkış işlemlerini göster",
        minMiktar: "Min. Miktar",
        maxMiktar: "Max. Miktar",
        temizle: "Temizle",
        aramayiBaslat: "Aramayı Başlat",
      },
    },
    varyantliStokEkle: {
      baslik: "Varyantlı Stok Ekle",
      kaydetButton: "Kaydet",
      urunAdi: "Ürün Adı",
      aciklama: "Açıklama",
      stokVaryantlari: "Stok Varyantları",
      varyantOlusturButton: "Varyant Oluştur",
      varyantGrup: {
        barkod: "Barkod",
        gtin: "Gtin",
        kod: "Kod",
        raf: "Raf",
        aciklama: "Açıklama",
        stokAlisFiyati: {
          vergilerHaricAlisFiyati: "Vergiler Hariç Alış Fiyatı",
          alisKdvOran: "Alış Kdv Oran",
          alisOtvOran: "Alış ÖTV Oran",
          vergilerDahilAlisFiyati: "Vergiler Dahil Alış Fiyatı",
          alisDovizTuru: "Alış Döviz Türü",
        },
        stokSatisFiyati: {
          vergilerHaricSatisFiyati: "Vergiler Hariç Satış Fiyatı",
          satisKdvOran: "Satış Kdv Oran",
          satisOtvOran: "Satış ÖTV Oran",
          vergilerDahilSatisFiyati: "Vergiler Dahil Satış Fiyatı",
          satisDovizTuru: "Satış Döviz Türü",
        },
      },
    },
    varyantliStokDuzenle: {
      baslik: "Varyantlı Stok Düzenle",
      urunAdi: "Ürün Adı",
      aciklama: "Açıklama",
      guncelleButon: "Güncelle",
    },
    varyantliStokDetay: {
      pageHeader: {
        baslik: "Varyantlı Stok Detay",
        varyantGrupEkle: "Varyant Grup Ekle",
      },
      components: {
        profil: {
          stokKategori: "Stok Kategori",
          stokBirim: "Stok Birim",
          aciklama: "Açıklama",
        },
        stokBakiye: {
          genelBakiye: "Genel Bakiye",
          varyant: "Varyant",
          giris: "Giriş",
          cikis: "Çıkış",
          bakiye: "Bakiye",
        },
        stokIslemVeVaryantListe: {
          islemler: "İşlemler",
          varyantlar: "Varyantlar",
        },
        stokIslemListe: {
          islemTarihi: "İşlem Tarihi",
          aciklama: "Açıklama",
          depo: "Depo",
          miktar: "Miktar",
          varyantGrup: "Varyant Grup",
          islemler: "İşlemler",
          faturaIslemi: "Fatura İşlemi",
        },
        stokVaryantGrupListe: {
          varyantGrup: "Varyant Grup",
          islemler: "İşlemler",
          ekstraVeri: {
            digerBilgiler: {
              baslik: "Diğer Bilgiler",
              barkod: "Barkod",
              gtin: "Gtin",
              kod: "Kod",
              raf: "Raf",
              aciklama: "Açıklama",
            },
            alisFiyatBilgileri: "Alış Fiyat Bilgileri",
            satisFiyatBilgileri: "Satış Fiyat Bilgileri",
            fiyatlandirma: {
              vergiHaricFiyat: "Vergi Hariç Fiyat",
              kdvOran: "KDV Oran",
              kdvTutar: "KDV Tutar",
              otvOran: "OTV Oran",
              otvTutar: "OTV Tutar",
              vergiDahilTutar: "Vergi Dahil Tutar",
            },
          },
        },
        islemButon: {
          girisYap: "Giriş Yap",
          cikisYap: "Çıkış Yap",
        },
        detayliArama: {
          baslik: "Detaylı Arama",
          islemTarihi: "İşlem Tarihi",
          varyantGrup: "Varyant Grup",
          depo: "Depo",
          islemTipi: "İşlem Tipi",
          tumIslemTipleriniGoster: "Tüm işlem tiplerini göster",
          girisIslemleriniGoster: "Giriş işlemlerini göster",
          cikisIslemleriniGoster: "Çıkış işlemlerini göster",
          minMiktar: "Min. Miktar",
          maxMiktar: "Max. Miktar",
          temizle: "Temizle",
          aramayiBaslat: "Aramayı Başlat",
        },
      },
    },
    stokIslem: {
      components: {
        stokIslemEkle: {
          baslik: "Stok İşlem Ekle",
          tarih: "Tarih",
          depo: "Depo",
          evrakNo: "Evrak No",
          aciklama: "Açıklama",
          islemTipi: "İşlem Tipi",
          islemTipiSelect: {
            giris: "Giriş",
            cikis: "Çıkış",
          },
          miktar: "Miktar",
        },
        stokIslemDuzenle: {
          baslik: "Stok İşlem Düzenle",
          tarih: "Tarih",
          depo: "Depo",
          evrakNo: "Evrak No",
          aciklama: "Açıklama",
          islemTipi: "İşlem Tipi",
          islemTipiSelect: {
            giris: "Giriş",
            cikis: "Çıkış",
          },
          miktar: "Miktar",
        },
      },
    },
    stokVaryantGrupEkle: {
      varyantTurUyari: "Her varyant türünden bir tane seçebilirsiniz",
      pageHeader: {
        baslik: "Varyant Grup Ekle",
        kaydet: "Kaydet",
      },
      varyantGrupSeciniz: "Varyant Grup Seçiniz",
      varyantGrupOlustur: "Varyant Grup Oluştur",
      varyantGrup: {
        barkod: "Barkod",
        gtin: "Gtin",
        kod: "Kod",
        raf: "Raf",
        aciklama: "Açıklama",
        stokAlisFiyati: {
          vergilerHaricAlisFiyati: "Vergiler Hariç Alış Fiyatı",
          alisKdvOran: "Alış Kdv Oran",
          alisOtvOran: "Alış ÖTV Oran",
          vergilerDahilAlisFiyati: "Vergiler Dahil Alış Fiyatı",
          alisDovizTuru: "Alış Döviz Türü",
        },
        stokSatisFiyati: {
          vergilerHaricSatisFiyati: "Vergiler Hariç Satış Fiyatı",
          satisKdvOran: "Satış Kdv Oran",
          satisOtvOran: "Satış ÖTV Oran",
          vergilerDahilSatisFiyati: "Vergiler Dahil Satış Fiyatı",
          satisDovizTuru: "Satış Döviz Türü",
        },
      },
    },
    varyantliStokIslem: {
      components: {
        stokIslemEkle: {
          baslik: "Stok İşlem Ekle",
          tarih: "Tarih",
          varyantGrupSeciniz: "Varyant Grup Seçiniz",
          depo: "Depo",
          evrakNo: "Evrak No",
          aciklama: "Açıklama",
          islemTipi: "İşlem Tipi",
          islemTipiSelect: {
            giris: "Giriş",
            cikis: "Çıkış",
          },
          miktar: "Miktar",
        },
        stokIslemDuzenle: {
          baslik: "Stok İşlem Düzenle",
          tarih: "Tarih",
          varyantGrupSeciniz: "Varyant Grup Seçiniz",
          depo: "Depo",
          evrakNo: "Evrak No",
          aciklama: "Açıklama",
          islemTipi: "İşlem Tipi",
          islemTipiSelect: {
            giris: "Giriş",
            cikis: "Çıkış",
          },
          miktar: "Miktar",
        },
      },
    },

    firmaBilgileri: {
      components: {
        pageHeader: {
          baslik: "Firma Bilgileri",
          altBaslik: "",
        },
        firmaBilgileriForm: {
          unvan: "Ünvan",
          yetkili: "Yetkili",
          vergiDairesi: "Vegi Dairesi",
          vergiNumarasi: "Vergi Numarası",
          sektor: "Sektör",
          eMail: "E-Mail",
          telefon: "Telefon",
          cepTelefonu: "Cep Telefonu",
          sehir: "Şehir",
          ilce: "İlçe",
          adres: "Adres",
          guncelleButton: "Güncelle",
          guncellemeUyari:
            "Firma bilgilerini güncellemek için sistem yöneticinize başvurunuz.",
        },
      },
    },
    sifremiDegistir: {
      components: {
        pageHeader: {
          baslik: "Şifremi Değiştir",
          altBaslik: "",
        },
        sifremiDegistirForm: {
          mevcutSifre: "Mevcut Şifre",
          yeniSifre: "Yeni Şifre",
          yeniSifreTekrar: "Yeni Şifre Tekrar",
          guncelleButton: "Güncelle",
          sifreUyusmuyor: "Yeni şifre ile yeni şifre tekrar uyuşmuyor",
        },
      },
    },
    cariRapor: {
      components: {
        pageHeader: {
          baslik: "Cari Rapor",
          altBaslik: "",
        },
        cariRaporForm: {
          tarihSeciniz: "Tarih Seçiniz",
          raporAlButon: "Cari Rapor Al",
        },
      },
    },
    cariIslemRapor: {
      components: {
        pageHeader: {
          baslik: "Cari İşlem Raporu",
          altBaslik: "",
        },
        cariIslemRaporForm: {
          tarihSeciniz: "Tarih Seçiniz",
          cariSeciniz: "Cari Seçiniz",
          cariSecinizButon: "Cari Seçiniz",
          siralamaSekli: "Sıralama Şekli",
          siralamaTuru: "Sıralama Türü",
          islemTipi: "İşlem Tipi",
          odemeSekliTip: "Ödeme Şekli Tipi",
          islemTipItem: {
            tumIslemler: "Tüm İşlemler",
            tahsilat: "Tahsilat",
            odeme: "Ödeme",
            alacak: "Alacaklandırma",
            borclandirma: "Borçlandırma",
          },
          odemeSekliTipItem: {
            tumIslemler: "Tüm İşlemler",
            acikHesap: "Açık Hesap",
            nakit: "Nakit",
            krediKarti: "Kredi Kartı",
            havale: "Havale",
          },
          raporAlButon: "Cari İşlem Raporu Al",
        },
      },
    },
    cariEkstreRapor: {
      components: {
        pageHeader: {
          baslik: "Cari Ekstre Raporu",
          altBaslik: "",
        },
        cariEkstreRaporForm: {
          tarihSeciniz: "Tarih Seçiniz",
          raporAlButon: "Cari Ekstre Raporu Al",
          gecmisBakiyeDahilEt: "Geçmiş Bakiyeyi Dahil Et",
        },
      },
    },
    kasaRapor: {
      components: {
        pageHeader: {
          baslik: "Kasa Raporu",
          altBaslik: "",
        },
        kasaRaporForm: {
          tarihSeciniz: "Tarih Seçiniz",
          kasaSeciniz: "Kasa Seçiniz",
          raporAlButon: "Kasa Raporu Al",
        },
      },
    },
    bankaRapor: {
      components: {
        pageHeader: {
          baslik: "Banka Raporu",
          altBaslik: "",
        },
        bankaRaporForm: {
          tarihSeciniz: "Tarih Seçiniz",
          bankaHesapSeciniz: "Banka Hesap Seçiniz",
          raporAlButon: "Banka Raporu Al",
        },
      },
    },
    stokRapor: {
      baslik: "Stok Raporu",
      stokRaporuAl: "Stok Raporu Al",
    },
    stokHareketRapor: {
      baslik: "Stok Hareket Raporu",
      tarihSeciniz: "Tarih Seçiniz",
      stokHareketRaporuAl: "Stok Hareket Raporu Al",
    },
    stokEkstreRapor: {
      baslik: "Stok Ekstre Raporu",
      tarihSeciniz: "Tarih Seçiniz",
      gecmisBakiyeDahilEt: "Geçmiş Bakiyeyi Dahil Et",
      stokEkstreRaporuAl: "Stok Ekstre Raporu Al",
    },
    faturaRapor: {
      baslik: "Fatura Raporu",
      tarihSeciniz: "Tarih Seçiniz",
      odemeSekliSeciniz: "Ödeme Şekli Seçiniz",
      faturaRaporuAl: "Fatura Raporu Al",
    },
    cariGrupSelectOption: {
      cariGrupPlaceHolder: "Cari grup seçiniz",
      cariGrupBaslik: "Cari Grup",
    },
    dovizSelectOption: {
      dovizPlaceHolder: "Döviz seçiniz",
      dovizBaslik: "Döviz",
    },
    bankaSelectOption: {
      bankaPlaceHolder: "Banka seçiniz",
      bankaBaslik: "Banka",
    },
    cariKartSelectOption: {
      cariPlaceHolder: "Carikart arama...",
      cariBaslik: "CariKart",
    },
    stokKategoriSelectOption: {
      stokKategoriPlaceHolder: "Stok Kategori Seçiniz...",
      stokKategoriBaslik: "Stok Kategori",
    },
    stokBirimSelectOption: {
      stokBirimPlaceHolder: "Stok Birim Seçiniz...",
      stokBirimBaslik: "Stok Birim",
    },
    depoSelectOption: {
      depoPlaceHolder: "Depo seçiniz",
    },
    fetchListContent: {
      aramaPlaceHolder: "arama...",
    },
    page404: {
      mesajBaslik: "Üzgünüz, ziyaret ettiğiniz sayfa bulunamadı.",
      geriDonButton: "Geri dön",
    },
    odemeSekliSelectOption: {
      odemeSekliPlaceHolder: "Ödeme şekli seçiniz...",
      acikHesap: "Açık Hesap",
      nakit: "Nakit",
      krediKarti: "Kredi Kartı",
      havale: "Havale",
    },
    faturaSelectOption: {
      faturaTip: "Fatura Tip",
      faturaTipPlaceHolder: "Fatura tip seçiniz...",
      satisFaturasi: "Satış Faturası",
      alisFaturasi: "Alış Faturası",
      satisIadeFaturasi: "Satış İade Faturası",
      alisIadeFaturasi: "Alış İade Faturası",
    },
    kasaSelectOption: {
      placeHolder: "Kasa seçiniz...",
    },
    bankaHesapSelectOption: {
      placeHolder: "Banka hesap seçiniz...",
    },
    stokSelectOption: {
      stokPlaceHolder: "Ürün/Hizmet arama...",
      stokBaslik: "Ürün/Hizmet",
    },
    stokVaryantGrupSelectOption: {
      placeHolder: "Varyant grup seçiniz...",
    },
    stokVaryantSelectOption: {
      placeHolder: "Varyant seçiniz...",
    },
    faturaLayout: {
      faturaHeader: {
        aciklama: "Fatura Açıklaması",
        aciklamaPlaceHolder: "fatura açıklaması giriniz...",
        musteriSeciniz: "Müşteri Seçiniz",
        musteriArama: "müşteri arama...",
        dovizTuru: "Döviz Türü",
        odemeSekli: "Ödeme Şekli",
        kasa: "Kasa",
        bankaHesap: "Banka Hesap",
        duzenlemeTarihi: "Düzenleme Tarihi",
        vadeTarihi: "Vade Tarihi",
        sevkTarihi: "Sevk Tarihi",
        faturaNo: "Fatura No",
        faturaNoGiriniz: "fatura no giriniz...",
        sevkNo: "Sevk No",
        sevkNoPlaceHolder: "sevk no giriniz...",
      },
      faturaSatir: {
        urunHizmet: "Ürün/Hizmet",
        miktar: "Miktar",
        depo: "Depo",
        birimFiyat: "Birim Fiyat",
        kdv: "KDV",
        genelToplam: "Genel Toplam",
        yeniHizmetUrunEkle: "Yeni Hizmet/Ürün Ekle",
        aciklama: "Açıklama",
        miktarGerekliBirAlan: "Miktar gerekli bir alan",
        birimFiyatGerekliBirAlan: "Birim fiyat gerekli bir alan",
        kdvGerekliBirAlan: "KDV gerekli bir alan",
        indirimGerekliBirAlan: "İndirim gerekli bir alan",
        indirim: "indirim",
        tutar: "Tutar",
        otvGerekliBirAlan: "ÖTV gerekli bir alan",
        otv: "ÖTV",
        otvOran: "ÖTV Oran",
        genelToplamGerekliBirAlan: "Genel toplam gerekli bir alan",
        toplam: "Toplam",
        aciklamaEkle: "Açıklama Ekle",
        indirimEkle: "İndirim Ekle",
        otvEkle: "ÖTV Ekle",
      },
      faturaFooter: {
        araToplam: "Ara Toplam",
        araToplamIndirimEkle: "Ara Toplam İndirimi Ekle",
        stopajUygula: "Stopaj Uygula",
        satirIndirimi: "Satır İndirimi",
        araToplamIndirim: "Ara Toplam İndirim",
        tutar: "Tutar",
        brutToplam: "Brüt Toplam",
        stopajOran: "Stopaj Oran",
        toplamOtv: "Toplam ÖTV",
        toplamKdv: "Toplam KDV",
        tumuneTevkifatUygula: "Tümüne Tevkifat Uygula",
        tevkifatUygula: "Tevkifat Uygula",
        kdvTevkifati: "KDV Tevkifatı",
        genelToplam: "Genel Toplam",
      },
    },
    faturaEkle: {
      kaydetButon: "Kaydet",
    },
    faturaDuzenle: {
      baslik: "Fatura Düzenle",
      guncelleButon: "Güncelle",
    },
    teklifSipariListe: {
      baslik: "Teklif & Siparişler",
      components: {
        teklifSiparisListeTable: {
          firmaAdi: "Firma Adı",
          evrakNo: "Evrak No",
          evrakTipi: "Evrak Tipi",
          evrakTarihi: "Evrak Tarihi",
          genelToplam: "Genel Toplam",
          islemler: "İşlemler",
          evrakTip: {
            teklif: "Teklif",
            siparis: "Sipariş",
          },
        },
        islemButton: {
          yeniTeklif: "Yeni Teklif",
          yeniSiparis: "Yeni Sipariş",
        },
        detayliArama: {
          baslik: "Detaylı Arama",
          islemTarihi: "İşlem Tarihi",
          evrakTipi: "Evrak Tipi",
          tumEvraklariGoster: "Tüm Evrakları Göster",
          teklifleriGoster: "Teklifleri Göster",
          siparisleriGoster: "Siparişleri Göster",
          minTutar: "Min. Tutar",
          maxTutar: "Max. Tutar",
          doviz: "Döviz",
          temizle: "Temizle",
          aramayiBaslat: "Aramayı Başlat",
        },
      },
    },
    teklifSiparisLayout: {
      teklifsSiparisHeader: {
        aciklama: "Açıklama",
        aciklamaPlaceHolder: "açıklaması giriniz...",
        musteriSeciniz: "Müşteri Seçiniz",
        musteriArama: "müşteri arama...",
        dovizTuru: "Döviz Türü",
        duzenlemeTarihi: "Düzenleme Tarihi",
        gecerlilikTarihi: "Geçerlilik Tarihi",
        evrakNo: "Evrak No",
        evrakNoGiriniz: "evrak no giriniz...",
      },
      teklifSiparisSatir: {
        urunHizmet: "Ürün/Hizmet",
        miktar: "Miktar",
        birimFiyat: "Birim Fiyat",
        kdv: "KDV",
        genelToplam: "Genel Toplam",
        yeniHizmetUrunEkle: "Yeni Hizmet/Ürün Ekle",
        aciklama: "Açıklama",
        miktarGerekliBirAlan: "Miktar gerekli bir alan",
        birimFiyatGerekliBirAlan: "Birim fiyat gerekli bir alan",
        kdvGerekliBirAlan: "KDV gerekli bir alan",
        indirimGerekliBirAlan: "İndirim gerekli bir alan",
        indirim: "indirim",
        tutar: "Tutar",
        otvGerekliBirAlan: "ÖTV gerekli bir alan",
        otv: "ÖTV",
        otvOran: "ÖTV Oran",
        genelToplamGerekliBirAlan: "Genel toplam gerekli bir alan",
        toplam: "Toplam",
        aciklamaEkle: "Açıklama Ekle",
        indirimEkle: "İndirim Ekle",
        otvEkle: "ÖTV Ekle",
      },
      teklifSiparisFooter: {
        araToplam: "Ara Toplam",
        araToplamIndirimEkle: "Ara Toplam İndirimi Ekle",
        stopajUygula: "Stopaj Uygula",
        satirIndirimi: "Satır İndirimi",
        araToplamIndirim: "Ara Toplam İndirim",
        tutar: "Tutar",
        brutToplam: "Brüt Toplam",
        stopajOran: "Stopaj Oran",
        toplamOtv: "Toplam ÖTV",
        toplamKdv: "Toplam KDV",
        tumuneTevkifatUygula: "Tümüne Tevkifat Uygula",
        tevkifatUygula: "Tevkifat Uygula",
        kdvTevkifati: "KDV Tevkifatı",
        genelToplam: "Genel Toplam",
      },
    },
    teklifSiparisEkle: {
      kaydetButon: "Kaydet",
    },
    teklifSiparisDuzenle: {
      baslik: "Teklif Sipariş Düzenle",
      guncelleButon: "Güncelle",
    },
    varsayilanAyarlar: {
      baslik: "Varsayılan Ayarlar",
      stokBirim: "Stok Birim",
      stokKategori: "Stok Kategori",
      stokDepo: "Stok Depo",
      banka: "Banka",
      basamakSayisi: "Basamak Sayısı",
      basamakSayisiPlaceHolder: "Basamak sayısı seçiniz",
      odemeSekli: "Ödeme Şekli",
      doviz: "Döviz",
      kasa: "Kasa",
      bankaHesap: "Banka Hesap",
      kaydet: "Kaydet",
    },
  },
  en: {
    logoName: "EminHesap",
    sideBar: {
      panel: "Dashboard",
      cariKart: "Customers",
      faturalar: "Invoice",
      stoklar: "Stocks",
      kasalar: "Case",
      bankalar: "Bank",
      raporlar: "Reports",
      raporlarAltMenu: {
        cariRapor: "Customer Report",
        cariIslemRapor: "Customer Detail Report",
        cariEksteRapor: "Customer Extract Report",
        kasaDetayRapor: "Case Detail Report",
        bankaDetayRapor: "Bank Detail Report",
        stokRapor: "Stock Report",
        faturaRapor: "Invoice Report",
      },
    },
    navBar: {
      temaSecim: {
        aktif: "Dark Mode Active",
        pasif: "Dark Mode Passive",
      },
      donemSecim: {
        baslik: "Period",
      },
      kullaniciAyarSecim: {
        firmaBilgileri: "Company Information",
        sifremiDegistir: "Change Password",
        ayarlar: "Settings",
        cikisYap: "Sign Out",
      },
    },
    panel: {
      baslik: "Current Status",
      components: {
        vadeliTahsilatCicleProgress: {
          baslik: "Collections",
          toplamTahsilEdilecek: "Total to be charged",
          vadesiGecmisTahsilatlar: "Out of day collection",
          genelToplam: "Grand total",
        },
        vadeliOdemeCicleProgress: {
          baslik: "Payments",
          toplamTahsilEdilecek: "Total payable",
          vadesiGecmisTahsilatlar: "Out of day payments",
          genelToplam: "Grand total",
        },
        tahsilatOdemeBarChart: {
          baslik: "14 Days Collection & Payment",
          tahsilat: "Collection",
          odeme: "Payment",
          tarih: "date",
        },
        bankaBakiyeleri: {
          baslik: "Bank Balances",
          bankaAdi: "Bank Name",
          bakiye: "Balance",
        },
        kasaBakiyeleri: {
          baslik: "Case Balances",
          kasaAdi: "Case Name",
          bakiye: "Balance",
        },
      },
    },
    cariKartListe: {
      components: {
        pageHeader: {
          baslik: "Customers",
          altBaslik: "Customers list",
          cariEkleButon: "Add Customer",
        },
        cariKartListTable: {
          aramaPlaceHolder: "search company, title, phnone number, city...",
          firmaAdi: "Company Name",
          cariKodu: "Customer Code",
          sonIslemTarihi: "Last Operation Date",
          bakiye: "Balance",
          islemler: "Operations",
        },
      },
    },
    cariDetay: {
      components: {
        pageHeader: {
          baslik: "Customer Detail",
          altBaslik: "create collection, pay, credit, debt, invoice...",
          cariDuzenle: "Edit Customer",
        },
        profil: {
          alacak: "receivable",
          borc: "debt",
          toplam: "total",
          tumBilgileriGoster: "All Information",
        },
        profilDetay: {
          baslik: "Customer information",
          tabFirmaBilgileri: "Company information",
          firmaAdi: "Company Name",
          unvan: "Title",
          cariGrup: "Customer Group",
          telefon: "Phone Number",
          cepTelefonu: "Mobile Phone Number",
          cariKod: "Customer Code",
          eMail: "EMail",
          webAdresi: "Web Address",
          vergiDairesi: "Taxt Administration",
          vergiNumarasi: "Taxt Number",
          il: "City",
          ilce: "District",
          adres: "Address",
          sevkAdresi: "Dispatch Address",
          kapatButon: "Close",
        },
        islemMenu: {
          baslik: "Customer Operations",
          tahsilatYap: "Add Collection",
          odemeYap: "Add Pay",
          alacaklandir: "Add Credit",
          borclandir: "Add Debt",
          yeniSatisFaturasi: "Add New Sales Invoice",
          yeniAlisFaturasi: "Add New Buying Invoice",
        },
        cariIslemList: {
          baslik: "Customer Operation",
          aramaPlaceHolder: "search document, description, amount...",
          tarih: "Date",
          vadeTarihi: "Expiry Date",
          evrakNo: "Document No",
          aciklama: "Description",
          tutar: "Amount",
          islemler: "Operations",
        },
      },
    },
    faturaListe: {
      components: {
        pageHeader: {
          baslik: "Invoices",
          altBaslik: "invoices list",
          faturaEkleButon: "Add Invoice",
        },
      },
    },
    tarih: {
      ay: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      hafta: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      format: "DD-MM-YYYY",
      baslangicTarih: "Start Date",
      bitisTarihi: "End Date",
      tarihSeciniz: "Select Date...",
    },
    cariKartEkle: {
      components: {},
    },
    cariKartDuzenle: {
      components: {},
    },
    cariKartSil: {
      baslik: "Warning",
      yazi: "your customer will be permanently deleted. Do you confirm?",
      iptalButon: "cancel",
      onayButon: "ok",
    },
    cariIslem: {
      alacaklandirmaEkle: {
        baslik: "Add Credit",
        vadeTarihi: "Expiry Date",
        islemTarihi: "Date",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "select document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        dovizTuru: "Currency Type",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        iptalButon: "Cancel",
        kaydetButon: "Save",
      },
      borclandirmaEkle: {
        baslik: "Add Debt",
        vadeTarihi: "Expiry Date",
        islemTarihi: "Date",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "select document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        dovizTuru: "Currency Type",
        odemeSekli: "Ödeme Şekli",
        odemeSekliPlaceHolder: "ödeme şekli seçiniz",
        kasaSec: "Kasa Seç",
        kasaSecPlaceholder: "kasa seçiniz",
        bankaSec: "banka seçiniz",
        bankaSecPlaceHolder: "banka seçiniz...",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        iptalButon: "Cancel",
        kaydetButon: "Save",
      },
      tahsilatEkle: {
        baslik: "Add Collection",
        islemTarihi: "Date",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "write document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        dovizTuru: "Currency Type",
        odemeSekli: "Payment Type",
        odemeSekliPlaceHolder: "select payment type...",
        kasaSec: "Select Case",
        kasaSecPlaceholder: "select case...",
        bankaSec: "Select Bank",
        bankaSecPlaceHolder: "select bank...",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        iptalButon: "Cancel",
        kaydetButon: "Save",
      },
      odemeEkle: {
        baslik: "Add Pay",
        islemTarihi: "Date",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "write document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        dovizTuru: "Currency Type",
        odemeSekli: "Payment Type",
        odemeSekliPlaceHolder: "select payment type...",
        kasaSec: "Select Case",
        kasaSecPlaceholder: "select case...",
        bankaSec: "Select Bank",
        bankaSecPlaceHolder: "select bank...",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        iptalButon: "Cancel",
        kaydetButon: "Save",
      },
    },
    kasaListe: {
      components: {
        pageHeader: {
          baslik: "Cases",
          altBaslik: "cases list",
          kasaEkleButon: "Add Case",
        },
        kasaListe: {
          kasaAdi: "Case Name",
          bakiye: "Remainder",
          islemler: "Operations",
        },
      },
    },
    kasaEkle: {
      components: {
        kasaEkleModal: {
          baslik: "Add Case",
          kasaAdi: "Case Name",
          kasaAdiPlaceHolder: "write case name...",
          dovizTuru: "Currency Type",
          dovizTuruPlaceHolder: "select currency type...",
          kaydetButon: "Save",
          iptalButon: "Cancel",
        },
      },
    },
    kasaDuzenle: {
      baslik: "Update Case",
      kasaAdi: "Case Name",
      kasaAdiPlaceHolder: "write case name...",
      kaydetButon: "Update",
    },
    kasaSil: {
      baslik: "Warning",
      yazi: "The case will be deleted permanently. Do you confirm?",
      iptalButon: "cancel",
      onayButon: "ok",
    },
    kasaDetay: {
      components: {
        pageHeader: {
          baslik: "Case Detail",
          altBaslik: "inflow, out, transfer operations...",
          kasaDuzenle: "Edit Case",
        },
        profil: {
          giris: "inflow",
          cikis: "out",
          toplam: "total",
          dovizTuru: "Currenct Type",
        },
        kasaIslemListe: {
          baslik: "Case Transaction",
          aramaPlaceHolder: "search document no, description, amount...",
          tarih: "Date",
          evrakNo: "Document No",
          unvan: "Title",
          aciklama: "Description",
          tutar: "Amount",
          islemler: "Operations",
        },
        islemMenu: {
          baslik: "Case Operations",
          girisYap: "Add Inflow",
          cikisYap: "Add Out",
          kasayaVirman: "Case Tranfer",
          bankayaVirman: "Bank Transfer",
        },
      },
    },
    kasaIslem: {
      girisYap: {
        baslik: "Add Inflow Case",
        islemTarihi: "Date",
        islemTarihiPlaceHolder: "select date...",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "write document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        islemTipi: "Operation Type",
        odemeTipi: "Payment Type",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        onayButon: "Ok",
        iptalButon: "Cancel",
      },
      cikisYap: {
        baslik: "Add Out Case",
        islemTarihi: "Date",
        islemTarihiPlaceHolder: "select date...",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "write document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        islemTipi: "Operation Type",
        odemeTipi: "Payment Type",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        onayButon: "Ok",
        iptalButon: "Cancel",
      },
      kasayaVirman: {
        baslik: "Case to Case Transfer",
        islemTarihi: "Date",
        islemTarihiPlaceHolder: "select date...",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "write document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        kasaSec: "Select Case",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        onayButon: "Ok",
        iptalButon: "Cancel",
      },
      bankayaVirman: {
        baslik: "Case to Bank Transfer",
        islemTarihi: "Date",
        islemTarihiPlaceHolder: "select date...",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "write document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        bankaSec: "Select Bank",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        onayButon: "Ok",
        iptalButon: "Cancel",
      },
      virmanDuzenle: {
        baslik: "Virman Düzenle",
      },
    },
    banka: {
      components: {
        pageHeader: {
          baslik: "Bank",
          altBaslik: "banks and bank Accounts",
        },
        menu: {
          bankaHesaplari: "Bank Accounts",
          bankaListesi: "Bank Lists",
        },
      },
    },
    bankaHesapListe: {
      components: {
        bankaHesapListe: {
          baslik: "Bank Account List",
          hesapAdi: "Account Name",
          bankaAdi: "Bank Name",
          sube: "Branch",
          hesapNo: "Account No",
          bakiye: "Balance",
          islemler: "Operations",
          bankaHesapEkleButon: "Add Bank Account",
        },
      },
    },
    bankaEkle: {
      components: {
        bankaEkleModal: {
          baslik: "Add Bank",
          bankaAdi: "Bank Name",
          bankaAdiPlaceHolder: "write bank name...",
          kaydetButon: "Save",
          iptalButon: "Cancel",
        },
      },
    },
    bankaHesapEkle: {},
    bankaListe: {
      components: {
        bankaListe: {
          baslik: "Bank List",
          bankaAdi: "Bank Name",
          islemler: "Operations",
          bankaEkleButon: "Add Bank",
        },
      },
    },
    bankaDuzenle: {
      components: {
        bankaDuzenleModal: {
          baslik: "Bank Update",
          bankaAdi: "Bank Name",
          bankaAdiPlaceHolder: "write bank name...",
          kaydetButon: "Update",
          iptalButon: "Cancel",
        },
      },
    },
    bankaSil: {
      components: {
        bankaSilModal: {
          baslik: "Bank Delete",
          yazi: "The bank will be deleted permanently. Do you confirm?",
          kaydetButon: "Delete",
          iptalButon: "Cancel",
        },
      },
    },
    bankaHesapSil: {},
    bankaHesapDuzenle: {},
    bankaHesapDetay: {
      components: {
        pageHeader: {
          baslik: "Bank Account Detail",
          altBaslik: "inflow, out, tranfer operations...",
          bankaHesapDuzenleButon: "Update Bank Account",
        },
        profil: {
          giris: "Inflow",
          cikis: "Out",
          toplam: "Total",
          tumBilgileriGoster: "All Information",
        },
        profilDetay: {
          baslik: "Bank Account Information",
          bankaAdi: "Bank Name",
          hesapAdi: "Account Name",
          subeAdi: "Branch Name",
          subeNo: "Branch No",
          hesapNo: "Account No",
          ibanNo: "Iban No",
          dovizTuru: "Currency Type",
          kapatButon: "Close",
        },
        bankaHesapIslemListe: {
          baslik: "Bank Account Operations",
          aramaPlaceHolder: "search document no, description, amount...",
          tarih: "Date",
          evrakNo: "Document No",
          unvan: "Title",
          aciklama: "Description",
          tutar: "Amount",
          islemler: "Operations",
        },
        islemMenu: {
          baslik: "Bank Operations",
          girisYap: "Add Inflow",
          cikisYap: "Add Out",
          kasayaVirman: "Case Tranfer",
          bankayaVirman: "Bank Transfer",
        },
      },
    },
    bankaIslem: {
      girisYap: {
        baslik: "Add Inflow Bank",
        islemTarihi: "Date",
        islemTarihiPlaceHolder: "select date...",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "write document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        islemTipi: "Operation Type",
        odemeTipi: "Payment Type",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        onayButon: "Ok",
        iptalButon: "Cancel",
      },
      cikisYap: {
        baslik: "Add Out Bank",
        islemTarihi: "Date",
        islemTarihiPlaceHolder: "select date...",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "write document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        islemTipi: "Operation Type",
        odemeTipi: "Payment Type",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        onayButon: "Ok",
        iptalButon: "Cancel",
      },
      kasayaVirman: {
        baslik: "Bank to Case Transfer",
        islemTarihi: "Date",
        islemTarihiPlaceHolder: "select date...",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "write document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        kasaSec: "Select Case",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        onayButon: "Ok",
        iptalButon: "Cancel",
      },
      bankayaVirman: {
        baslik: "Bank to Bank Transfer",
        islemTarihi: "Date",
        islemTarihiPlaceHolder: "select date...",
        evrakNo: "Document No",
        evrakNoPlaceHolder: "write document no...",
        aciklama: "Description",
        aciklamaPlaceHolder: "write description...",
        bankaSec: "Select Bank",
        tutar: "Amount",
        tutarPlaceHolder: "write amount...",
        onayButon: "Ok",
        iptalButon: "Cancel",
      },
    },
    girisYap: {
      components: {
        girisYapForm: {
          eMailPlaceHolder: "write email address...",
          sifrePlaceHolder: "write password...",
          girisYapButon: "Login",
          kayitOlButon: "register for free now",
          eMailVeyaSifreHatali: "email or password icorrect",
          kurallar: {
            emailZorunlu: "email field is required!",
            emailFormat: "email format incorrect!",
            sifreZorunlu: "password field is required!",
            sifreMinUzunluk: "password must be at least 6 digits!",
          },
        },
        kayitOlButton: {
          butonYazi: "Register",
        },
        iletisimBilgi: {
          telefonNumarasi: "447 154 32 22",
        },
        sifremiUnuttumButon: {
          butonYazi: "I forgot my password",
        },
      },
    },
    kayitOl: {
      slogan: "Write email and password for free register",
      components: {
        kayitOlForm: {
          eMailPlaceHolder: "write email address...",
          sifrePlaceHolder: "write password...",
          sifreTekrarPlaceHolder: "write confirm password...",
          kayitOlButon: "register for free now",
          kurallar: {
            emailZorunlu: "email field is required!",
            emailFormat: "email format incorrect!",
            sifreZorunlu: "password field is required!",
            sifreMinUzunluk: "password must be at least 6 digits!",
            sifreOnay: "The two passwords that you entered do not match!",
          },
        },
      },
    },
    sifremiUnuttum: {
      slogan:
        "We can send the information required to renew your password to your registered e-mail address.",
      components: {
        sifremiUnuttumForm: {
          eMailPlaceHolder: "write email address...",
          sifremiUnuttumButon: "Send Mail",
          kurallar: {
            emailZorunlu: "email field is required!",
            emailFormat: "email format incorrect!",
          },
        },
      },
    },
    stok: {
      components: {
        pageHeader: {
          baslik: "Stock",
          altBaslik:
            "stock lists, stock groups, stock unit, stock property,stock discount, store lists",
        },
        menu: {
          stokListesi: "Stock Lists",
          stokGruplari: "Stock Groups",
          stokBirimleri: "Stock Units",
          depoListesi: "Store Lists",
        },
      },
    },
    stokDepoListe: {
      components: {
        stokDepoListe: {
          baslik: "Store List",
          depoAd: "Name",
          islemler: "Operations",
          depoEkleButon: "Add Store",
        },
      },
    },
    stokDepoEkle: {
      components: {
        stokDepoEkleModal: {
          baslik: "Add Store",
          depoAd: "Store Name",
          depoAdPlaceHolder: "write store name...",
          onayButon: "Save",
          iptalButon: "Cancel",
        },
      },
    },
    stokDepoDuzenle: {
      components: {
        stokDepoDuzenleModal: {
          baslik: "Update Store",
          depoAd: "Store Name",
          depoAdPlaceHolder: "write store name...",
          onayButon: "Update",
          iptalButon: "Cancel",
        },
      },
    },
    stokDepoSil: {
      components: {
        stokDepoSilModal: {
          baslik: "Delete Store",
          yazi: "The store will be permanently deleted. Do you confirm?",
          onayButon: "Delete",
          iptalButon: "Cancel",
        },
      },
    },
    stokBirimListe: {
      components: {
        stokBirimListe: {
          baslik: "Stock Unit List",
          birimAd: "Name",
          islemler: "Operations",
          birimEkleButom: "Add Unit",
        },
      },
    },
    stokBirimEkle: {
      components: {
        stokBirimEkleModal: {
          baslik: "Add Stock Unit",
          birimAd: "Unit Name",
          birimAdPlaceHolder: "write unit name...",
          onayButon: "Save",
          iptalButon: "Cancel",
        },
      },
    },
    stokBirimDuzenle: {
      components: {
        stokBirimEkleModal: {
          baslik: "Update Stock Unit",
          birimAd: "Unit Name",
          birimAdPlaceHolder: "write unit name...",
          onayButon: "Update",
          iptalButon: "Cancel",
        },
      },
    },
    stokBirimSil: {
      components: {
        stokBirimSilModal: {
          baslik: "Delete Stock Unit",
          yazi: "The stock unit will be permanently deleted. Do you confirm?",
          onayButon: "Delete",
          iptalButon: "Cancel",
        },
      },
    },
    stokGrupListe: {
      components: {
        stokGrupListe: {
          baslik: "Stock Group List",
          grupAd: "Name",
          islemler: "Operations",
          stokGrupEkleButon: "Add Stock Group",
        },
      },
    },
    stokGrupEkle: {
      components: {
        stokGrupEkleModal: {
          baslik: "Add Stock Group",
          grupAd: "Group Name",
          grupAdPlaceHolder: "write group name...",
          ustGrup: "Select Upper Group",
          ustGrupItem: "no upper group",
          iptalButon: "Cancel",
          onayButon: "Save",
        },
      },
    },
    stokGrupDuzenle: {
      components: {
        stokGrupDuzenleModal: {
          baslik: "Update Stock Group",
          grupAd: "Group Name",
          grupAdPlaceHolder: "write group name...",
          ustGrup: "Select Upper Group",
          ustGrupItem: "no upper group",
          iptalButon: "Cancel",
          onayButon: "Update",
        },
      },
    },
    stokGrupSil: {
      components: {
        stokGrupSilModal: {
          baslik: "Delete Stock Group",
          yazi: "The stock group will be permanently deleted. Do you confirm?",
          onayButon: "Delete",
          iptalButon: "Cancel",
        },
      },
    },
    stokListe: {
      components: {
        stokListe: {
          baslik: "Stock List",
          stokAd: "Stock Name",
          stokGrup: "Stock Group",
          barkod: "Barcode",
          dovizTuru: "Currency Type",
          fiyat: "Price",
          islemler: "Operations",
          stokEkleButon: "Add Stock",
        },
      },
    },
    stokEkle: {
      components: {
        stokEkleModal: {
          baslik: "Add Stock",
          stokAd: "Stock Name",
          stokAdPlaceHolder: "write stock name...",
          barkod: "Barcode",
          barkodPlaceHolder: "write barcode...",
          raf: "Shelf",
          rafPlaceHolder: "write shelf...",
          kod: "Code",
          kodPlaceHolder: "write code...",
          aciklama: "Description",
          aciklamaPlaceHolder: "write description...",
          otvOran: "OTV Rate",
          oivOran: "ÖIV Rate",
          kdvOran: "KDV Rate",
          alisFiyat: "Buying Price",
          satisFiyat: "Sale Price",
          iptalButon: "Cancel",
          onayButon: "Save",
        },
      },
    },
    stokDuzenle: {
      components: {
        stokDuzenleModal: {
          baslik: "Update Stock",
          stokAd: "Stock Name",
          stokAdPlaceHolder: "write stock name...",
          barkod: "Barcode",
          barkodPlaceHolder: "write barcode...",
          raf: "Shelf",
          rafPlaceHolder: "write shelf...",
          kod: "Code",
          kodPlaceHolder: "write code...",
          aciklama: "Description",
          aciklamaPlaceHolder: "write description...",
          otvOran: "OTV Rate",
          oivOran: "ÖIV Rate",
          kdvOran: "KDV Rate",
          alisFiyat: "Buying Price",
          satisFiyat: "Sale Price",
          iptalButon: "Cancel",
          onayButon: "Update",
        },
      },
    },
    stokSil: {
      components: {
        stokSilModal: {
          baslik: "Delete Stock",
          yazi: "The stock will be permanently deleted. Do you confirm?",
          onayButon: "Delete",
          iptalButon: "Cancel",
        },
      },
    },
    firmaBilgileri: {
      components: {
        pageHeader: {
          baslik: "Company Informations",
          altBaslik: "",
        },
        firmaBilgileriForm: {
          unvan: "Title",
          yetkili: "Ex cathedra",
          vergiDairesi: "Tax Office",
          vergiNumarasi: "Tax Number",
          eMail: "E-Mail",
          telefon: "Land Phone Number",
          cepTelefonu: "Phone Number",
          sehir: "City",
          ilce: "District",
          adres: "Address",
          guncelleButton: "Update",
        },
      },
    },
    sifremiDegistir: {
      components: {
        pageHeader: {
          baslik: "Change Password",
          altBaslik: "",
        },
        sifremiDegistirForm: {
          mevcutSifre: "Current Password",
          yeniSifre: "New Password",
          yeniSifreTekrar: "Confirm New Password",
          guncelleButton: "Update",
        },
      },
    },
    cariRapor: {
      components: {
        pageHeader: {
          baslik: "Customer Report",
          altBaslik: "",
        },
        cariRaporForm: {
          tarihSeciniz: "Choose Date",
          siralamaSekli: "Sort Shape",
          siralamaTuru: "Sort Type",
          raporTipi: "Report Type",
          siralamaSekliItem: {
            firmaAdinaGore: "By Company Name",
            cariGrubaGore: "By Customer Group",
          },
          raporTipItem: {
            tumCariler: "All Customers",
            alacakliCariler: "Payee Customer",
            borcluCariler: "Debtor Customer",
          },
          raporAlButon: "View Report",
        },
      },
    },
    cariIslemRapor: {
      components: {
        pageHeader: {
          baslik: "Customer Detail Report",
          altBaslik: "",
        },
        cariIslemRaporForm: {
          tarihSeciniz: "Choose Date",
          cariSeciniz: "Choose Customer",
          cariSecinizButon: "Choose Customer",
          tumCariler: "All Customers",
          siralamaSekli: "Sort Shape",
          siralamaTuru: "Sort Type",
          islemTipi: "Operation Type",
          siralamaSekliItem: {
            tariheGore: "By Date",
            firmaAdinaGore: "By Company Name",
          },
          islemTipItem: {
            tumCariler: "All Operation",
            tahsilat: "Collection",
            odeme: "Payment",
            alacak: "Credit",
            borclandirma: "Borrowing",
          },
          raporAlButon: "View Report",
        },
      },
    },
    cariEkstreRapor: {
      components: {
        pageHeader: {
          baslik: "Customer Extract Report",
          altBaslik: "",
        },
        cariEkstreRaporForm: {
          tarihSeciniz: "Choose Date",
          cariSeciniz: "Choose Customer",
          cariSecinizButon: "Choose Customer",
          tumCariler: "All Customers",
          siralamaSekli: "Sort Shape",
          siralamaTuru: "Sort Type",
          raporTipi: "Report Type",
          siralamaSekliItem: {
            firmaAdinaGore: "By Company Name",
            tariheGore: "By Date",
          },
          raporTipItem: {
            tumCariler: "All Customers",
            alacakliCariler: "Payee Customer",
            borcluCariler: "Debtor Customer",
          },
          raporAlButon: "View Report",
        },
      },
    },
    kasaRapor: {
      components: {
        pageHeader: {
          baslik: "Case Report",
          altBaslik: "",
        },
        kasaRaporForm: {
          tarihSeciniz: "Choose Date",
          kasaSeciniz: "Choose Case",
          kasaSecinizButon: "Choose Case",
          tumKasalar: "All Cases",
          siralamaSekli: "Sort Shape",
          siralamaTuru: "Sort Type",
          islemTipi: "Operation Type",
          siralamaSekliItem: {
            tariheGore: "By Date",
            kasaAdinaGore: "By Case Name",
          },
          islemTipItem: {
            tumKasalar: "All Operations",
            giris: "Inflow",
            cikis: "Out",
          },
          raporAlButon: "View Report",
        },
      },
    },
    bankaRapor: {
      components: {
        pageHeader: {
          baslik: "Bank Report",
          altBaslik: "",
        },
        bankaRaporForm: {
          tarihSeciniz: "Choose Date",
          bankaSeciniz: "Choose Bank",
          bankaSecinizButon: "Choose Bank",
          tumBankalar: "All Banks",
          siralamaSekli: "Sort Shape",
          siralamaTuru: "Sort Type",
          islemTipi: "Operation Type",
          siralamaSekliItem: {
            tariheGore: "By Date",
            bankaAdinaGore: "By Bank Name",
          },
          islemTipItem: {
            tumBankalar: "All Operations",
            giris: "Inflow",
            cikis: "Out",
          },
          raporAlButon: "View Report",
        },
      },
    },
  },
});

export default strings;
