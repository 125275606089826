import React, { useState } from "react";
import {
  Button,
  Space,
  Card,
  Popconfirm,
  notification,
  message,
  Tag,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import CursorTableItem from "../toolbox/CursorTableItem";
import strings from "../../utils/Localization";
import FetchListContent from "../toolbox/FetchListContent";
import { api } from "../../helpers/ApiHelper";
import StokVaryantEkleModal from "./StokVaryantEkleModal";
import StokVaryantDuzenleModal from "./StokVaryantDuzenleModal";
import StokVaryantDegerEkleModal from "../stokVaryantDeger/StokVaryantDegerEkleModal";
import StokVaryantDegerDuzenleModal from "../stokVaryantDeger/StokVaryantDegerDuzenleModal";

export default function StokVaryantListe() {
  const [dataSource, setDataSource] = useState({});
  const [stokVaryantGrupEkleModalVisible, setStokVaryantGrupEkleModalVisible] =
    useState(false);
  const [stokVaryantDuzenleModalVisible, setStokVaryantDuzenleModalVisible] =
    useState(false);
  const [duzenleData, setDuzenleData] = useState(null);
  const [
    stokVaryantDegerEkleModalVisible,
    setStokVaryantDegerEkleModalVisible,
  ] = useState(false);
  const [selectedStokVaryantId, setSelectedStokVaryantId] = useState(null);
  const [
    stokVaryantDegerDuzenleModalVisible,
    setStokVaryantDegerDuzenleModalVisible,
  ] = useState(false);
  const [varyantDegerDuzenleData, setVaryantDegerDuzenleData] = useState(null);
  const { stokVaryantListe: localization } = strings.stokVaryant.components;

  const columns = [
    {
      title: localization.varyantAdi,
      dataIndex: "ad",
      key: "ad",
      render: (text, row, index) => {
        const toplamStokSayisi = row.stokvaryantdeger
          .map((item) => item.stokSayisi)
          .reduce((a, b) => a + b, 0);
        return (
          <>
            <CursorTableItem hideHand text={`${text} (${toplamStokSayisi})`} />
            <Space>
              {row.stokvaryantdeger.map((degerItem, index) => {
                if (degerItem.isDeleted === false) {
                  return (
                    <Tag
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setVaryantDegerDuzenleData(degerItem);
                        setStokVaryantDegerDuzenleModalVisible(true);
                      }}
                      color="geekblue"
                    >
                      {`${degerItem.ad} (${degerItem.stokSayisi})`}
                    </Tag>
                  );
                } else return null;
              })}
            </Space>
          </>
        );
      },
    },
    {
      title: localization.islemler,
      dataIndex: "islemler",
      key: "islemler",
      render: (text, row, index) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setSelectedStokVaryantId(row.id);
              setStokVaryantDegerEkleModalVisible(true);
            }}
            type="default"
            shape="circle"
            icon={<PlusOutlined />}
            size={"large"}
          />
          <Button
            onClick={() => {
              setDuzenleData(row);
              setStokVaryantDuzenleModalVisible(true);
            }}
            type="default"
            shape="circle"
            icon={<EditOutlined />}
            size={"large"}
          />
          <Popconfirm
            title={strings.mesaj.silmeIslemUyari}
            onConfirm={() => {
              const toplamStokSayisi = row.stokvaryantdeger
                .map((item) => item.stokSayisi)
                .reduce((a, b) => a + b, 0);
              if (toplamStokSayisi > 0) {
                message.error({
                  content: strings.mesaj.stokVaryantSilme,
                  style: { marginTop: "20vh" },
                });
              } else remove(row.id);
            }}
          >
            <Button
              type="default"
              shape="circle"
              icon={<DeleteOutlined />}
              size={"large"}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const remove = async (stokVaryantId) => {
    try {
      const response = await api.delete(`/stokvaryant/${stokVaryantId}`, {});
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== stokVaryantId),
        });
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      <Card
        title={localization.baslik}
        extra={[
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => setStokVaryantGrupEkleModalVisible(true)}
          >
            {localization.varyantEkleButon}
          </Button>,
        ]}
      >
        <FetchListContent
          columns={columns}
          requestUrl="/stokvaryant/get"
          onDataSource={(e) => setDataSource(e)}
          dataSource={dataSource}
          requestBody={{
            SelectColumns: [
              {
                TableName: "",
                ColumnNames: ["ad", "id"],
              },
              {
                TableName: "stokvaryantdeger",
                ColumnNames: ["ad", "id", "isDeleted"],
              },
              {
                Operator: "Count",
                TableName:
                  "stokvaryantdeger.StokvaryantgrupHasStokvaryantdeger:stokSayisi",
              },
            ],
          }}
        />
      </Card>
      <StokVaryantEkleModal
        visible={stokVaryantGrupEkleModalVisible}
        handleCancel={() => setStokVaryantGrupEkleModalVisible(false)}
      />
      <StokVaryantDuzenleModal
        visible={stokVaryantDuzenleModalVisible}
        handleCancel={() => setStokVaryantDuzenleModalVisible(false)}
        data={duzenleData}
      />
      <StokVaryantDegerEkleModal
        visible={stokVaryantDegerEkleModalVisible}
        handleCancel={() => setStokVaryantDegerEkleModalVisible(false)}
        stokVaryantId={selectedStokVaryantId}
      />
      <StokVaryantDegerDuzenleModal
        visible={stokVaryantDegerDuzenleModalVisible}
        handleCancel={() => setStokVaryantDegerDuzenleModalVisible(false)}
        data={varyantDegerDuzenleData}
      />
    </>
  );
}
