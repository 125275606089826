import React from "react";
import { Modal, Row, Col, Divider, Button } from "antd";
import strings from "../../utils/Localization";
import { useSelector } from "react-redux";

const ModelItem = ({ title, text }) => (
  <div style={{ marginBottom: 16 }}>
    <strong style={{ fontSize: 16 }}>{title}</strong>
    <br />
    <span style={{ fontSize: 14 }}>{text}</span>
    <Divider />
  </div>
);

export default function ProfilDetay({ visible = false, kapatOnClick }) {
  const { profilDetay } = strings.bankaHesapDetay.components;
  const { data } = useSelector((state) => state.selectedBankaHesapReducer);

  return (
    <Modal
      width={"70%"}
      title={profilDetay.baslik}
      visible={visible}
      onCancel={kapatOnClick}
      footer={[
        <Button key="submit" type="primary" size="large" onClick={kapatOnClick}>
          {profilDetay.kapatButon}
        </Button>,
      ]}
    >
      <Row gutter={24}>
        <Col xl={12} lg={12} md={12} xs={12}>
          <ModelItem
            title={`${profilDetay.bankaAdi}:`}
            text={data.banka !== undefined && data.banka.ad}
          />
          <ModelItem title={`${profilDetay.hesapAdi}:`} text={data.ad} />
          <ModelItem title={`${profilDetay.ibanNo}:`} text={data.ibanNo} />
          <ModelItem
            title={`${profilDetay.dovizTuru}:`}
            text={
              data.doviz !== undefined &&
              ` ${data.doviz.simge} (${data.doviz.kod})`
            }
          />
        </Col>
        <Col xl={12} lg={12} md={12} xs={12}>
          <ModelItem title={`${profilDetay.hesapNo}:`} text={data.hesapNo} />
          <ModelItem title={`${profilDetay.subeAdi}:`} text={data.sube} />
          <ModelItem title={`${profilDetay.subeNo}:`} text={data.subeNo} />
        </Col>
      </Row>
    </Modal>
  );
}
