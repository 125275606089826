import React from 'react'
import { Layout } from 'antd'
import SideBar from '../components/navi/SideBar'
import styled, { ThemeProvider } from 'styled-components'
import NavBar from '../components/navi/navBar/Index'
import { useSelector } from 'react-redux'

const { Header, Content } = Layout

const MyContent = styled(Content)`
    padding:24px;
`

const Index = (props) => {
    const currentTheme = useSelector(state => state.themeReducer)
    const selectedTheme = { selectedTheme: currentTheme}

    return (
        <ThemeProvider theme={selectedTheme}>
            <Layout>
                <SideBar />
                <Layout>
                    <Header><NavBar /></Header>
                    <MyContent>
                        {props.children}
                    </MyContent>
                </Layout>
            </Layout>
        </ThemeProvider>
    )
}

export default Index


