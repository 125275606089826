import React, { useState, useEffect } from "react";
import { Table, Input, message, Space, Button, Row } from "antd";
import { api } from "../../helpers/ApiHelper";
import strings from "../../utils/Localization";
import { useSelector } from "react-redux";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";

export default function FetchListContent({
  columns = [],
  requestUrl = null,
  requestBody = {},
  dataSource = {
    data: [],
    totalRecordCount: 0,
  },
  onDataSource = () => {},
  expandable,
  detailSearch,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [detailSearchHidden, setDetailSearchHidden] = useState(true);
  const rerenderReducer = useSelector((state) => state.rerenderReducer);

  useEffect(() => {
    fetch();
  }, [rerenderReducer]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = async (body = {}) => {
    try {
      setFetchLoading(true);

      const response = await api.post(requestUrl, {
        PagingOptions: {
          PageSize: 10,
          PageNumber: 1,
        },
        SortOptions: {
          ColumnName: "id",
          SortType: "Desc",
        },
        ...requestBody,
        ...body,
      });
      if (response.status === 200) {
        const data = response.data.data.map((item, index) => ({
          ...item,
          key: index,
        }));

        onDataSource({ ...response.data, data: data });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const search = (val) => {
    fetch({
      SearchText: {
        PropertyNames: null,
        Value: val,
      },
    });
  };
  const onChange = (pagination, filters, sorter, extra) => {
    let filterData = {
      SearchText: {
        PropertyNames: null,
        Value: searchText,
      },
      PagingOptions: {
        PageSize: pagination.pageSize,
        PageNumber: pagination.current,
      },
    };
    if (sorter.order !== undefined) {
      filterData = {
        ...filterData,
        SortOptions: {
          ColumnName: sorter.field,
          SortType: sorter.order === "descend" ? "Desc" : "Asc",
        },
      };
    }
    fetch(filterData);
  };
  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <Row>
        <Input
          placeholder={strings.fetchListContent.aramaPlaceHolder}
          size="large"
          onChange={(e) => setSearchText(e.target.value)}
          onPressEnter={(e) => {
            search(e.target.value);
          }}
          style={{ flex: 1 }}
        />
        <Button
          size="large"
          icon={<SearchOutlined />}
          type="primary"
          onClick={() => search(searchText)}
          style={{ width: 55 }}
        />
        {detailSearch !== undefined && (
          <Button
            style={{ marginLeft: 12 }}
            size="large"
            type="default"
            icon={<FilterOutlined />}
            onClick={() => setDetailSearchHidden(!detailSearchHidden)}
          >
            Detaylı Arama
          </Button>
        )}
      </Row>
      {detailSearch !== undefined && (
        <>{detailSearchHidden === false && detailSearch}</>
      )}
      <Table
        columns={columns}
        dataSource={dataSource.data}
        onChange={onChange}
        pagination={{
          total: dataSource.totalRecordCount,
        }}
        loading={fetchLoading}
        expandable={expandable}
      />
    </Space>
  );
}
