import React from "react";
import { DatePicker, Form } from "antd";
import moment from "moment";
import strings from "../../utils/Localization";

export default function DatepickerRanger({
  formName,
  formLabel,
  required,
}) {
  moment.updateLocale("en", {
    weekdaysMin: strings.tarih.hafta,
    monthsShort: strings.tarih.ay,
  });

  return (
    <Form.Item
      label={formLabel}
      name={formName}
      rules={[{ type: "array", required: required }]}
    >
      <DatePicker.RangePicker
        placeholder={[strings.tarih.baslangicTarih, strings.tarih.bitisTarihi]}
        style={{ width: "100%" }}
        format={strings.tarih.format}
        allowClear={false}
      />
    </Form.Item>
  );
}
