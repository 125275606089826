import React, { useEffect, useState } from "react";
import { Card, message, Table, Tag } from "antd";
import styled from "styled-components";
import strings from "../../utils/Localization";
import useCurrency from "../../hooks/useCurrency";
import { api } from "../../helpers/ApiHelper";
import { useHistory } from "react-router-dom";
import CursorTableItem from "../toolbox/CursorTableItem";

const BakiyeText = styled.span`
  font-size: 15px;
  padding: 4px;
`;

export default function BankaBakiyeleri() {
  const [dataSource, setDataSource] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(true);
  const { paraFormat } = useCurrency();
  const history = useHistory();

  useEffect(() => {
    fetchBankaHesapBakiye();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBankaHesapBakiye = async () => {
    try {
      const response = await api.post("/bankahesap/get", {
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["Ad", "Id"],
          },
          {
            TableName: "Doviz",
            ColumnNames: ["Simge", "Id"],
          },
          {
            TableName: "Bankahesapislem:GirenBankaIslemTutar",
            ColumnNames: ["Tutar"],
            Conditions: [
              {
                Property: "GirisMi",
                Value: "true",
                Operator: "Equal",
              },
            ],
            Operator: "Sum",
          },
          {
            TableName: "Bankahesapislem:CikanBankaIslemTutar",
            ColumnNames: ["Tutar"],
            Conditions: [
              {
                Property: "GirisMi",
                Value: "false",
                Operator: "Equal",
              },
            ],
            Operator: "Sum",
          },
          {
            TableName: "Bankahesapislem:islemSayisi",
            Operator: "Count",
          },
        ],
      });
      if (response.status === 200) {
        setDataSource(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const columns = [
    {
      title: strings.panel.components.bankaBakiyeleri.bankaAdi,
      dataIndex: "Ad",
      key: "Ad",
      render: (bankaAdi, row, index) => (
        <CursorTableItem
          text={`${bankaAdi} (${row.islemSayisi})`}
          onClick={() => history.push(`/BankaHesapDetay/${row.Id}`)}
        />
      ),
    },
    {
      title: strings.panel.components.bankaBakiyeleri.bakiye,
      dataIndex: "bakiye",
      key: "bakiye",
      render: (bakiye, row, index) => (
        <Tag color={"cyan"} key={bakiye}>
          <BakiyeText>
            {`${row.Doviz.Simge}${paraFormat(
              parseFloat(row.GirenBankaIslemTutar - row.CikanBankaIslemTutar)
            )}`}
          </BakiyeText>
        </Tag>
      ),
    },
  ];

  return (
    <Card
      title={strings.panel.components.bankaBakiyeleri.baslik}
      loading={fetchLoading}
    >
      <Table pagination={false} dataSource={dataSource} columns={columns} />
    </Card>
  );
}
