import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  message,
  notification,
} from "antd";
import Datepicker from "../toolbox/DatePicker";
import moment from "moment";
import strings from "../../utils/Localization";
import { api } from "../../helpers/ApiHelper";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import KasaSelectOption from "../toolbox/KasaSelectOption";
import useEvrakNo from "../../hooks/useEvrakNo";

const formSize = "middle";
const aliciKasaInitialState = {
  doviz: { kod: "", simge: "", id: null },
};
function BankadanKasayaVirmanEkleModal({ visible = false, handleCancel }) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [aliciKasa, setAliciKasa] = useState({
    ...aliciKasaInitialState,
  });
  const [form] = Form.useForm();
  const { bankaHesapId } = useParams();
  const dispatch = useDispatch();
  const selectedBankaHesapReducer = useSelector(
    (state) => state.selectedBankaHesapReducer
  );
  const { doviz: gonderiBankaHesapDoviz = { simge: "", kod: "", id: null } } =
    selectedBankaHesapReducer.data;
  const { kasayaVirman: localization } = strings.bankaHesapIslem;
  const { getBankaHesapIslemEvrakNo } = useEvrakNo();

  useEffect(() => {
    getBankaHesapIslemEvrakNo().then((evrakNo) => {
      form.setFieldsValue({ evrakNo: evrakNo });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        islemTarihi,
        evrakNo,
        aciklama,
        aliciKasaId,
        cikanTutar,
        carpan,
        girenTutar,
      } = values;
      setFetchLoading(true);
      const response = await api.post(
        "/bankahesapislem/virman/addbankatokasa",
        {
          CikanBankaHesapId: bankaHesapId,
          GirenKasaId: aliciKasaId,
          EvrakNo: evrakNo,
          Aciklama: aciklama,
          CikanBankaHesapTutar: cikanTutar,
          GirenKasaTutar:
            (gonderiBankaHesapDoviz.id !== aliciKasa.doviz.id) &
            (aliciKasa.doviz.id !== null)
              ? girenTutar
              : cikanTutar,
          IslemTarihi: islemTarihi,
          Carpan: carpan,
        }
      );
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        getBankaHesapIslemEvrakNo().then((evrakNo) => {
          form.setFieldsValue({ evrakNo: evrakNo });
        });
        setAliciKasa({ ...aliciKasaInitialState });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const kurHesapla = (fieldName) => {
    const { cikanTutar, carpan, girenTutar } = form.getFieldValue();

    if (fieldName === "cikanTutar") {
      const currentGirenTutar = parseFloat(cikanTutar * carpan).toFixed(2);
      form.setFieldsValue({
        girenTutar: currentGirenTutar,
      });
    } else if (fieldName === "carpan") {
      const currentGirenTutar = parseFloat(cikanTutar * carpan).toFixed(2);
      form.setFieldsValue({
        girenTutar: currentGirenTutar,
      });
    } else if (fieldName === "girenTutar") {
      const currentCikanTutar = parseFloat(girenTutar / carpan).toFixed(2);
      form.setFieldsValue({
        cikanTutar: currentCikanTutar,
      });
    }
  };
  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "bankahesapdanKasayaVirmanEkleForm",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      width="60%"
    >
      <Form
        form={form}
        id="bankahesapdanKasayaVirmanEkleForm"
        size={formSize}
        layout="vertical"
        initialValues={{
          islemTarihi: moment(),
          cikanTutar: 0,
          carpan: 1,
          girenTutar: 0,
        }}
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col xxl={12} xl={12} md={12} sm={12} xs={24}>
            <Form.Item
              label={localization.islemTarihi}
              name="islemTarihi"
              rules={[{ required: true }]}
            >
              <Datepicker />
            </Form.Item>
            <Form.Item label={localization.evrakNo} name="evrakNo">
              <Input />
            </Form.Item>
            <Form.Item label={localization.aciklama} name="aciklama">
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} md={12} sm={12} xs={24}>
            <Form.Item label={localization.gondericiBankaHesap}>
              <Input
                readOnly
                value={`${selectedBankaHesapReducer.data.ad} (${gonderiBankaHesapDoviz.kod})`}
              />
            </Form.Item>
            <KasaSelectOption
              formItemLabel={localization.aliciKasa}
              formItemName="aliciKasaId"
              required
              requestBody={{}}
              showDovizKod
              size={formSize}
              onSelectedKasa={(e) => {
                setAliciKasa(e);
              }}
              rules={[{ required: true }]}
            />
            <Row gutter={12}>
              <Col flex={1}>
                <Form.Item
                  label={
                    (gonderiBankaHesapDoviz.id !== aliciKasa.doviz.id) &
                    (aliciKasa.doviz.id !== null)
                      ? localization.cikanTutar
                      : localization.tutar
                  }
                  name="cikanTutar"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "100%" }}
                    onChange={() => kurHesapla("cikanTutar")}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={localization.doviz}>
                  <Input value={gonderiBankaHesapDoviz.kod} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.aliciKasaId !== currentValues.aliciKasaId
              }
            >
              {({ getFieldValue }) => {
                if (
                  (gonderiBankaHesapDoviz.id !== aliciKasa.doviz.id) &
                  (aliciKasa.doviz.id !== null)
                ) {
                  return (
                    <>
                      <Form.Item
                        name="carpan"
                        label={localization.kur}
                        rules={[{ required: true }]}
                      >
                        <Input
                          type="number"
                          onChange={() => kurHesapla("carpan")}
                        />
                      </Form.Item>
                      <Row gutter={12}>
                        <Col flex={1}>
                          <Form.Item
                            name="girenTutar"
                            label={localization.girenTutar}
                            rules={[{ required: true }]}
                          >
                            <InputNumber
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              style={{ width: "100%" }}
                              onChange={() => kurHesapla("girenTutar")}
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item label={localization.doviz}>
                            <Input value={aliciKasa.doviz.kod} readOnly />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  );
                }
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default BankadanKasayaVirmanEkleModal;
