export const SET_LIGHT_THEME = "SET_LIGHT_THEME";
export const SET_DARK_THEME = "SET_DARK_THEME";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const SET_TOKEN = "SET_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";

export const SET_RERENDER = "SET_RERENDER";

export const FETCH_KASA_REQUEST = "FETCH_KASA_REQUEST";
export const FETCH_KASA_SUCCESS = "FETCH_KASA_SUCCESS";
export const FETCH_KASA_FAILURE = "FETCH_KASA_FAILURE";

export const FETCH_BANKA_HESAP_REQUEST = "FETCH_BANKA_HESAP_REQUEST";
export const FETCH_BANKA_HESAP_SUCCESS = "FETCH_BANKA_HESAP_SUCCESS";
export const FETCH_BANKA_HESAP_FAILURE = "FETCH_BANKA_HESAP_FAILURE";

export const SET_SELECTED_STOK = "SET_SELECTED_STOK";
