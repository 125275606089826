import React, { useState, useEffect } from "react";
import { Card, Form, message, notification } from "antd";
import { TeklifSiparisLayout } from "../components/teklifSiparisDuzenle";
import { useParams } from "react-router-dom";
import { api } from "../helpers/ApiHelper";
import moment from "moment";
import strings from "../utils/Localization";

function TeklifSiparisDuzenle() {
  const [form] = Form.useForm();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { teklifSiparisId } = useParams();
  const { teklifSiparisDuzenle: localization } = strings;

  useEffect(() => {
    fetchTeklifSiparis();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        teklifSiparisId,
        evrakNo,
        firmaAdi,
        yetkili,
        vergiDairesi,
        vergiNumarasi,
        telefon,
        cepTelefonu,
        eMail,
        duzenlemeTarihi,
        gecerlilikTarihi,
        aciklama,
        stopajOran,
        stopajTutar,
        tevkifatOran,
        tevkifatTutar,
        araToplamTutar,
        genelIskontoOran,
        genelIskontoTutar,
        genelIskontoTip,
        toplamSatirIskontoTutar,
        genelToplamTutar,
        teklifSiparisSatir,
      } = values;

      let requestData = {
        Id: teklifSiparisId,
        FirmaAdi: firmaAdi,
        Yetkili: yetkili,
        VergiDairesi: vergiDairesi,
        VergiNumarasi: vergiNumarasi,
        Telefon: telefon,
        CepTelefonu: cepTelefonu,
        EMail: eMail,
        TeklifSiparisNo: evrakNo,
        TeklifSiparisTarihi: moment(duzenlemeTarihi),
        GecerlilikTarihi: moment(gecerlilikTarihi),
        Aciklama: aciklama,
        StopajOran: stopajOran,
        StopajTutar: stopajTutar,
        TevkifatOran: tevkifatOran,
        TevkifatTutar: tevkifatTutar,
        Toplam: araToplamTutar,
        AraToplamIskontoOran: genelIskontoOran,
        AraToplamIskontoTutar: genelIskontoTutar,
        AraToplamIskontoTip: genelIskontoTip === "yuzde" ? "Oran" : "Tutar",
        SatirIskontoToplamTutar: toplamSatirIskontoTutar,
        GenelToplam: genelToplamTutar,
        UpdatedAt: moment(),
        Teklifsiparissatir: teklifSiparisSatir.map((teklifSiparisSatirItem) => {
          if (teklifSiparisSatirItem.id != null) {
            return {
              //eğer güncellenen satir ise
              Id: teklifSiparisSatirItem.id,
              Miktar: teklifSiparisSatirItem.miktar,
              BirimFiyat: teklifSiparisSatirItem.birimFiyat,
              Aciklama: teklifSiparisSatirItem.aciklama,
              OtvTutar: teklifSiparisSatirItem.otvTutar,
              OtvOran: teklifSiparisSatirItem.otvOran,
              KdvTutar: teklifSiparisSatirItem.kdvTutar,
              KdvOran: teklifSiparisSatirItem.kdvOran,
              UpdatedAt: moment(),
              Teklifsiparissatiriskonto: [
                {
                  Id: teklifSiparisSatirItem.iskontoId,
                  Oran: teklifSiparisSatirItem.iskontoOran,
                  Tutar: teklifSiparisSatirItem.iskontoTutar,
                  IskontoTip:
                    teklifSiparisSatirItem.iskontoTip === "yuzde"
                      ? "Oran"
                      : "Tutar",
                },
              ],
              GenelToplam: teklifSiparisSatirItem.toplam,
            };
          } else {
            return {
              //eğer yeni eklenen satir ise
              StokVaryantGrupId: teklifSiparisSatirItem.stokVaryantGrupId,
              StokBirimId: teklifSiparisSatirItem.stokBirimId,
              UrunAdi: teklifSiparisSatirItem.urunAdi,
              Miktar: teklifSiparisSatirItem.miktar,
              BirimFiyat: teklifSiparisSatirItem.birimFiyat,
              Barkod: teklifSiparisSatirItem.barkod,
              Aciklama: teklifSiparisSatirItem.aciklama,
              OtvTutar: teklifSiparisSatirItem.otvTutar,
              OtvOran: teklifSiparisSatirItem.otvOran,
              KdvTutar: teklifSiparisSatirItem.kdvTutar,
              KdvOran: teklifSiparisSatirItem.kdvOran,
              Teklifsiparissatiriskonto: [
                {
                  Oran: teklifSiparisSatirItem.iskontoOran,
                  Tutar: teklifSiparisSatirItem.iskontoTutar,
                  IskontoTip:
                    teklifSiparisSatirItem.iskontoTip === "yuzde"
                      ? "Oran"
                      : "Tutar",
                },
              ],
              GenelToplam: teklifSiparisSatirItem.toplam,
            };
          }
        }),
      };
      setFetchLoading(true);
      const response = await api.put("/teklifSiparis", requestData);

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const fetchTeklifSiparis = async () => {
    try {
      setLoading(true);
      const response = await api.post("/teklifsiparis/get", {
        Data: {
          Id: teklifSiparisId,
        },
        Includes: [
          "Teklifsiparissatir",
          "Teklifsiparissatir.Teklifsiparissatiriskonto",
          "Teklifsiparissatir.StokVaryantGrup",
          "Teklifsiparissatir.StokVaryantGrup.StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger",
          "Teklifsiparissatir.StokBirim",
          "Doviz",
        ],
      });
      if (response.status === 200) {
        const result = response.data.data[0];

        form.setFieldsValue({
          teklifSiparisId: result.id,
          teklifSiparisTip: result.tip,
          cariKartId: String(result.cariKartId),
          dovizId: String(result.dovizId),
          duzenlemeTarihi: moment(result.duzenlemeTarihi),
          gecerlilikTarihi: moment(result.gecerlilikTarihi),
          evrakNo: result.evrakNo,
          firmaAdi: result.firmaAdi,
          yetkili: result.yetkili,
          vergiDairesi: result.vergiDairesi,
          vergiNumarasi: result.vergiNumarasi,
          telefon: result.telefon,
          cepTelefonu: result.cepTelefonu,
          eMail: result.eMail,
          aciklama: result.aciklama,
          teklifSiparisSatir: result.teklifsiparissatir.map((satirItem) => ({
            id: satirItem.id,
            stokBirimId: satirItem.stokBirimId,
            urunAdi: satirItem.urunAdi,
            barkod: satirItem.barkod,
            stokId: satirItem.stokVaryantGrupId,
            stokVaryantGrupId: satirItem.stokVaryantGrupId,
            varyantGrupAdi:
              satirItem.stokVaryantGrup.stokvaryantgrupHasStokvaryantdeger
                .map((varyantGrupItem) => {
                  return varyantGrupItem.stokVaryantDeger.ad + " - ";
                })
                .join(""),
            aciklama: satirItem.aciklama,
            miktar: satirItem.miktar,
            birimFiyat: satirItem.birimFiyat,
            otvTutar: satirItem.otvTutar,
            otvOran: satirItem.otvOran,
            kdvTutar: satirItem.kdvTutar,
            kdvOran: satirItem.kdvOran,
            toplam: satirItem.genelToplam,
            iskontoId: satirItem.teklifsiparissatiriskonto[0].id,
            iskontoOran: satirItem.teklifsiparissatiriskonto[0].oran,
            iskontoTip:
              satirItem.teklifsiparissatiriskonto[0].iskontoTip === "Oran"
                ? "yuzde"
                : "tutar",
            iskontoTutar: satirItem.teklifsiparissatiriskonto[0].tutar,
            yeniSatirMi: false,
          })),
          genelIskontoOran: result.araToplamIskontoOran,
          genelIskontoTip:
            result.araToplamIskontoTip === "Oran" ? "yuzde" : "tutar",
          genelIskontoTutar: result.araToplamIskontoTutar,
          stopajOran: result.stopajOran,
          stopajTutar: result.stopajTutar,
          tevkifatOran: result.tevkifatOran,
          tevkifatTutar: result.tevkifatTutar,
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <Card loading={loading} />;
  }

  return (
    <>
      <TeklifSiparisLayout
        form={form}
        title={localization.baslik}
        saveButtonTitle={localization.guncelleButon}
        onFinish={onFinish}
        saveButtonLoading={fetchLoading}
      />
    </>
  );
}

export default TeklifSiparisDuzenle;
