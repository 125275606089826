import React, { useState, useEffect } from "react";
import FaturaLayout from "../components/faturaEkle/FaturaLayout";
import { Form, message, notification } from "antd";
import { useLocation } from "react-router-dom";
import { faturaTypes } from "../types/faturaTypes";
import { api } from "../helpers/ApiHelper";
import { odemeSekliTypes } from "../types/odemeSekliTypes";
import { cariIslemTurTypes } from "../types/cariIslemTurTypes";
import strings from "../utils/Localization";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default function FaturaEkle() {
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = Form.useForm();
  const { state = { faturaTip: faturaTypes.SATIS_FATURASI } } = useLocation();
  const { faturaEkle: localization } = strings;
  const history = useHistory();

  useEffect(() => {
    form.setFieldsValue({ faturaTip: state.faturaTip });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        cariKartId,
        dovizId,
        odemeSekli,
        kasaId,
        bankaHesapId,
        faturaNo,
        faturaTarihi,
        faturaSaati,
        vadeTarihi,
        aciklama,
        stopajOran,
        stopajTutar,
        tevkifatOran,
        tevkifatTutar,
        toplamSatirIskontoTutar,
        araToplamTutar,
        genelIskontoOran,
        genelIskontoTutar,
        genelIskontoTip,
        genelToplamTutar,
        faturaSatir = [
          {
            aciklama: "",
            stokId: null,
            varyantId: null,
            miktar: 1,
            birimFiyat: 0,
            kdvOran: 0,
            kdvTutar: 0,
            otvOran: 0,
            otvTutar: 0,
            toplam: 0,
            iskontoOran: 0,
            iskontoTip: "yuzde",
            iskontoTutar: 0,
          },
        ],
        firmaAdi,
        yetkili,
        vergiDairesi,
        vergiNumarasi,
        telefon,
        cepTelefonu,
        eMail,
        il,
        ilce,
        adres,
        sevkAdresi,
        sevkTarihi,
        sevkNo,
      } = values;
      let requestData = {
        CariKartId: cariKartId,
        DovizId: dovizId,
        FaturaTip: state.faturaTip, //Alış Faturası,Satış Faturası,Alış İade Faturası,Satış İade Faturası
        OdemeSekli: odemeSekli,
        Carpan: 1,
        FirmaAdi: firmaAdi,
        Yetkili: yetkili,
        VergIdairesi: vergiDairesi,
        VergiNumarasi: vergiNumarasi,
        Telefon: telefon,
        CepTelefonu: cepTelefonu,
        EMail: eMail,
        // CariKod: '',
        Il: il,
        Ilce: ilce,
        Adres: adres,
        SevkAdresi: sevkAdresi,
        FaturaNo: faturaNo,
        FaturaTarihi: faturaTarihi,
        SevkTarihi: sevkTarihi,
        SevkNumarasi: sevkNo,
        FaturaSaati: moment(faturaSaati).format("HH:mm:ss"),
        Aciklama: aciklama,
        StopajOran: stopajOran,
        StopajTutar: stopajTutar,
        TevkifatOran: tevkifatOran,
        TevkifatTutar: tevkifatTutar,
        FaturaSatirIskontoToplamTutar: toplamSatirIskontoTutar,
        Toplam: araToplamTutar,
        AraToplamIskontoOran: genelIskontoOran,
        AraToplamIskontoTutar: genelIskontoTutar,
        AraToplamIskontoTip: genelIskontoTip === "yuzde" ? "Oran" : "Tutar",
        GenelToplam: genelToplamTutar,
        Faturasatir: faturaSatir.map((item) => ({
          StokVaryantGrupId: item.stokVaryantGrupId,
          StokBirimId: item.stokBirimId,
          UrunAdi: item.urunAdi,
          Miktar: item.miktar,
          BirimFiyat: item.birimFiyat,
          Barkod: item.barkod,
          Aciklama: item.aciklama,
          OtvTutar: item.otvTutar,
          OtvOran: item.otvOran,
          GenelToplam: item.toplam,
          // OivTutar: 20,
          // OivOran: 25,
          KdvTutar: item.kdvTutar,
          KdvOran: item.kdvOran,
          Faturasatiriskonto: [
            {
              Oran: item.iskontoOran,
              Tutar: item.iskontoTutar,
              IskontoTip: item.iskontoTip === "yuzde" ? "Oran" : "Tutar",
            },
          ],
          Stokislem: {
            Aciklama: item.aciklama,
            DovizId: dovizId,
            GirisMi: faturaGirisMi(),
            IslemTarihi: sevkTarihi,
            Miktar: item.miktar,
            StokBirimId: item.stokBirimId,
            StokDepoId: item.stokDepoId,
            StokVaryantGrupId: item.stokVaryantGrupId,
            BirimFiyat: item.birimFiyat,
          },
        })),
        Cariislembaglanti: {
          CariKartId: cariKartId,
          Cariislem: {
            CariKartId: cariKartId,
            DovizId: dovizId,
            CariIslemTur: cariIslemTur(),
            EvrakNo: faturaNo,
            Aciklama: `${faturaNo} numaralı faturanın ${cariIslemTur()} işlemidir`,
            Carpan: 1,
            Tutar: genelToplamTutar,
            VadeTarih: vadeTarihi,
            IslemTarih: faturaTarihi,
            GirisMi: faturaGirisMi(),
            OdemeSekli: odemeSekliTypes.ACIK_HESAP,
            AltCariIslem:
              odemeSekli !== odemeSekliTypes.ACIK_HESAP
                ? [
                    {
                      CariKartId: cariKartId,
                      DovizId: dovizId,
                      CariIslemTur:
                        cariIslemTur() === cariIslemTurTypes.BORC
                          ? cariIslemTurTypes.TAHSILAT
                          : cariIslemTurTypes.ODEME,
                      EvrakNo: faturaNo,
                      Aciklama: `${faturaNo} numaralı faturanın ${
                        cariIslemTur() === cariIslemTurTypes.BORC
                          ? cariIslemTurTypes.TAHSILAT
                          : cariIslemTurTypes.ODEME
                      } işlemidir`,
                      Carpan: 1,
                      Tutar: genelToplamTutar,
                      VadeTarih: vadeTarihi,
                      IslemTarih: faturaTarihi,
                      GirisMi: !faturaGirisMi(),
                      OdemeSekli: odemeSekli,
                      Cariislembaglanti: {
                        CariKartId: cariKartId,
                        BankaHesapId:
                          odemeSekli === odemeSekliTypes.HAVALE ||
                          odemeSekli === odemeSekliTypes.KREDI_KARTI
                            ? bankaHesapId
                            : null,
                        KasaId:
                          odemeSekli === odemeSekliTypes.NAKIT ? kasaId : null,
                        KasaIslem:
                          odemeSekli === odemeSekliTypes.NAKIT
                            ? {
                                KasaId: kasaId,
                                EvrakNo: faturaNo,
                                Aciklama: `${faturaNo} numaralı faturanın işlemidir`,
                                VirmanMi: 0,
                                GirisMi: !faturaGirisMi(),
                                Tutar: genelToplamTutar,
                                IslemTarihi: faturaTarihi,
                              }
                            : null,
                        BankaHesapIslem:
                          odemeSekli === odemeSekliTypes.KREDI_KARTI ||
                          odemeSekli === odemeSekliTypes.HAVALE
                            ? {
                                BankaHesapId: bankaHesapId,
                                EvrakNo: faturaNo,
                                Aciklama: `${faturaNo} numaralı faturanın işlemidir`,
                                VirmanMi: 0,
                                GirisMi: faturaGirisMi(),
                                Tutar: genelToplamTutar,
                                IslemTarihi: faturaTarihi,
                              }
                            : null,
                      },
                    },
                  ]
                : null,
          },
        },
      };
      setSaveLoading(true);
      const response = await api.post("/fatura", requestData);
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        history.goBack();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setSaveLoading(false);
    }
  };

  const faturaGirisMi = () => {
    const faturaTip = form.getFieldValue("faturaTip");
    switch (faturaTip) {
      case faturaTypes.SATIS_FATURASI:
        return false;
      case faturaTypes.ALIS_FATURASI:
        return true;
      case faturaTypes.SATIS_IADE_FATURASI:
        return true;
      case faturaTypes.ALIS_IADE_FATURASI:
        return false;
      default:
        return null;
    }
  };
  const cariIslemTur = () => {
    const faturaTip = form.getFieldValue("faturaTip");
    switch (faturaTip) {
      case faturaTypes.SATIS_FATURASI:
        return cariIslemTurTypes.BORC;
      case faturaTypes.ALIS_FATURASI:
        return cariIslemTurTypes.ALACAK;
      case faturaTypes.SATIS_IADE_FATURASI:
        return cariIslemTurTypes.ALACAK;
      case faturaTypes.ALIS_IADE_FATURASI:
        return cariIslemTurTypes.BORC;
      default:
        return null;
    }
  };

  return (
    <FaturaLayout
      form={form}
      title={state.faturaTip}
      saveButtonTitle={localization.kaydetButon}
      saveButtonLoading={saveLoading}
      onFinish={onFinish}
    />
  );
}
