import React, { useEffect, useState } from "react";
import { api } from "../../helpers/ApiHelper";
import { message, Select, Form } from "antd";
import strings from "../../utils/Localization";

const { Option } = Select;

function DovizSelectOption({
  formItemName,
  formItemLabel,
  allowClear,
  titleHidden,
  onChange,
  defaultValue,
  disabled,
  required,
  showKod,
  noStyle,
  hidden,
  onDovizData = () => {},
}) {
  const [dovizData, setDovizData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { dovizPlaceHolder, dovizBaslik } = strings.dovizSelectOption;
  const label = formItemLabel || dovizBaslik;
  useEffect(() => {
    fetchDovizGrup();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDovizGrup = async () => {
    try {
      setFetchLoading(true);

      const response = await api.post("/doviz/get", {
        SortOptions: {
          ColumnName: "Id",
          SortType: "Asc",
        },
      });
      if (response.status === 200) {
        setDovizData(response.data.data);
        onDovizData(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Form.Item
      name={formItemName}
      label={!titleHidden && label}
      rules={[{ required: required }]}
      noStyle={noStyle}
      hidden={hidden}
    >
      <Select
        placeholder={dovizPlaceHolder}
        allowClear={allowClear}
        loading={fetchLoading}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {dovizData.map((item, index) => (
          <Option key={index} value={String(item.id)}>
            {showKod ? item.kod : item.ad}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default DovizSelectOption;
