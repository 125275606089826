import React, { useState, useEffect } from "react";
import { Button, Form, Divider, Input, Typography, message } from "antd";
import strings from "../utils/Localization";
import { useHistory, useLocation } from "react-router-dom";
import { Layout } from "../components/sifremiUnuttum";
import { api } from "../helpers/ApiHelper";
const md5 = require("md5");

const { Paragraph } = Typography;

const paragraphStyle = { textAlign: "center", letterSpacing: 1 };

function SifremiUnuttumYeniSifre() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const { state } = useLocation();
  const {
    slogan,
    sifre,
    sifrePlaceHolder,
    sifreTekrar,
    sifreTekrarPlaceHolder,
    kaydetButon,
    sifreUyusmuyor,
    sifreDegistirildi,
  } = strings.sifremiUnuttumYeniSifre;

  useEffect(() => {
    state === undefined && history.push("/SifremiUnuttumMailDogrulama");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const { sifre } = values;
      setFetchLoading(true);
      const response = await api.post("/auth/sifreunuttumyenileme", {
        Email: state.eMail,
        DogrulamaKodu: state.code,
        YeniSifre: md5(sifre),
      });
      if (response.status === 200) {
        message.success(sifreDegistirildi);
        history.push("/GirisYap");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Layout>
      <Paragraph style={paragraphStyle}>{slogan}</Paragraph>
      <Divider />
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="basic"
        onFinish={onFinish}
      >
        <Form.Item
          label={sifre}
          name="sifre"
          rules={[
            {
              required: true,
              min: 6,
            },
          ]}
        >
          <Input.Password placeholder={sifrePlaceHolder} autoFocus />
        </Form.Item>
        <Form.Item
          label={sifreTekrar}
          name="sifreTekrar"
          dependencies={["sifre"]}
          rules={[
            { required: true, min: 6 },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("sifre") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(sifreUyusmuyor);
              },
            }),
          ]}
        >
          <Input.Password placeholder={sifreTekrarPlaceHolder} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={fetchLoading}
          >
            {kaydetButon}
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}

export default SifremiUnuttumYeniSifre;
