import React from "react";
import { Button, Space } from "antd";
import { useHistory } from "react-router-dom";
import strings from "../../utils/Localization";
import { faturaTypes } from "../../types/faturaTypes";

export default function IslemButon() {
  const history = useHistory();
  const { islemButton } = strings.faturaListe.components;

  return (
    <>
      <Space direction="horizontal">
        <Button
          type="primary"
          size="large"
          onClick={() =>
            history.push({
              pathname: "/FaturaEkle",
              state: {
                faturaTip: faturaTypes.SATIS_FATURASI,
              },
            })
          }
        >
          {islemButton.yeniSatisFaturasi}
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={() =>
            history.push({
              pathname: "/FaturaEkle",
              state: {
                faturaTip: faturaTypes.ALIS_FATURASI,
              },
            })
          }
        >
          {islemButton.yeniAlisFaturasi}
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={() =>
            history.push({
              pathname: "/FaturaEkle",
              state: {
                faturaTip: faturaTypes.SATIS_IADE_FATURASI,
              },
            })
          }
        >
          {islemButton.yeniSatisIadeFaturasi}
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={() =>
            history.push({
              pathname: "/FaturaEkle",
              state: {
                faturaTip: faturaTypes.ALIS_IADE_FATURASI,
              },
            })
          }
        >
          {islemButton.yeniAlisIadeFaturasi}
        </Button>
      </Space>
    </>
  );
}
