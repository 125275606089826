import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import languageReducer from "./languageReducer";
import tokenReducer from "./tokenReducer";
import rerenderReducer from "./rerenderReducer";
import selectedKasaReducer from "./selectedKasaReducer";
import selectedBankaHesapReducer from "./selectedBankaHesapReducer";
import selectedStokReducer from "./selectedStokReducer";

export default combineReducers({
  themeReducer,
  languageReducer,
  tokenReducer,
  rerenderReducer,
  selectedKasaReducer,
  selectedBankaHesapReducer,
  selectedStokReducer,
});
