import React, { useState } from "react";
import { Button, Card, Form, message } from "antd";
import DatepickerRanger from "../toolbox/DatepickerRanger";
import strings from "../../utils/Localization";
import KasaSelectOption from "../toolbox/KasaSelectOption";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import moment from "moment";
import { api } from "../../helpers/ApiHelper";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";

export default function KasaRaporForm() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const { kasaRaporForm } = strings.kasaRapor.components;
  const { getDefaultSetting } = useVarsayilanDeger();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const { tarih, kasaId, dovizId } = values;

      const baslangicTarihi = moment(tarih[0]).format("YYYY-MM-DD");
      const bitisTarihi = moment(tarih[1]).format("YYYY-MM-DD");

      setFetchLoading(true);
      const response = await api.post(
        "/rapordosya/kasa",
        {
          BaslangicTarih: `${baslangicTarihi} 00:00:00`,
          BitisTarih: `${bitisTarihi} 23:59:59`,
          KasaId: kasaId === undefined ? 0 : kasaId,
          DovizId: dovizId === undefined ? 0 : dovizId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return window.open(response.data, "_blank");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          tarih: [moment().add("months", -12), moment()],
          dovizId: getDefaultSetting("doviz"),
        }}
      >
        <DatepickerRanger
          formName="tarih"
          formLabel={kasaRaporForm.tarihSeciniz}
          required
        />
        <DovizSelectOption formItemName="dovizId" allowClear />
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.dovizId !== currentValues.dovizId
          }
        >
          {({ getFieldValue }) => {
            form.setFieldsValue({ kasaId: undefined });
            const currentDovizId = getFieldValue("dovizId");

            return (
              <KasaSelectOption
                formItemName="kasaId"
                formItemLabel={kasaRaporForm.kasaSeciniz}
                requestBody={{
                  Data: {
                    dovizId: currentDovizId,
                  },
                }}
                disabled={currentDovizId === undefined ? true : false}
                allowClear
              />
            );
          }}
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          style={{ float: "right" }}
          size="large"
          loading={fetchLoading}
        >
          {kasaRaporForm.raporAlButon}
        </Button>
      </Form>
    </Card>
  );
}
