import React from "react";
import { PageHeader, Button, Form, Typography, Space } from "antd";
import moment from "moment";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";
import FaturaHeader from "./FaturaHeader";
import FaturaSatir from "./FaturaSatir";
import FaturaFooter from "./FaturaFooter";
import {
  tutariYuzdeyeCevir,
  yuzdeHesala,
} from "../../utils/faturaSatirHesaplama";
const { Text } = Typography;

const formSize = "middle";

function FaturaLayout({
  title = "-",
  saveButtonTitle = "-",
  saveButtonLoading = false,
  form,
  onFinish,
}) {
  const { getDefaultSetting } = useVarsayilanDeger();

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.dovizId !== undefined) {
      form.setFieldsValue({
        kasaId: undefined,
        bankaHesapId: undefined,
      });
    }
    if (changedValues.stopajOran !== undefined) {
      setFaturaFooterFields();
    }
    if (changedValues.tevkifatOran !== undefined) {
      setFaturaFooterFields();
    }

    if (
      changedValues.genelIskontoTip !== undefined ||
      changedValues.genelIskontoOran !== undefined
    ) {
      setFaturaFooterFields();
    }
  };

  const setFaturaFooterFields = () => {
    const fields = form.getFieldsValue();
    const {
      faturaSatir,
      genelIskontoOran = 0,
      genelIskontoTip = "yuzde",
      stopajOran = 0,
      tevkifatOran = 0,
    } = fields;
    const araToplam = faturaSatir.reduce(
      (toplam, item) => (toplam += parseFloat(item.birimFiyat * item.miktar)),
      0
    );
    const toplamSatirIskontoTutar = faturaSatir.reduce(
      (toplam, item) => (toplam += parseFloat(item.iskontoTutar)),
      0
    );

    const genelIskontoTutar =
      genelIskontoTip === "tutar"
        ? parseFloat(genelIskontoOran)
        : yuzdeHesala(araToplam - toplamSatirIskontoTutar, genelIskontoOran);

    const genelIskontoYuzde =
      genelIskontoTip === "yuzde"
        ? parseFloat(genelIskontoOran)
        : tutariYuzdeyeCevir(
            genelIskontoOran,
            araToplam - toplamSatirIskontoTutar
          );

    const brutToplam = parseFloat(
      araToplam - toplamSatirIskontoTutar - genelIskontoTutar
    );

    const stopajTutar = yuzdeHesala(brutToplam, stopajOran);

    let toplamOtvTutar = faturaSatir.reduce(
      (toplam, item) => (toplam += parseFloat(item.otvTutar)),
      0
    );
    toplamOtvTutar -= yuzdeHesala(toplamOtvTutar, genelIskontoYuzde);

    let toplamKdv01Tutar = faturaSatir.reduce(
      (toplam, item) =>
        item.kdvOran === 1 ? (toplam += item.kdvTutar) : (toplam += 0),
      0
    );
    toplamKdv01Tutar -= yuzdeHesala(toplamKdv01Tutar, genelIskontoYuzde);

    let toplamKdv08Tutar = faturaSatir.reduce(
      (toplam, item) =>
        item.kdvOran === 8 ? (toplam += item.kdvTutar) : (toplam += 0),
      0
    );
    toplamKdv08Tutar -= yuzdeHesala(toplamKdv08Tutar, genelIskontoYuzde);

    let toplamKdv18Tutar = faturaSatir.reduce(
      (toplam, item) =>
        item.kdvOran === 18 ? (toplam += item.kdvTutar) : (toplam += 0),
      0
    );
    toplamKdv18Tutar -= yuzdeHesala(toplamKdv18Tutar, genelIskontoYuzde);

    const toplamKdvTutar = parseFloat(
      toplamKdv01Tutar + toplamKdv08Tutar + toplamKdv18Tutar
    );

    const tevkifatTutar = yuzdeHesala(toplamKdvTutar, parseFloat(tevkifatOran));

    const genelToplamTutar = parseFloat(
      araToplam -
        toplamSatirIskontoTutar -
        genelIskontoTutar -
        stopajTutar +
        toplamKdvTutar +
        toplamOtvTutar -
        tevkifatTutar
    );

    form.setFields([
      { name: "araToplamTutar", value: araToplam },
      { name: "toplamSatirIskontoTutar", value: toplamSatirIskontoTutar },
      { name: "brutToplamTutar", value: brutToplam },
      { name: "stopajTutar", value: stopajTutar },
      { name: "toplamOtvTutar", value: toplamOtvTutar },
      { name: "toplamKdv01Tutar", value: toplamKdv01Tutar },
      { name: "toplamKdv08Tutar", value: toplamKdv08Tutar },
      { name: "toplamKdv18Tutar", value: toplamKdv18Tutar },
      { name: "toplamKdvTutar", value: toplamKdvTutar },
      { name: "tevkifatTutar", value: tevkifatTutar },
      { name: "genelToplamTutar", value: genelToplamTutar },
    ]);
  };

  const firstOnFinish = (values) => {
    onFinish(values);
  };
  return (
    <div>
      <PageHeader
        title={
          <Text style={{ fontSize: 22 }}>
            <EditOutlined /> {title}
          </Text>
        }
        extra={
          <Button
            icon={<SaveOutlined />}
            type="primary"
            title="Kaydet"
            size="large"
            form="myForm"
            htmlType="submit"
            loading={saveButtonLoading}
          >
            {saveButtonTitle}
          </Button>
        }
        style={{ padding: 0, paddingBottom: 18 }}
      />
      <Form
        id="myForm"
        size={formSize}
        labelCol={{ span: 6 }}
        labelAlign="left"
        form={form}
        onValuesChange={onValuesChange}
        initialValues={{
          faturaId: null,
          faturaTip: null,
          faturaTarihi: moment(),
          vadeTarihi: moment(),
          sevkTarihi: moment(),
          dovizId: getDefaultSetting("doviz"),
          odemeSekli: getDefaultSetting("odemeSekli"),
          faturaSatir: [],
          araToplamTutar: 0,
          toplamSatirIskontoTutar: 0,
          genelIskontoOran: 0,
          genelIskontoTip: "yuzde",
          genelIskontoTutar: 0,
          brutToplamTutar: 0,
          stopajOran: 0,
          stopajTutar: 0,
          toplamOtvTutar: 0,
          toplamKdv01Tutar: 0,
          toplamKdv08Tutar: 0,
          toplamKdv18Tutar: 0,
          toplamKdvTutar: 0,
          tevkifatOran: 0,
          tevkifatTutar: 0,
          genelToplamTutar: 0,
        }}
        onFinish={firstOnFinish}
      >
        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <FaturaHeader form={form} />
          <FaturaSatir form={form} setFooterFields={setFaturaFooterFields} />
          <FaturaFooter form={form} />
        </Space>
        <Form.Item name="faturaId" initialValue={null} hidden noStyle />
        <Form.Item name="faturaTip" initialValue={null} hidden noStyle />
      </Form>
      <br />
    </div>
  );
}

export default FaturaLayout;
