import React, { useEffect, useState } from "react";
import { api } from "../../helpers/ApiHelper";
import { message, Select, Form } from "antd";
import strings from "../../utils/Localization";

const { Option } = Select;

function StokBirimSelectOption({ formItemName, disabled }) {
  const [stokBirimData, setStokBirimData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { stokBirimPlaceHolder, stokBirimBaslik } =
    strings.stokBirimSelectOption;

  useEffect(() => {
    fetchStokBirim();
  }, []);

  const fetchStokBirim = async () => {
    try {
      setFetchLoading(true);

      const response = await api.post("/stokbirim/get", {
        SortOptions: {
          ColumnName: "Id",
          SortType: "Asc",
        },
      });
      if (response.status === 200) {
        setStokBirimData(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Form.Item
      name={formItemName}
      label={stokBirimBaslik}
      rules={[{ required: true }]}
    >
      <Select
        placeholder={stokBirimPlaceHolder}
        allowClear
        loading={fetchLoading}
        disabled={disabled}
      >
        {stokBirimData.map((item, index) => (
          <Option key={index} value={String(item.id)}>
            {item.ad}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default StokBirimSelectOption;
