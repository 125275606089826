import React from "react";
import { Card, Divider } from "antd";
import {
  Container,
  Logo,
  GirisYapForm,
  KayitOlButton,
  IletisimBilgi,
  SifremiUnuttumButon,
  DemoButton,
} from "../components/girisYap";

const formWidth = 380;
const formRadius = 24;

const formStyle = {
  borderRadius: formRadius,
  width: formWidth,
};

export default function GirisYap() {
  return (
    <Container>
      <Logo />
      <Card style={formStyle}>
        <GirisYapForm />
        <KayitOlButton />
        <Divider />
        <IletisimBilgi />
      </Card>
      <SifremiUnuttumButon />
      <DemoButton />
    </Container>
  );
}
