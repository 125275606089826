import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  InputNumber,
  Row,
  Col,
  Input,
  message,
  notification,
} from "antd";
import Datepicker from "../toolbox/DatePicker";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import moment from "moment";
import strings from "../../utils/Localization";
import { cariIslemTurTypes } from "../../types/cariIslemTurTypes";
import { api } from "../../helpers/ApiHelper";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";

const formSize = "middle";

const CariIslemDuzenleModal = ({
  visible = false,
  handleCancel,
  data = {},
}) => {
  const [form] = Form.useForm();
  const [fetchLoading, setFetchLoading] = useState(false);
  const { cariIslemDuzenle } = strings.cariIslem;
  const dispatch = useDispatch();

  useEffect(() => {
    if (data !== {}) {
      const {
        id,
        vadeTarih,
        islemTarih,
        tutar,
        doviz = { id: null },
        evrakNo,
        aciklama,
        cariIslemTur,
      } = data;

      form.setFieldsValue({
        id: id,
        vadeTarihi: moment(vadeTarih),
        islemTarihi: moment(islemTarih),
        dovizId: String(doviz.id),
        tutar,
        evrakNo,
        aciklama,
        cariIslemTur,
      });
    } else {
      form.resetFields();
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const { id, vadeTarihi, islemTarihi, tutar, evrakNo, aciklama } = values;
      setFetchLoading(true);
      const response = await api.put("/cariislem", {
        Id: id,
        EvrakNo: evrakNo,
        Aciklama: aciklama,
        Carpan: "1",
        Tutar: tutar,
        VadeTarih: vadeTarihi,
        IslemTarih: islemTarihi,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={cariIslemDuzenle.baslik}
      visible={visible}
      onCancel={handleCancel}
      width={"50%"}
      okButtonProps={{
        size: "large",
        form: "cariIslemDuzenleForm",
        htmlType: "submit",
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: "large" }}
      centered
    >
      <Form
        form={form}
        id="cariIslemDuzenleForm"
        layout="vertical"
        size={formSize}
        onFinish={onFinish}
      >
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const cariIslemTur = getFieldValue("cariIslemTur");
            if (cariIslemTur === undefined) return null;
            if (
              cariIslemTur === cariIslemTurTypes.ALACAK ||
              cariIslemTur === cariIslemTurTypes.BORC
            ) {
              return (
                <Form.Item
                  label={cariIslemDuzenle.vadeTarihi}
                  name="vadeTarihi"
                  rules={[{ required: true }]}
                >
                  <Datepicker size={formSize} />
                </Form.Item>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          label={cariIslemDuzenle.islemTarihi}
          name="islemTarihi"
          rules={[{ required: true }]}
        >
          <Datepicker size={formSize} />
        </Form.Item>
        <Row gutter={12}>
          <Col flex={1}>
            <Form.Item
              label={cariIslemDuzenle.tutar}
              name="tutar"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col>
            <DovizSelectOption
              formItemName="dovizId"
              disabled
              size={formSize}
              showKod
            />
          </Col>
        </Row>
        <Form.Item name="evrakNo" label={cariIslemDuzenle.evrakNo}>
          <Input />
        </Form.Item>
        <Form.Item name="aciklama" label={cariIslemDuzenle.aciklama}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item name="id" noStyle hidden />
        <Form.Item name="cariIslemTur" noStyle hidden />
      </Form>
    </Modal>
  );
};

export default React.memo(CariIslemDuzenleModal);
