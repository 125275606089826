import React from "react";
import Routes from "./Routes";
import { useSelector } from "react-redux";
import strings from "./utils/Localization";
import { ConfigProvider } from "antd";
import trTr from "antd/lib/locale/tr_TR";
import './App.less';

const App = () => {
  const currentLanguage = useSelector((state) => state.languageReducer);
  strings.setLanguage(currentLanguage);

  return (
    <ConfigProvider locale={trTr}>
      <Routes />
    </ConfigProvider>
  );
};

export default App;
